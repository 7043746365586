import React from 'react'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import { ShortCuts } from 'components/dashboard/shortcuts/components'

import { CarrierShortcutGoal } from '../../../dashboard/shortcuts/ShortcutGoal'

const fieldCarriersSelectorList = (props) => {
    const { input, values, options } = props

    const selectedCarriers = isEmpty(values[input.name])
        ? []
        : values[input.name].filter((type) => options.includes(type))

    const onChangeField = (carrierType, checked) => {
        const newValues = checked
            ? uniq([...selectedCarriers, carrierType])
            : selectedCarriers.filter((carrier) => carrier !== carrierType)

        input.onChange(newValues)
    }

    const isSelected = (carrierType) => selectedCarriers.includes(carrierType)

    return (
        <div>
            <ShortCuts.InputLabels className="mb-4">
                {options.map((carrierType) => (
                    <label
                        key={`carrier-input-${carrierType}`}
                        htmlFor={carrierType}
                    >
                        <input
                            id={carrierType}
                            name={carrierType}
                            style={{ display: 'none' }}
                            type="checkbox"
                            checked={isSelected(carrierType)}
                            onChange={(event) =>
                                onChangeField(carrierType, event.target.checked)
                            }
                        />

                        <CarrierShortcutGoal carrierType={carrierType} />
                    </label>
                ))}
            </ShortCuts.InputLabels>

            <ShortCuts.FieldDescription>
                <i className="fas fa-truck mr-2" />
                ShipperHQ integrates with 50+ small package and LTL carriers
            </ShortCuts.FieldDescription>
        </div>
    )
}

export default fieldCarriersSelectorList
