import React, { useMemo, useEffect } from 'react'
import ModalMultiselect from 'components/ui/ModalMultiselect'
import MultiSelectList from 'components/ui/MultiSelectList'
import { useField, useForm } from 'react-final-form'

function useUnifiedSelection() {
    const { input: { value: shippingMethodIds } = {} } = useField(
        'shipping_method_ids'
    )
    const {
        input: { value: carrierMethodCodeIds },
    } = useField('carriers_methods_code_ids')

    const selected = useMemo(
        () => [...shippingMethodIds, ...carrierMethodCodeIds],
        [shippingMethodIds, carrierMethodCodeIds]
    )

    return selected
}

function flattenOptions(options) {
    return [
        ...options.map((item) => item.options.map((option) => option.value)),
    ].flat()
}

export default function fieldShippingMethods(props) {
    const {
        title,
        hint,
        optionSources,
        input,
        disabled,
        readOnly,
        forceSelect,
        askToSelect,
        tooltip,
        required,
        entityName,
        meta,
        emptyMessage,
        list = false,
    } = props

    const metaData = { ...meta }
    const value = useUnifiedSelection()
    const { change } = useForm()

    // merge live & custom options
    const options = useMemo(
        () => [
            ...(optionSources.shipping_methods || []),
            ...(optionSources.carriers_methods_codes || []),
        ],
        [optionSources]
    )

    /**
     * Split selected values between live & custom fields
     * @param values
     */
    const onChange = (values) => {
        const customMethods = flattenOptions(optionSources.shipping_methods)
        const liveMethods = flattenOptions(optionSources.carriers_methods_codes)
        const liveIds = []
        const customIds = []

        for (const id of values) {
            if (customMethods.includes(id)) {
                customIds.push(id)
            }
            if (liveMethods.includes(id)) {
                liveIds.push(id)
            }
        }

        // update target values
        change(input.name, values)
        change('shipping_method_ids', customIds)
        change('carriers_methods_code_ids', liveIds)
    }

    if (
        forceSelect &&
        (!options || options.length === 0 || input.value === '')
    ) {
        metaData.error = emptyMessage
        metaData.touched = true
    }

    useEffect(() => {
        onChange(value)
    }, [])

    const MultiSelectComponent = list ? MultiSelectList : ModalMultiselect

    return (
        <MultiSelectComponent
            disabled={disabled || readOnly}
            /* $FlowFixMe */
            hint={hint}
            /* $FlowFixMe */
            options={options}
            title={title}
            onChange={onChange}
            multiSelect
            selected={value}
            forceSelect={forceSelect}
            askToSelect={askToSelect}
            tooltip={tooltip}
            required={required}
            meta={metaData}
            entityName={entityName}
            missingDataNote={props.missingDataNote}
        />
    )
}
