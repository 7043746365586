import React, { Component, Fragment } from 'react'
import Tab from 'components/ui/Tab'
import { Field } from 'react-final-form'
import isEmpty from 'lodash/isEmpty'
import {
    fieldAssociation,
    fieldCheckboxStd,
    fieldHidden,
    fieldInput,
    fieldInputAmount,
    fieldInputNumber,
    fieldInternalCode,
    fieldMethodCode,
    fieldSelect,
    fieldRadioList,
    fieldState,
    fieldTextarea,
    fieldYesNo,
    FormGroup,
} from 'components/ui/form/renderers'
import fieldDate from 'components/ui/form/fieldDate'
import fieldApiKey from 'components/ui/form/fieldApiKey.jsx'
import fieldAuthCode from 'components/ui/form/fieldAuthCode.jsx'
import fieldFedexAcceptEula from 'components/ui/form/fieldFedexAcceptEula'
import fieldShippingGoals from 'components/ui/form/fieldShippingGoals'
import fieldCarriersSelectorList from 'components/ui/form/fieldCarriersSelectorList'
import {
    FieldApiTokenLabel,
    FieldApiTokenEC,
    FieldApiTokenShippingInsights,
    FieldApiTokenShipStation,
} from 'components/ui/form/FieldApiToken'
import ConditionRulesField from 'components/ui/form/ConditionRulesField'
import { fieldShippingBoxesSelection } from 'components/ui/form/packingRules/fieldShippingBoxesSelection'
import NewQuickRule from 'components/ui/form/NewQuickRule'
import NewTableRateField from 'components/ui/form/NewTableRateField'
import fieldLabelCarrierValidator from 'components/ui/form/fieldLabelCarrierValidator'
import fieldFile from 'components/ui/form/fieldFile.jsx'
import { getValidator } from 'components/ui/form/validators'
import { connect } from 'react-redux'
import Grid from 'containers/Grid'
import store from 'reducers/store'
import resolveFields from 'components/ui/form/fieldResolver'
import EntityEditorInfoContext from 'components/dashboard/entityEditorInfoContext'
import fieldCsvUploader from 'components/ui/form/fieldCsvUploader'
import { compareField } from 'utils/fields'
import { isDefaultValue } from 'components/ui/form/fieldRequirements'
import DynamicFormContext from './DynamicFormContext'
import DefaultFormValues from './DefaultFormValues'
import { fieldRuleAction } from 'components/ui/form/fieldRuleAction'
import fieldShippingMethods from 'components/ui/form/fieldShippingMethods'
import checkFieldRequirements from './fieldRequirements'
import fieldCarrierTypeSelector from './fieldTypes/CarrierTypeSelector'
import { RuleBuilderActionField } from './RuleBuilderActionField'
import { PackingRuleMethodField } from './PackingRuleMethodField'
import { UpsellButtonField } from './UpsellButtonField'
import cx from 'classnames'

export class DynamicForm extends Component {
    static contextType = DefaultFormValues

    decorators
    renderedFields

    state = {
        disabled: false,
        changed: false,
        data: {},
        defaultValues: {},
    }

    constructor(props) {
        super(props)

        this.replacePlaceholders = this.replacePlaceholders.bind(this)
    }

    componentDidMount() {
        this.fillInDefaultValues()
    }

    fillInDefaultValues() {
        const { values: defaultValues = {} } = this.context
        const { values = {}, tab } = this.props
        const defaultValuesRes = {}
        const fieldList = Object.values(resolveFields(tab))

        for (const field of fieldList) {
            const { name } = field
            const defaultValue = defaultValues[name]
            defaultValuesRes[name] = isDefaultValue(values[name], defaultValue)
        }

        this.setState({ defaultValues: defaultValuesRes })
    }

    /**
     * Auto-select values in drop-downs, etc
     * @param feature
     * @param data
     * @returns {{}}
     */
    static prepareDataToDisplay(tab, data, optionSources, changed) {
        let result = data
        if (!result) {
            result = {}
        }

        if (changed || !tab || !tab.fields || !result || !optionSources) {
            return result
        }

        const fieldList = Object.values(resolveFields(tab))
        for (const field of fieldList) {
            if (
                field.type === 'number' &&
                field.defaultValue &&
                !result[field.name]
            ) {
                result[field.name] = field.defaultValue
            } else if (
                field.type === 'association' &&
                field.forceSelect &&
                !result[field.name]
            ) {
                const optionSource = optionSources[field.option_source]
                if (Array.isArray(optionSource) && optionSource.length > 0) {
                    result[field.name] = optionSource[0].value
                }
            }
        }

        return result
    }

    /**
     * @param props
     * @param state
     * @returns {State}
     */
    static getDerivedStateFromProps(props, state) {
        const { data, tab, optionSources } = props
        const { changed } = state

        state.data = DynamicForm.prepareDataToDisplay(
            tab,
            data,
            optionSources,
            changed
        )

        return state
    }

    /**
     * @param field
     * @param values
     * @returns {boolean}
     */
    checkRequirements = (field, values) => {
        const {
            platform,
            plan,
            enabledFeatures,
            userSettings,
            crossBorderSettings,
        } = this.props

        if (field.require) {
            if (!values) {
                return false
            }

            const { defaultValues } = this.state
            return checkFieldRequirements(
                field.require,
                {
                    platform,
                    plan,
                    enabledFeatures,
                    userSettings,
                    renderedFields: this.renderedFields,
                    isDefaultValue: defaultValues[field.name],
                    crossBorderSettings,
                },
                values
            )
        }
        return true
    }

    /**
     * Selects one of the values based on requirements
     * @param value
     * @param values
     */
    conditionalValue(value, values) {
        if (Array.isArray(value)) {
            for (const item of value) {
                if (this.checkRequirements(item, values)) {
                    return item.value
                }
            }
            return ''
        }

        return value ? String(value) : ''
    }

    /**
     * @param field
     * @param idx
     * @param values
     * @returns {*}
     */
    renderSection(field, idx, values, sectionClasses = {}) {
        const classes = this.props?.classes || sectionClasses
        const fieldColClasses = this.props?.fieldColClasses || {}

        let fields = null
        let subsections = null
        if (field.columns) {
            let colClass = 'form-col-2'
            if (field.column_number) {
                colClass = 'form-col-' + String(field.column_number)
            }
            if (classes.formCol) {
                colClass = classes.formCol
            }
            if (fieldColClasses[field.name]) {
                colClass = fieldColClasses[field.name]
            }

            let fieldsCount = 0
            fields = (
                <Fragment key={idx}>
                    {field.columns.map((column, idx) => (
                        <div key={idx} className={colClass}>
                            {column.map((field, idx) => {
                                const renderedField = this.renderField(
                                    { ...field, oneLine: true },
                                    idx,
                                    true,
                                    values
                                )

                                if (renderedField) {
                                    fieldsCount++
                                }

                                return renderedField
                            })}
                        </div>
                    ))}
                </Fragment>
            )

            if (!fieldsCount) {
                // hide section without any fields
                return null
            }
        } else if (field.subsections) {
            let fieldsCount = 0

            subsections = field.subsections.map((subsection) => {
                const { columns, name, title, column_number, nospace } =
                    subsection
                let colClass = 'form-col-2'
                if (column_number) {
                    colClass = 'form-col-' + String(column_number)
                }

                if (!this.checkRequirements(subsection, values)) {
                    return null
                }

                return (
                    <Fragment key={name}>
                        <div className={cx('subsection', { nospace })}>
                            {title && (
                                <div className="ent-cont-subhead">
                                    <h3>{title}</h3>
                                </div>
                            )}
                            <div className="form-row">
                                {columns.map((column, idx) => (
                                    <div key={idx} className={colClass}>
                                        {column.map((field, idx) => {
                                            const renderedField =
                                                this.renderField(
                                                    { ...field, oneLine: true },
                                                    idx,
                                                    true,
                                                    values
                                                )

                                            if (renderedField) {
                                                fieldsCount++
                                            }

                                            return renderedField
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Fragment>
                )
            })

            if (!fieldsCount) {
                // hide section without any fields
                return null
            }
        } else {
            const fieldTypes = {}
            let fieldTypeIdx = 0
            const childFields = field.fields
            const processedFields = childFields
                ? childFields
                      .map((field, idx) =>
                          this.renderField(
                              {
                                  ...field,
                                  oneLine: true,
                              },
                              idx,
                              true,
                              values
                          )
                      )
                      .filter((field, idx) => {
                          // populate fieldTypes so we could determine field type later (needed for the grid below)
                          if (field !== null) {
                              fieldTypes[fieldTypeIdx] = childFields[idx].type
                              fieldTypeIdx++
                          }

                          return field !== null
                      })
                : []

            if (processedFields.length === 0) {
                // hide section without any fields
                return null
            }

            const half = Math.round(Math.ceil(processedFields.length / 2))
            const columns =
                processedFields.length > 1
                    ? [
                          processedFields.slice(0, half),
                          processedFields.slice(half),
                      ]
                    : [processedFields, []]

            // grid field should take the whole row
            if (processedFields.length === 1 && fieldTypes[0] === 'grid') {
                fields = (
                    <Fragment key={idx}>
                        <div className="form-col-1">{processedFields[0]}</div>
                    </Fragment>
                )
            } else {
                fields = (
                    <Fragment key={idx}>
                        <div className="form-col-2">{columns[0]}</div>
                        <div className="form-col-2">{columns[1]}</div>
                    </Fragment>
                )
            }
        }

        const sectionTitle = this.conditionalValue(field.title, values),
            sectionHint = this.conditionalValue(field.hint, values),
            sectionHint2 = this.conditionalValue(field.hint2, values),
            emptyHead =
                (!sectionTitle || !sectionTitle.length) &&
                (!sectionHint || !sectionHint.length)

        const rootClassName = classes.root ? classes.root : 'ent-cont-section'
        const formRowClassName = classes.formRow ? classes.formRow : 'form-row'

        return (
            <div
                key={idx}
                className={rootClassName + (emptyHead ? ' empty' : '')}
            >
                {emptyHead ? null : (
                    <div className="ent-cont-subhead">
                        {sectionHint2 && sectionHint2.length && (
                            <div
                                className="ent-cont-hint-right"
                                dangerouslySetInnerHTML={{
                                    __html: sectionHint2,
                                }}
                            />
                        )}
                        <h3>{sectionTitle}</h3>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: sectionHint,
                            }}
                        />
                    </div>
                )}

                {fields && <div className={formRowClassName}>{fields}</div>}
                {subsections && subsections}
            </div>
        )
    }

    /**
     * @param field
     * @param idx
     * @param values
     * @returns {*}
     */
    renderFieldList(field, idx, values) {
        const childFields = field.body

        const {
            app: {
                entity: { data },
            },
        } = store.getState()

        const processedFields = []
        let blockIdx = 0

        if (!data[field.list]) {
            console.error('missing list data for:', field.list)
            return null
        }

        for (const entry of data[field.list]) {
            const blockValues = { ...values }
            for (const [key, value] of Object.entries(entry)) {
                blockValues['_block_' + key] = value
            }

            for (const childField of childFields) {
                const override =
                    blockValues[
                        field.name + '__' + blockIdx + '__' + childField.name
                    ]

                if (override !== undefined) {
                    blockValues['_block_' + childField.name] = override
                } else {
                    blockValues['_block_' + childField.name] =
                        entry[childField.name]
                }
            }

            processedFields.push(
                ...childFields.map((childField, cidx) =>
                    childField.skip_transform
                        ? this.renderField(
                              {
                                  ...childField,
                                  name: childField.name,
                                  oneLine: true,
                              },
                              idx + '_' + cidx + '_' + blockIdx,
                              true,
                              blockValues
                          )
                        : this.renderField(
                              {
                                  ...childField,
                                  name:
                                      field.name +
                                      '__' +
                                      blockIdx +
                                      '__' +
                                      childField.name,
                                  oneLine: true,
                              },
                              idx + '_' + cidx + '_' + blockIdx,
                              true,
                              blockValues
                          )
                )
            )

            blockIdx++
        }

        return <Fragment key={idx}>{processedFields}</Fragment>
    }

    /**
     * @param field
     * @param idx
     * @param compact
     * @param values
     * @returns {*}
     */
    renderField = (field, idx, compact, values) => {
        const { name } = field
        if (this.renderedFields[name]) {
            return null
        }

        if (!this.checkRequirements(field, values)) {
            return null
        }

        this.renderedFields[name] = true

        if (field.type === 'section') {
            return this.renderSection(field, idx, values)
        }

        if (field.type === 'table_section') {
            return this.renderSection(field, idx, values, {
                root: 'ent-cont-table-section',
            })
        }

        if (field.type === 'field_list') {
            return this.renderFieldList(field, idx, values)
        }

        if (field.type === 'field_group') {
            return (
                <FormGroup
                    className="field-group"
                    key={idx}
                    meta={{}}
                    labelTag={<h5>{field.title}</h5>}
                    label={this.conditionalValue(field.title, values)}
                    hint={this.conditionalValue(field.hint, values)}
                    hintPosition={field.hint_position}
                    fullWidth={field.full_width}
                    required={false}
                >
                    {field.fields.map((childField, idx) =>
                        this.renderField(childField, idx, false, values)
                    )}
                </FormGroup>
            )
        }

        const fieldProps = this.getFieldProps(field, values)

        if (this.shouldForceOneLine()) {
            fieldProps.oneLine = true
        }

        if (this.props.noAutocomplete) {
            fieldProps.noAutocomplete = true
        }

        if (fieldProps.component === 'empty') return <></>

        return (
            <Field
                key={name + '_' + idx}
                title={this.replacePlaceholders(
                    this.conditionalValue(field.title)
                )}
                parse={(value, name) => {
                    if (value === undefined && name.indexOf('__') !== -1) {
                        value = []
                    }
                    return value
                }}
                name={this.getFieldName(field, fieldProps)}
                render={this.getRenderer(field)}
                disabled={this.state.disabled}
                compact={compact}
                {...fieldProps}
                format={(value, name) => {
                    if (value === undefined) {
                        if (name.indexOf('__') !== -1) {
                            const parts = name.split('__')
                            if (values && parts && parts.length === 3) {
                                try {
                                    value = values[parts[0]][parts[1]][parts[2]]
                                } catch (e) {
                                    console.error(e)
                                }
                            }
                        }
                    }
                    return value
                }}
            />
        )
    }

    getFieldName(field, fieldProps) {
        if (
            field.type === 'state' &&
            (!fieldProps.options || !fieldProps.options.length)
        ) {
            return field.text_field_name ? field.text_field_name : field.name
        } else {
            return field.name
        }
    }

    /**
     * @param field
     * @param values
     * @returns {Object}
     */
    getFieldProps(field, values) {
        const { forceRowStyle, errors, fieldProps } = this.props

        let extraProps = {
            leftIcon: field.left_icon,
            rightLabel: field.right_label,
            fields: field.fields,
            readOnly: field.readOnly,
            error: (errors || {})[field.name],
            hint: this.replacePlaceholders(
                this.conditionalValue(field.hint, values)
            ),
            extraHint: this.replacePlaceholders(
                this.conditionalValue(field.extra_hint, values)
            ),
            placeholder: field.placeholder,
            oneLine: field.oneLine,
            stepForms: field.step_forms,
            hideTitleAndHint: field.hide_title_and_hint,
            prefix: field.prefix,
            fieldProps,
        }

        if (field.default_value_from_url && isEmpty(values[field.name])) {
            const urlParams = new URLSearchParams(window.location.search)

            if (urlParams.get(field.name)) {
                extraProps.initialValue = urlParams.get(field.name)
            }
        }

        if (field.readOnly && field.readOnly.require) {
            delete extraProps.readOnly
            if (this.checkRequirements(field.readOnly, values)) {
                extraProps.readOnly = true
            } else {
                extraProps.readOnly = false
            }
        }

        if (forceRowStyle) {
            extraProps.fullWidth = true
            extraProps.className = forceRowStyle
        }

        switch (field.type) {
            case 'checkbox':
                extraProps.type = 'checkbox'
                extraProps.disabled = handleConditions(field.disabled, values)
                extraProps.tooltip = handleConditions(field.tooltip, values)

                if (field.update_on_change) {
                    extraProps.updateOnChange = field.update_on_change
                }
                break

            case 'password':
                extraProps.type = 'password'
                break

            case 'amount':
            case 'number':
                extraProps.transformValue = field.transform_value

                if (field.step !== undefined) {
                    extraProps.step = field.step
                }
                if (field.min !== undefined) {
                    extraProps.min = field.min
                }
                if (field.max !== undefined) {
                    extraProps.max = field.max
                }
                break

            case 'select':
                if (!field.options) {
                    extraProps.options = this.props.optionSources
                        ? this.props.optionSources[
                              field.option_source || field.name
                          ]
                        : []
                } else {
                    extraProps.options = field.options
                }
                break

            case 'time': {
                extraProps.type = 'time'
                extraProps.component = fieldDate
                break
            }

            case 'csv_file':
                extraProps.values = values
                extraProps.sampleFile = field.sample_file
                extraProps.sampleText = field.sample_text
                extraProps.postButton = field.post_button
                extraProps.postUrl = field.post_url
                extraProps.component = fieldCsvUploader
                break

            case 'date': {
                extraProps.type = 'date'
                extraProps.component = fieldDate
                if (field.sort) {
                    extraProps.sort = field.sort
                }

                if (field.multiSelect !== undefined) {
                    extraProps.multiSelect = field.multiSelect
                }
                break
            }

            case 'hidden': {
                extraProps.setValue = field.value
                break
            }

            case 'api_key': {
                extraProps.component = fieldApiKey
                extraProps.values = values
                break
            }

            case 'rule_action': {
                extraProps.component = fieldRuleAction
                extraProps.emptyMessage = field.empty_message
                break
            }

            case 'upsell_button': {
                extraProps.component = UpsellButtonField
                extraProps.upsellPlans = field.upsell_plans
                break
            }

            case 'shipping_goals': {
                extraProps.component = fieldShippingGoals
                extraProps.shippingGoalsOptions =
                    this.props.optionSources[field.option_source]
                extraProps.values = values
                break
            }

            case 'carriers_list': {
                extraProps.component = fieldCarriersSelectorList
                extraProps.values = values
                extraProps.options = field.options
                break
            }

            case 'shipstation_token': {
                extraProps.component = FieldApiTokenShipStation
                extraProps.values = values
                break
            }

            case 'shipping_insights_token': {
                extraProps.component = FieldApiTokenShippingInsights
                extraProps.values = values
                break
            }

            case 'carrier_type_selector': {
                extraProps.component = fieldCarrierTypeSelector
                extraProps.values = values
                break
            }

            case 'create_table_rate': {
                extraProps.component = NewTableRateField
                extraProps.values = values
                break
            }

            case 'label_carrier_validator': {
                extraProps.component = fieldLabelCarrierValidator
                extraProps.values = values
                extraProps.fields = field.fields
                break
            }

            case 'label_token': {
                extraProps.component = FieldApiTokenLabel
                extraProps.values = values
                break
            }

            case 'ec_token': {
                extraProps.component = FieldApiTokenEC
                extraProps.values = values
                break
            }

            case 'auth_code': {
                extraProps.component = fieldAuthCode
                extraProps.values = values
                break
            }

            case 'fedex_accept_eula': {
                extraProps.component = fieldFedexAcceptEula
                extraProps.values = values
                break
            }

            case 'datetime': {
                extraProps.type = 'datetime'
                extraProps.component = fieldDate
                break
            }

            case 'internal_code': {
                extraProps.component = fieldInternalCode
                break
            }

            case 'file': {
                extraProps.values = values
                extraProps.component = fieldFile
                break
            }

            case 'rule_builder_actions': {
                extraProps.values = values
                extraProps.component = RuleBuilderActionField
                extraProps.actions = field.actions
                break
            }

            case 'packing_rule_method': {
                extraProps.values = values
                extraProps.component = PackingRuleMethodField
                extraProps.options =
                    this.props.optionSources[field.option_source] || []

                break
            }

            case 'condition_rules': {
                extraProps.values = values
                extraProps.component = ConditionRulesField
                break
            }

            case 'new_quick_rule': {
                extraProps.values = values
                extraProps.component = NewQuickRule
                extraProps.actions = field.actions
                extraProps.conditions = field.conditions
                extraProps.shippingMethodForm = field.shipping_method_form
                break
            }

            case 'grid': {
                extraProps.component = Grid
                extraProps.columns = field.columns
                extraProps.dataSource = field.data_source
                extraProps.dataSourceEntity = field.data_source_entity
                extraProps.dataSourceIdField = field.data_source_id_field
                extraProps.form = field.form
                extraProps.fullWidth = true
                extraProps.values = values
                extraProps.paginate = field.paginate
                extraProps.actionsClass = field.actions_class
                extraProps.gridAddButton = field.grid_add_button
                extraProps.emptyHint = field.empty_hint
                extraProps.emptyImportCsv = field.empty_import_csv
                extraProps.emptyTitle = field.empty_title
                extraProps.emptyMessage = field.empty_message
                extraProps.fullScreen = field.full_screen
                extraProps.title = this.conditionalValue(field.title, values)
                extraProps.hint = this.conditionalValue(field.hint, values)
                extraProps.hint2 = this.conditionalValue(field.hint2, values)

                if (field.validate_shipping_methods) {
                    extraProps.validateShippingMethods = true
                }
                const { validate_associations } = field
                if (validate_associations) {
                    extraProps.validateAssociations = {
                        dataSource: validate_associations.data_source,
                        dataSourceEntity:
                            validate_associations.data_source_entity,
                        dataSourceIdField:
                            validate_associations.data_source_id_field,
                    }
                }
                break
            }

            case 'method_code':
                extraProps.dataSourceField = field.data_source_field
                extraProps.dataSourceIdField = field.data_source_id_field
                extraProps.options = this.props.optionSources
                    ? this.props.optionSources[field.option_source]
                    : []
                break

            case 'country':
            case 'radio_list':
            case 'association':
            case 'shipping_boxes_selection':
                if (field.multiSelect !== undefined) {
                    extraProps.multiSelect = field.multiSelect
                }
                if (field.tooltip !== undefined) {
                    extraProps.tooltip = field.tooltip
                }
                if (field.forceSelect) {
                    extraProps.forceSelect = field.forceSelect
                }
                if (field.missing_data_note) {
                    extraProps.missingDataNote = field.missing_data_note
                }
                if (field.only_options) {
                    extraProps.onlyOptions = field.only_options
                }

                if (
                    [
                        'association',
                        'country',
                        'radio_list',
                        'shipping_boxes_selection',
                    ].includes(field.type)
                ) {
                    extraProps.type = 'select'
                }

                extraProps.emptyMessage = field.emptyMessage
                extraProps.allowCreate = field.allow_create
                extraProps.editor = field.editor
                extraProps.modal = field.modal
                extraProps.newEntityModalTitle = field.new_entity_modal_title
                extraProps.askToSelect = field.ask_to_select
                extraProps.optionGroups = field.option_groups
                extraProps.size = field.size
                extraProps.searchable =
                    field.searchable === undefined ? true : field.searchable

                extraProps.options = this.props.optionSources
                    ? this.props.optionSources[field.option_source]
                    : []

                if (field.update_on_change) {
                    extraProps.updateOnChange = field.update_on_change
                }
                if (field.highlight_value) {
                    extraProps.highlightValue = field.highlight_value
                    extraProps.highlightValuePrefix =
                        field.highlight_value_prefix
                }

                if (field.entity_name) {
                    extraProps.entityName = field.entity_name
                }

                if (
                    field.exclude &&
                    extraProps.options &&
                    extraProps.options.length
                ) {
                    const optionsToExclude = []
                    for (const entry of field.exclude) {
                        if (this.checkRequirements(entry, values)) {
                            optionsToExclude.push(entry.value)
                        }
                    }

                    if (optionsToExclude.length) {
                        extraProps.options = extraProps.options.filter(
                            (option) => !optionsToExclude.includes(option.value)
                        )
                    }
                }

                // adds blank option with the specified title
                if (field.include_blank) {
                    extraProps.options = [
                        { title: field.include_blank, value: '' },
                        ...extraProps.options,
                    ]
                }

                break

            case 'shippingMethods':
                if (field.tooltip !== undefined) {
                    extraProps.tooltip = field.tooltip
                }
                if (field.missing_data_note) {
                    extraProps.missingDataNote = field.missing_data_note
                }
                if (field.only_options) {
                    extraProps.onlyOptions = field.only_options
                }

                extraProps.emptyMessage = field.emptyMessage
                extraProps.list = field.list
                extraProps.type = 'select'
                extraProps.allowCreate = field.allow_create
                extraProps.editor = field.editor
                extraProps.modal = field.modal
                extraProps.newEntityModalTitle = field.new_entity_modal_title
                extraProps.askToSelect = field.ask_to_select
                extraProps.optionGroups = field.option_groups
                extraProps.size = field.size
                extraProps.optionSources = this.props.optionSources

                if (field.entity_name) {
                    extraProps.entityName = field.entity_name
                }

                break

            case 'state': {
                let countryValue = values[field.country_field]
                if (Array.isArray(countryValue)) {
                    countryValue = String(countryValue.find((value) => !!value))
                }

                extraProps.options = this.props.optionSources
                    ? this.props.optionSources[
                          countryValue ? 'states_' + countryValue : 'states_US'
                      ]
                    : []

                if (field.forceSelect) {
                    extraProps.forceSelect = true
                    extraProps.emptyMessage = field.emptyMessage
                }

                extraProps.idFieldName = field.name
                extraProps.textFieldName = field.text_field_name
                extraProps.countryField = field.country_field
                extraProps.multiSelect = field.multiSelect
                extraProps.searchable =
                    field.searchable === undefined ? true : field.searchable
                extraProps.component = fieldState
                extraProps.countryValue = countryValue

                break
            }
            case 'industry_sector': {
                let industryValue = values[field.industry_field]

                if (Array.isArray(industryValue)) {
                    industryValue = String(
                        industryValue.find((value) => !!value)
                    )
                }

                const options = this.props.optionSources[field.option_source]

                const placeholderOption = options.find((sector) =>
                    isEmpty(sector.value)
                )

                const industryOptions = options.filter(
                    (sector) =>
                        String(sector.industry_id) === String(industryValue)
                )

                extraProps.options = [placeholderOption, ...industryOptions]

                if (industryOptions.length > 0) {
                    extraProps.component = fieldSelect
                } else {
                    extraProps.component = 'empty'
                }

                extraProps.skipNotExists = true

                break
            }
        }

        if (field.unit) {
            extraProps.unit = field.unit
        }

        if (field.defaultValue) {
            extraProps.defaultValue = field.defaultValue
        }

        if (field.validate) {
            extraProps.validate = getValidator(
                field.validate,
                values,
                (updatedValues) =>
                    this.checkRequirements(field.validate, updatedValues),
                this.props.getValidatorByType
            )

            extraProps.required = [field.validate].flat().includes('nonempty')
        }

        return extraProps
    }

    /**
     * Returns renderer for field type
     * @param field
     * @returns {fieldInput}
     */
    getRenderer(field) {
        let fieldRenderer = fieldInput

        switch (field.type) {
            case 'hidden':
                fieldRenderer = fieldHidden
                break

            case 'textarea':
                fieldRenderer = fieldTextarea
                break

            case 'checkbox':
                fieldRenderer = fieldCheckboxStd
                break

            case 'number':
                fieldRenderer = fieldInputNumber
                break

            case 'amount':
                fieldRenderer = fieldInputAmount
                break

            case 'yesno':
                fieldRenderer = fieldYesNo
                break

            case 'country':
            case 'select':
                fieldRenderer = fieldSelect
                break

            case 'radio_list':
                fieldRenderer = fieldRadioList
                break

            case 'association':
                fieldRenderer = fieldAssociation
                break

            case 'shipping_boxes_selection':
                fieldRenderer = fieldShippingBoxesSelection
                break

            case 'shippingMethods':
                fieldRenderer = fieldShippingMethods
                break

            case 'state':
                fieldRenderer = undefined
                break

            case 'industry_sector':
                fieldRenderer = undefined
                break

            case 'internal_code':
                fieldRenderer = undefined
                break

            case 'method_code':
                fieldRenderer = fieldMethodCode
                break

            case 'date':
            case 'datetime':
            case 'time':
            case 'api_key':
            case 'csv_file':
                return undefined
        }

        return fieldRenderer
    }

    prepareTabFields(fields) {
        return fields
    }

    getFieldsCount(fields) {
        if (!fields) {
            return 0
        }

        let count = 0
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i]
            if (field.type === 'field_group') {
                count += this.getFieldsCount(field.fields)
            } else {
                count++
            }
        }

        return count
    }

    shouldBeCompact(fields) {
        return !!(fields && fields.length > 4)
    }

    shouldForceOneLine() {
        return !!this.props.oneLine
    }

    renderFields(fields, compact, values) {
        return fields
            ? this.prepareTabFields(
                  fields.map((field, idx) =>
                      this.renderField(field, String(idx), compact, values)
                  )
              )
            : null
    }

    renderColumns(columns, compact, values) {
        let colClass = 'col-sm-6 form-col-2'
        if (columns.length === 4) {
            colClass = 'col-sm-3 form-col-2'
        } else if (columns.length === 3) {
            colClass = 'col-sm-4 form-col-2'
        }

        return (
            <div className="fields-wrap form-horizontal">
                <div className="row">
                    {columns.map((column, idx) => (
                        <div className={colClass} key={idx}>
                            {column.map((field, idx) => {
                                return this.renderField(
                                    { ...field, oneLine: true },
                                    String(idx),
                                    true,
                                    values
                                )
                            })}
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    /**
     * Renders tab fields
     */
    renderTabContent(tab, values) {
        if (!tab) {
            console.error('empty tab passed')
            return null
        }

        const compact = this.shouldBeCompact(tab.fields)

        return (
            <Tab key={tab.title} name={tab.title} title={tab.title}>
                <EntityEditorInfoContext.Consumer>
                    {(context) => {
                        const hint = tab
                            ? context.renderSectionHint(tab.name)
                            : null

                        if (hint) {
                            return hint
                        }
                    }}
                </EntityEditorInfoContext.Consumer>

                {tab.hint ? (
                    <div className={'ent-cont-header'}>
                        {tab.help_url ? (
                            <button
                                className="btn btn-sm btn-bl-o help"
                                type="button"
                                onClick={() => {
                                    if (tab) {
                                        window.open(tab.help_url, '_blank')
                                    }
                                }}
                            >
                                Learn More
                            </button>
                        ) : null}
                        <span
                            dangerouslySetInnerHTML={{
                                __html: this.replacePlaceholders(tab.hint),
                            }}
                        />
                    </div>
                ) : null}
                {tab.columns
                    ? this.renderColumns(tab.columns, compact, values)
                    : this.renderFields(tab.fields, compact, values)}
            </Tab>
        )
    }

    /**
     * Replaces ${placeholders} inside hints and titles
     * @param text
     * @returns {string}
     */
    replacePlaceholders(text) {
        const { placeholders } = this.props

        if (text === undefined || placeholders === undefined) {
            return text
        }

        for (let [name, value] of placeholders) {
            text = text.replace('${' + name + '}', value)
        }

        return text
    }

    getFormContext() {
        const { values, form, optionSources } = this.props
        return {
            form,
            optionSources,
            values,
            conditionalValue: (hint) => {
                return this.conditionalValue(hint, values)
            },
        }
    }

    /**
     *
     * @returns {*}
     */
    render() {
        const { tab, values } = this.props

        this.renderedFields = {}

        return (
            <DynamicFormContext.Provider value={this.getFormContext()}>
                {this.renderTabContent(tab, values)}
            </DynamicFormContext.Provider>
        )
    }
}

export default connect(
    (
        {
            app: {
                user_settings,
                platform,
                plan,
                enabled_features,
                option_sources,
                entity: { option_sources: entityOptionSources } = {},
                cross_border_settings,
            },
        },
        { optionSources: optionSourcesOverride }
    ) => {
        return {
            userSettings: user_settings,
            platform,
            plan,
            enabledFeatures: enabled_features,
            optionSources: optionSourcesOverride
                ? { ...option_sources, ...optionSourcesOverride }
                : { ...option_sources, ...entityOptionSources },
            crossBorderSettings: cross_border_settings,
        }
    }
)(DynamicForm)

/**
 * Either returns a value or determines it based on the conditions in .when
 * @param value
 * @param values
 */
function handleConditions(value, values) {
    if (value && value.when) {
        const { when } = value

        let returnValue = value.value
        if (returnValue === undefined) {
            returnValue = true
        }

        if (Array.isArray(when)) {
            let result = true

            for (const entry of when) {
                const [key, comparison] = Object.entries(entry)[0]
                const [op, targetValue] = Object.entries(comparison)[0]
                if (!compareField(values[key], targetValue, op)) {
                    result = false
                    break
                }
            }

            if (result) {
                return returnValue
            }
        } else {
            const [key, comparison] = Object.entries(value.when)[0]
            const [op, targetValue] = Object.entries(comparison)[0]
            if (compareField(values[key], targetValue, op)) {
                return returnValue
            }
        }

        return undefined
    } else {
        return value
    }
}
