import AjaxTableDataSource from './AjaxTableDataSource'

class ShippingMethodsDataSource extends AjaxTableDataSource {
    get endpointConfig() {
        const { values } = this.config

        return {
            list: `/ratesmgr/carriers/${values.id}/get_shipping_methods`,
            get: (id) => `/ratesmgr/shipping_methods/${id}`,
            delete: (id) => `/ratesmgr/shipping_methods/${id}`,
            update: (id) => `/ratesmgr/shipping_methods/${id}`,
            create: '/ratesmgr/shipping_methods',
        }
    }

    get paramsKey() {
        return 'ratesmgr_carriers_shipping_method'
    }

    hasAssociatedRecords() {
        return false
    }

    get isDuplicatable() {
        return false
    }
}

export default ShippingMethodsDataSource
