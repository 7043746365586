import { useDispatch, useSelector } from 'react-redux'
import { axiosClient } from 'net/ajax'
import { useQuery } from 'react-query'
import { useEffect, useMemo, useRef } from 'react'
import { loadCarrierForm } from '../reducers/modules/integrations'

/**
 * Returns a list of available carrier types (caution: can include agencies, etc)
 * @returns {*[]}
 */
export function useCarrierTypes(preload = false) {
    const dispatch = useDispatch()
    const isLoaded = useRef(false)
    const availableTypes = useSelector(
        ({
            app: {
                integrations: { available_integrations: integrations } = {},
                network_action,
            },
        }) => integrations
    )

    // make sure we have carrier types on mount
    useEffect(() => {
        if (preload && !availableTypes && !isLoaded.current) {
            isLoaded.current = true
            dispatch(loadCarrierForm())
        }
    }, [])

    return availableTypes ?? []
}

export function useAvailableCarrierNames() {
    const availableCarriers = useCarrierTypes()
    const result = useMemo(() => {
        return availableCarriers.map((carrier) => carrier.name)
    }, [availableCarriers?.length])
    return result
}

/**
 * Returns url of the carrier logo + logos mapping
 * @param name
 * @returns {{logo: (*|null), logos}}
 */
export function useCarrierLogo(name) {
    const logos = useSelector(({ app }) => app.integrations.logos)
    return { logo: logos ? logos[name] : null, logos }
}

async function getInstalledCarrierTypes() {
    const response = await axiosClient.get(
        '/ratesmgr/onboarding/installed-carrier-types.json',
        {}
    )
    return response.data
}

/**
 * Use to retrieve a list of the types of created carriers
 * @returns {{isLoadingError: false | true, data: unknown, isRefetchError: false | true, isFetching: boolean, isPlaceholderData: boolean, refetch: {(options?: RefetchOptions): Promise<QueryObserverResult<unknown, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<unknown, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<unknown, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<unknown, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<unknown, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<any, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<any, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<any, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<any, unknown>>, (options?: RefetchOptions): Promise<QueryObserverResult<any, unknown>>}, error: unknown, remove: {(): void, (): void, (): void, (): void, (): void, (): void, (): void, (): void, (): void, (): void}, isFetchedAfterMount: boolean, isLoading: false | true, errorUpdatedAt: number, dataUpdatedAt: number, isError: false | true, isPreviousData: boolean, isFetched: boolean, isIdle: true | false, carrierTypes: unknown, isStale: boolean, failureCount: number, isSuccess: false | true, status: "idle" | "error" | "loading" | "success"}}
 */
export function useInstalledCarrierTypes() {
    const queryResult = useQuery('installed_carrier_types', () =>
        getInstalledCarrierTypes()
    )
    return {
        ...queryResult,
        carrierTypes: queryResult.data,
    }
}
