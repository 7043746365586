import React, { useEffect } from 'react'
import {
    WizardStateProvider,
    useWizardState,
} from 'components/dashboard/wizard/WizardStateContext'
import SelectConditionStep from './SelectConditionStep'
import ConditionFlow from '../ConditionFlow'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import { ConditionsProvider } from '../ConditionsProvider'

export const StackFlowContext = React.createContext(null)

const StackConditionFlow = () => {
    const stackWizard = useWizardState({ context: StackFlowContext })
    const selectedConditions = stackWizard.context.selectedConditions || []

    const onCompleted = (context) => {
        stackWizard.submitStep({
            ...stackWizard.context,
            conditionField: context,
        })
    }

    return (
        <ConditionFlow
            selectedConditions={selectedConditions}
            onCompleted={onCompleted}
        />
    )
}

export const STEPS_COMPONENTS = {
    selectCondition: SelectConditionStep,
    conditionFlow: StackConditionFlow,
}

const INITIAL_STEPS = ['selectCondition', 'conditionFlow']

const StackFlowComponent = () => {
    const wizard = useWizardState({ context: StackFlowContext })

    const CurrentStepComponent = STEPS_COMPONENTS[wizard.currentStep]

    return CurrentStepComponent ? <CurrentStepComponent /> : <div></div>
}

const StackFlowContainer = () => {
    const { onCompleted } = useConditionRulesField()

    return (
        <ConditionsProvider>
            <WizardStateProvider
                onCompleted={onCompleted}
                context={StackFlowContext}
                steps={INITIAL_STEPS}
            >
                <StackFlowComponent />
            </WizardStateProvider>
        </ConditionsProvider>
    )
}

export default StackFlowContainer
