import { useMutation, useQuery } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

const getCustomerGroups = () => {
    return axiosClient
        .get('/ratesmgr/criteria/customer_groups')
        .then(({ data }) => data)
}

export const useGetCustomerGroups = () => {
    const { data: customerGroups = [], ...rest } = useQuery(
        ['customer_groups'],
        getCustomerGroups
    )

    return { customerGroups, ...rest }
}

export const createCustomerGroup = (variables) =>
    axiosClient.post('/ratesmgr/criteria/customer_groups', {
        render_entity: true,
        ratesmgr_criteria_customer_group: variables,
    })

export const useCreateCustomerGroup = () => {
    const props = useMutation(createCustomerGroup)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        createCustomerGroup: props.mutate,
    }
}
