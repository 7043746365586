import React from 'react'
import styled from 'styled-components'
import {
    space,
    color,
    typography,
    border,
    layout,
    flexbox,
    shadow,
} from 'styled-system'

export const Box = styled.div`
    box-sizing: border-box;
    ${space}
    ${color}
    ${typography}
    ${border}
    ${layout}
    ${flexbox}
    ${shadow}
`

const HeadingBase = ({ level, as: Component = `h${level}`, ...props }) => (
    <Component {...props} />
)

export const Heading = styled(HeadingBase)`
    margin: 0;
    ${space}
    ${typography}
    ${color}
`

export const Text = styled.p`
    margin: 0;
    ${color}
    ${typography}
	${space}
`

export const Button = styled.button``

export const Flex = styled(Box)`
    display: flex;
`

export const LinkButton = styled.button`
    color: #007fd0 !important;
    display: inline-block;
    background: none;
    border: none;

    &:hover {
        opacity: 1;
    }
    &:active,
    &:visited {
        color: initial;
    }
`
