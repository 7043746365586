import { useMutation, useQuery } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

export const createShipMethod = (variables) =>
    axiosClient.post('/ratesmgr/shipping_methods', {
        ratesmgr_carriers_shipping_method: variables,
    })

export const useCreateShipMethod = () => {
    const props = useMutation(createShipMethod)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        createShipMethod: props.mutate,
    }
}
