import React from 'react'
import { PackingMethodAction } from './types'
import cx from 'classnames'
import {
    ActionItem,
    StyledActionButton,
    StyledActionTitle,
    StyledActionDescription,
} from 'components/ui/ActionSelector/styles'

export const actionTitles: Record<PackingMethodAction, string> = {
    TOGETHER: 'Pack according to box rules',
    SEPARATE: 'Pack separately',
    MULTIPLE: 'Pack in multiple fixed boxes',
}

export const actionDescriptions: Record<PackingMethodAction, string> = {
    TOGETHER:
        'Allow SHQ to determine packing based on order info and available boxes',
    SEPARATE: "Have every item pack into it's own separate box",
    MULTIPLE: 'Split a single SKU into multiple set packages',
}

const iconMap: Record<PackingMethodAction, string> = {
    TOGETHER: 'fa-sort-size-down-alt',
    SEPARATE: 'fas fa-split',
    MULTIPLE: 'fas fa-boxes',
}

const OPTIONS: PackingMethodAction[] = ['TOGETHER', 'SEPARATE', 'MULTIPLE']

interface ActionSelectorProps {
    onChange: (action: PackingMethodAction) => void
    value: PackingMethodAction
}

/**
 * ActionSelector
 */
export const ActionSelector: React.FC<ActionSelectorProps> = ({
    onChange,
    value,
}) => {
    return (
        <>
            {OPTIONS.map((option) => (
                <ActionButton
                    key={option}
                    option={option}
                    onClick={onChange}
                    selected={value === option}
                />
            ))}
        </>
    )
}

const ActionButton: React.FC<{
    option: PackingMethodAction
    onClick: (action: PackingMethodAction) => void
    selected: boolean
}> = ({ option, onClick, selected }) => {
    const title = actionTitles[option]
    const description = actionDescriptions[option]

    return (
        <ActionItem selected={selected}>
            <StyledActionButton
                type="button"
                selected={selected}
                onClick={() => onClick(option)}
                data-testid={`action-${option}`}
            >
                <i
                    className={cx('fa', iconMap[option], { active: selected })}
                />
            </StyledActionButton>

            <div>
                <StyledActionTitle selected={selected} className="mb-1">
                    {title}
                </StyledActionTitle>

                <StyledActionDescription>{description}</StyledActionDescription>
            </div>
        </ActionItem>
    )
}
