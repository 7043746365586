import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { upgradePlanURL } from 'utils/urls'
import { Flex, Button } from 'styles'
import ComparePlanModal from 'components/dashboard/plans/ComparePlanModal'
import { AVAILABLE_PLATFORM_PLANS } from 'components/dashboard/features/useFeatures'
import { toTitleCase } from 'utils/string'

interface FeatureEnableButtonProps {
    upsellPlans: string[]
    onClick?: React.MouseEventHandler
}

export const UpsellButtonField: React.FC<FeatureEnableButtonProps> = ({
    upsellPlans,
    children,
}) => {
    const [upgradeModal, setUpgradeModal] = useState(false)

    const { plan, platform } = useSelector(({ app }: any) => app)

    const availablePlans = AVAILABLE_PLATFORM_PLANS[platform] || []

    const availableUpsellPlans = availablePlans
        .slice(availablePlans.indexOf(plan) + 1, availablePlans.length + 1)
        .filter((plan) => upsellPlans.includes(plan))

    const upgradeLimitMessage = `Available on ${availableUpsellPlans
        .map((plan) => toTitleCase(plan))
        .join(', ')} ${availableUpsellPlans.length > 1 ? 'plans' : 'plan'}`

    const upgradePlanName = availableUpsellPlans[0]

    const onUpgradePlan = (upgradePlanBilling) => {
        window.location.href = upgradePlanURL({
            planId: upgradePlanBilling?.id,
        })
    }

    if (!upgradePlanName) return null

    const openUpgradeModal = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setUpgradeModal(true)
    }

    return (
        <>
            <Flex justifyContent="space-between" alignItems="center">
                <p className="billing-card__title">{upgradeLimitMessage}</p>

                <Button
                    className="btn btn-orange btn-round"
                    data-testid="upgradePlanButton"
                    onClick={openUpgradeModal}
                >
                    See plans
                </Button>
            </Flex>

            {upgradeModal && (
                <ComparePlanModal
                    open={!!upgradePlanName}
                    onClose={() => setUpgradeModal(false)}
                    onUpgrade={onUpgradePlan}
                    currentPlan={plan}
                    upgradePlan={upgradePlanName}
                />
            )}
        </>
    )
}
