//
import React, { useState, useEffect, useRef, Suspense } from 'react'
import { FormSpy, useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/ui/Modal'
import useSubmitForm from 'hooks/useSubmitForm'
import { updateFedexEulaAcceptedDate } from 'reducers/modules/app'

const ReactPDF = import(/* webpackChunkName: "react-pdf" */ 'react-pdf')

const Document = React.lazy(() =>
    ReactPDF.then((module) => ({ default: module.Document }))
)

const Page = React.lazy(() =>
    ReactPDF.then((module) => ({ default: module.Page }))
)

/**
 * fieldDate Properties
 */

/**
 * fieldDate State
 */

/**
 */
export default function fieldFedexAcceptEula(props) {
    const formApi = useForm()
    const [eulaAcceptedDate, carrierType] = useSelector(
        ({
            app: {
                entity: { data: { carrier_type = '' } = {} } = {},
                user_settings: { fedex_eula_accepted_date } = {},
            },
        }) => [fedex_eula_accepted_date, carrier_type]
    )

    const [initialized, setInitialized] = useState(null)
    const [displayEula, setDisplayEula] = useState(false)
    const isFedexCarrier = carrierType.includes('fedex')

    const onChange = (values) => {
        if (!initialized || !isFedexCarrier) {
            return
        }

        const newCreateLabelValue = values?.values?.create_label
        if (['RATE_LABEL', 'LABEL'].includes(newCreateLabelValue)) {
            setDisplayEula(true)
        }
    }

    useEffect(() => {
        setInitialized(true)
    }, [])

    // don't trigger the modal when EULA was already accepted
    if (eulaAcceptedDate || !isFedexCarrier) {
        return null
    }

    return (
        <>
            <FormSpy onChange={onChange} />
            {/* $FlowFixMe */}
            {displayEula && (
                <EulaModal
                    onClose={() => {
                        setDisplayEula(false)
                    }}
                    onCancel={() => {
                        // revert back to RATE since user didn't accept the EULA
                        formApi.change('create_label', 'RATE')
                    }}
                />
            )}
        </>
    )
}

function EulaModal({ onClose, onCancel }) {
    const dispatch = useDispatch()
    const ref = useRef(null)
    const [canSubmit, setCanSubmit] = useState(false)
    const [numPages, setNumPages] = useState(0)
    const { submit, loading, error } = useSubmitForm(
        window.Routes.ratesmgr_user_settings_accept_fedex_eula_path()
    )

    const doAccept = async () => {
        const result = await submit()
        if (result) {
            dispatch(updateFedexEulaAcceptedDate())
            onClose()
        }
    }

    const doCancel = () => {
        onCancel()
        onClose()
    }

    const onScroll = (e) => {
        const {
            srcElement: {
                clientHeight = 0,
                scrollTop = 0,
                scrollHeight = 0,
            } = {},
        } = e

        const endY = scrollHeight - clientHeight - clientHeight / 3
        if (scrollTop > endY) {
            setCanSubmit(true)
        }
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
        // listen for scroll events, so we could enable Accept button
        if (ref?.current) {
            /* $FlowFixMe */
            ref?.current?.addEventListener('scroll', onScroll)
        }
    }

    // setup PDF.js worker
    useEffect(() => {
        ReactPDF.then((module) => {
            module.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${module.pdfjs.version}/pdf.worker.min.js`
        })

        return () => {
            if (ref?.current) {
                /* $FlowFixMe */
                ref?.current?.removeEventListener('scroll', onScroll)
            }
        }
    }, [])

    return (
        <Modal
            title="Fedex Web Services End User License Agreement"
            onClose={doCancel}
        >
            {error && <div>{error}</div>}
            <Suspense fallback="Loading...">
                <Document
                    inputRef={ref}
                    file="/pdf/wsla_corp.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                        />
                    ))}
                </Document>
            </Suspense>

            <div>
                <button
                    className="btn btn-primary"
                    onClick={(e) => {
                        e.preventDefault()
                        doAccept()
                        return false
                    }}
                    disabled={!canSubmit || loading}
                >
                    Accept
                </button>{' '}
                <button className="btn" onClick={doCancel} disabled={loading}>
                    Cancel
                </button>
            </div>
        </Modal>
    )
}
