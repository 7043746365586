import React from 'react'
import { ActionContainer } from 'components/ui/ActionSelector/styles'

import { ActionSelector } from './ActionSelector'
import { PackingMethodAction } from './types'

export const PackingRuleMethodField = ({ input, values }) => {
    const value = values[input.name]

    const handleChange = (actionType: PackingMethodAction) => {
        input.onChange(actionType)
    }

    return (
        <>
            <ActionContainer className="fitWidth">
                <ActionSelector value={value} onChange={handleChange} />
            </ActionContainer>
        </>
    )
}
