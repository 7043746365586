import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

const Input = styled.input`
    padding-right: 50px;
    width: 100%;
`

const Icon = styled.i`
    position: absolute;
    top: 6px;
    right: 9px;
    color: ${({ theme }) => theme.colors?.grey8};
`

const CleanIcon = styled.i`
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 31px;
    color: ${({ theme }) => theme.colors?.grey8};

    &:hover {
        color: ${({ theme }) => theme.colors?.greyc};
    }
`

const Container = styled.div`
    width: 100%;
    position: relative;
    max-width: 334px;
`

interface SearchInputProps {
    onChange: Function
    value?: string
    disabled?: boolean
}

const SearchInput = ({
    onChange,
    value: defautValue,
    disabled,
}: SearchInputProps) => {
    const [value, setValue] = useState(defautValue)

    useEffect(() => {
        if (value === defautValue) return
        const timeOutId = setTimeout(() => onChange(value), 600)
        return () => clearTimeout(timeOutId)
        // eslint-disable-next-line
    }, [value])

    useEffect(() => {
        setValue(defautValue)
        // eslint-disable-next-line
    }, [defautValue])

    const handleChange = (event) => {
        event.preventDefault()
        setValue(event.target.value)
    }

    const handleSubmit = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            onChange(value)
        }
    }

    const handleClean = (event) => {
        event.preventDefault()
        setValue('')
        onChange('')
    }

    return (
        <Container>
            <Input
                disabled={disabled}
                type="text"
                className="form-control input-sm"
                placeholder="Search"
                onChange={handleChange}
                onKeyDown={handleSubmit}
                value={value ? value : ''}
            />

            {!isEmpty(value) && (
                <CleanIcon
                    onClick={handleClean}
                    className="fas fa-times-circle"
                />
            )}

            <Icon className="far fa-search" />
        </Container>
    )
}

export default SearchInput
