import React, { useState } from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import NewFormStep from 'components/ui/form/ConditionRulesField/ConditionFlow/NewFormStep'
import { useCreateCustomerGroup } from 'hooks/useCustomerGroups'
import { useConditionsSources } from 'hooks/useConditionsSources'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import SelectForm from './SelectForm'

const NewForm = ({ toggleMode }: { toggleMode: Function }) => {
    const wizard = useWizardState({ context: ConditionFlowContext })
    const { createCustomerGroup, isLoading, errors } = useCreateCustomerGroup()
    const { customerGroups } = useConditionsSources()
    const { setErrors, setLoading } = useConditionRulesField()

    const handleSubmit = (values) => {
        setLoading(true)

        createCustomerGroup(values, {
            onSuccess: ({ data }: { data: any }) => {
                customerGroups.refetch()
                wizard.setContext((prev) => ({
                    ...prev,
                    customerGroupIds: [
                        ...(prev.customerGroupIds || []),
                        data.id,
                    ],
                }))
                toggleMode()
                setLoading(false)
            },
            onError: (errors: any) => {
                setErrors(errors)
                setLoading(false)
            },
        })
    }

    return (
        <NewFormStep
            conditionType="customerGroups"
            formConfig="new_customer_group_form"
            onBack={toggleMode}
            onSubmit={handleSubmit}
        />
    )
}

const SelectStep = () => {
    const [selectMode, setSelectMode] = useState(true)
    const toggleMode = () => setSelectMode((prev) => !prev)

    return selectMode ? (
        <SelectForm toggleMode={toggleMode} />
    ) : (
        <NewForm toggleMode={toggleMode} />
    )
}

export default SelectStep
