import CalendarContainer from './datetime/CalendarContainer'
import React, { Component } from 'react'

import assign from 'object-assign'
import PropTypes from 'prop-types'
import createClass from 'create-react-class'
import moment from 'moment'
import onClickOutside from 'react-onclickoutside'

var viewModes = Object.freeze({
    YEARS: 'years',
    MONTHS: 'months',
    DAYS: 'days',
    TIME: 'time',
})

const componentProps = {
    fromProps: [
        'value',
        'isValidDate',
        'renderDay',
        'renderMonth',
        'renderYear',
        'timeConstraints',
        'allowMultiple',
    ],
    fromState: ['viewDate', 'selectedDate', 'selectedDates', 'updateOn'],
    fromThis: [
        'setDate',
        'setTime',
        'showView',
        'addTime',
        'subtractTime',
        'updateSelectedDate',
        'localMoment',
        'handleClickOutside',
    ],
}

const allowedSetTime = ['hours', 'minutes', 'seconds', 'milliseconds']

var TYPES = PropTypes
class Datetime extends Component {
    constructor(props) {
        super(props)

        this.state = this.getInitialState()
    }

    getInitialState() {
        var state = Datetime.getStateFromProps(this.props)

        if (state.open === undefined) state.open = !this.props.input

        state.currentView = this.props.dateFormat
            ? this.props.viewMode || state.updateOn || viewModes.DAYS
            : viewModes.TIME

        if (this.props.allowMultiple && !state.selectedDates) {
            state.selectedDates = []
        }

        state.oldValue = this.props.value

        return state
    }

    static parseDate(date, formats, props) {
        var parsedDate

        if (date && typeof date === 'string')
            parsedDate = Datetime.localMoment(
                date.replace('  ', ' ').trim(),
                formats.datetime,
                props
            )
        else if (date) parsedDate = Datetime.localMoment(date)

        if (parsedDate && !parsedDate.isValid()) parsedDate = null

        return parsedDate
    }

    static getStateFromProps(props) {
        const { allowMultiple } = props
        let formats = Datetime.getFormats(props),
            date = props.value || props.defaultValue,
            selectedDate,
            viewDate,
            updateOn,
            inputValue,
            selectedDates = []

        if (allowMultiple) {
            const parts = date.split ? date.split(',') : date
            const result = []

            for (const part of parts) {
                const trimmed = part.trim()
                if (!trimmed.length) {
                    continue
                }
                selectedDate = Datetime.parseDate(trimmed, formats, props)

                viewDate = Datetime.parseDate(props.viewDate, formats, props)

                viewDate = selectedDate
                    ? selectedDate.clone().startOf('month')
                    : viewDate
                    ? viewDate.clone().startOf('month')
                    : this.localMoment(undefined, undefined, props).startOf(
                          'month'
                      )

                updateOn = this.getUpdateOn(formats)

                if (selectedDate) {
                    result.push(selectedDate.format(formats.datetime))
                    selectedDates.push(selectedDate)
                } else result.push(part || '')
            }

            inputValue = result.join(', ')
        } else {
            selectedDate = Datetime.parseDate(date, formats, props)

            viewDate = Datetime.parseDate(props.viewDate, formats, props)

            viewDate = selectedDate
                ? selectedDate.clone().startOf('month')
                : viewDate
                ? viewDate.clone().startOf('month')
                : Datetime.localMoment(undefined, undefined, props).startOf(
                      'month'
                  )

            updateOn = this.getUpdateOn(formats)

            if (selectedDate) inputValue = selectedDate.format(formats.datetime)
            else if (date.isValid && !date.isValid()) inputValue = ''
            else inputValue = date || ''
        }

        const { viewDate: currentViewDate } = this.state ? this.state : {}
        if (currentViewDate) {
            viewDate = currentViewDate
        } else if (!viewDate) {
            viewDate = moment()
        }

        return {
            updateOn,
            inputFormat: formats.datetime,
            viewDate,
            selectedDate,
            selectedDates,
            inputValue,
            open: props.open,
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newState = { ...state }

        if (props.value !== state.oldValue) {
            const { value } = props
            const localMoment = Datetime.localMoment(
                value,
                state.inputFormat,
                props
            )
            if (localMoment.isValid()) {
                newState.inputValue = value
                newState.selectedDate = localMoment
                newState.viewDate = localMoment.clone().startOf('month')
                newState.oldValue = props.value
            }
        }

        return newState
    }

    static getUpdateOn(formats) {
        if (formats.date.match(/[lLD]/)) {
            return viewModes.DAYS
        } else if (formats.date.indexOf('M') !== -1) {
            return viewModes.MONTHS
        } else if (formats.date.indexOf('Y') !== -1) {
            return viewModes.YEARS
        }

        return viewModes.DAYS
    }

    static getFormats(props) {
        var formats = {
                date: props.dateFormat || '',
                time: props.timeFormat || '',
            },
            locale = Datetime.localMoment(props.date, null, props).localeData()

        if (formats.date === true) {
            formats.date = locale.longDateFormat('L')
        } else if (Datetime.getUpdateOn(formats) !== viewModes.DAYS) {
            formats.time = ''
        }

        if (formats.time === true) {
            formats.time = locale.longDateFormat('LT')
        }

        formats.datetime =
            formats.date && formats.time
                ? formats.date + ' ' + formats.time
                : formats.date || formats.time

        return formats
    }

    onInputChange = (e) => {
        var value = e.target === null ? e : e.target.value,
            localMoment = Datetime.localMoment(
                value,
                this.state.inputFormat,
                this.props
            ),
            update = { inputValue: value }

        if (localMoment.isValid()) {
            update.selectedDate = localMoment
            update.viewDate = localMoment.clone().startOf('month')
        } else {
            update.selectedDate = null
        }

        return this.setState(update, function () {
            const result = this.props.onChange(
                localMoment.isValid() ? localMoment : this.state.inputValue
            )

            if (result && result.inputValue) {
                this.setState(result)
            }

            return result
        })
    }

    onInputKey = (e) => {
        if (e.which === 9 && this.props.closeOnTab) {
            this.closeCalendar()
        }
    }

    showView = (view) => {
        var me = this
        return function () {
            me.state.currentView !== view && me.props.onViewModeChange(view)
            me.setState({ currentView: view })
        }
    }

    setDate = (type) => {
        var me = this,
            nextViews = {
                month: viewModes.DAYS,
                year: viewModes.MONTHS,
            }
        return function (e) {
            me.setState({
                viewDate: me.state.viewDate
                    .clone()
                    [type](parseInt(e.target.getAttribute('data-value'), 10))
                    .startOf(type),
                currentView: nextViews[type],
            })
            me.props.onViewModeChange(nextViews[type])
        }
    }

    subtractTime = (amount, type, toSelected) => {
        var me = this
        return function () {
            me.props.onNavigateBack(amount, type)
            me.updateTime('subtract', amount, type, toSelected)
        }
    }

    addTime = (amount, type, toSelected) => {
        var me = this
        return function () {
            me.props.onNavigateForward(amount, type)
            me.updateTime('add', amount, type, toSelected)
        }
    }

    updateTime = (op, amount, type, toSelected) => {
        var update = {},
            date = toSelected ? 'selectedDate' : 'viewDate'

        update[date] = this.state[date].clone()[op](amount, type)

        this.setState(update)
    }

    setTime = (type, value) => {
        var index = allowedSetTime.indexOf(type) + 1,
            state = this.state,
            date = (state.selectedDate || state.viewDate).clone(),
            nextType

        // It is needed to set all the time properties
        // to not to reset the time
        date[type](value)
        for (; index < allowedSetTime.length; index++) {
            nextType = allowedSetTime[index]
            date[nextType](date[nextType]())
        }

        this.setState({
            selectedDate: date,
            inputValue: date.format(state.inputFormat),
        })

        this.props.onChange(date)
    }

    updateSelectedDate = (e, close) => {
        var target = e.currentTarget,
            modifier = 0,
            viewDate = this.state.viewDate,
            currentDate = this.state.selectedDate || viewDate,
            date

        if (typeof e === 'string') {
            const formats = Datetime.getFormats(this.props)
            date = Datetime.parseDate(e, formats, this.props)
        } else {
            if (target.className.indexOf('rdtDay') !== -1) {
                if (target.className.indexOf('rdtNew') !== -1) modifier = 1
                else if (target.className.indexOf('rdtOld') !== -1)
                    modifier = -1

                date = viewDate
                    .clone()
                    .month(viewDate.month() + modifier)
                    .date(parseInt(target.getAttribute('data-value'), 10))
            } else if (target.className.indexOf('rdtMonth') !== -1) {
                date = viewDate
                    .clone()
                    .month(parseInt(target.getAttribute('data-value'), 10))
                    .date(currentDate.date())
            } else if (target.className.indexOf('rdtYear') !== -1) {
                date = viewDate
                    .clone()
                    .month(currentDate.month())
                    .date(currentDate.date())
                    .year(parseInt(target.getAttribute('data-value'), 10))
            }
        }

        if (this.props.type === 'datetime') {
            date.hours(currentDate.hours())
                .minutes(currentDate.minutes())
                .seconds(currentDate.seconds())
                .milliseconds(currentDate.milliseconds())
        }

        if (this.props.type === 'date') {
            date.hours(0).minutes(0).seconds(0).milliseconds(0)
        }

        const { allowMultiple } = this.props

        if (!this.props.value) {
            const open = !(this.props.closeOnSelect && close)
            if (!open) {
                this.props.onBlur(date)
            }

            if (allowMultiple) {
                let selectedDates = [...this.state.selectedDates]

                if (!selectedDates.find((moment) => moment.isSame(date))) {
                    selectedDates.push(date)
                } else {
                    selectedDates = selectedDates.filter(
                        (moment) => !moment.isSame(date)
                    )
                }

                this.setState(
                    {
                        selectedDate: date,
                        selectedDates: selectedDates,
                        viewDate: date.clone().startOf('month'),
                        inputValue: selectedDates
                            .map((date) => date.format(this.state.inputFormat))
                            .join(', '),
                        open: open,
                    },
                    () => {
                        this.props.onChange(this.state.selectedDates)
                    }
                )

                return
            } else {
                this.setState({
                    selectedDate: date,
                    viewDate: date.clone().startOf('month'),
                    inputValue: date.format(this.state.inputFormat),
                    open: open,
                })
            }
        } else {
            if (this.props.closeOnSelect && close) {
                this.closeCalendar()
            }

            if (allowMultiple) {
                let selectedDates = [...this.state.selectedDates]

                if (!selectedDates.find((moment) => moment.isSame(date))) {
                    selectedDates.push(date)
                } else {
                    selectedDates = selectedDates.filter(
                        (moment) => !moment.isSame(date)
                    )
                }

                this.setState(
                    {
                        selectedDates: selectedDates,
                        inputValue: selectedDates
                            .map((date) => date.format(this.state.inputFormat))
                            .join(', '),
                    },
                    () => {
                        this.props.onChange(this.state.selectedDates)
                    }
                )

                return
            } else {
                this.setState({
                    selectedDate: date,
                    inputValue: date.format(this.state.inputFormat),
                })
            }
        }

        this.props.onChange(date)
    }

    openCalendar = (e) => {
        if (this.state.preventToggle) {
            return
        }

        if (!this.state.open) {
            this.setState({ open: true, preventToggle: true }, function () {
                this.props.onFocus(e)
            })
        } else {
            if (!this.state.preventToggle) {
                this.setState({ open: false, preventToggle: true })
            }
        }

        // when clicking input field onFocus is triggered first and right after it - onClick, hiding the calendar
        // so we need to wait for some time before allowing toggling behaviour
        setTimeout(() => {
            this.setState({
                preventToggle: false,
            })
        }, 200)
    }

    closeCalendar = () => {
        this.setState({ open: false }, function () {
            this.props.onBlur(this.state.selectedDate || this.state.inputValue)
        })
    }

    handleClickOutside = () => {
        if (
            this.props.input &&
            this.state.open &&
            this.props.open === undefined &&
            !this.props.disableCloseOnClickOutside
        ) {
            this.setState({ open: false }, function () {
                this.props.onBlur(
                    this.state.selectedDate || this.state.inputValue
                )
            })
        }
    }

    static localMoment(date, format, props) {
        var m = null

        if (props.utc) {
            m = moment.utc(date, format, props.strictParsing)
        } else if (props.displayTimeZone) {
            m = moment.tz(date, format, props.displayTimeZone)
        } else {
            m = moment(date, format, props.strictParsing)
        }

        if (props.locale) m.locale(props.locale)
        return m
    }

    static checkTZ(props) {
        var con = console

        if (props.displayTimeZone && !this.tzWarning && !moment.tz) {
            this.tzWarning = true
            con &&
                con.error(
                    'react-datetime: displayTimeZone prop with value "' +
                        props.displayTimeZone +
                        '" is used but moment.js timezone is not loaded.'
                )
        }
    }

    getComponentProps() {
        var me = this,
            formats = Datetime.getFormats(this.props),
            props = { dateFormat: formats.date, timeFormat: formats.time }

        componentProps.fromProps.forEach(function (name) {
            props[name] = me.props[name]
        })
        componentProps.fromState.forEach(function (name) {
            props[name] = me.state[name]
        })
        componentProps.fromThis.forEach(function (name) {
            props[name] = me[name]
        })

        return props
    }

    overrideEvent(handler, action) {
        if (!this.overridenEvents) {
            this.overridenEvents = {}
        }

        if (!this.overridenEvents[handler]) {
            var me = this
            this.overridenEvents[handler] = function (e) {
                var result
                if (me.props.inputProps && me.props.inputProps[handler]) {
                    result = me.props.inputProps[handler](e)
                }
                if (result !== false) {
                    action(e)
                }
            }
        }

        return this.overridenEvents[handler]
    }

    render() {
        // TODO: Make a function or clean up this code,
        // logic right now is really hard to follow
        var className =
                'rdt' +
                (this.props.className
                    ? Array.isArray(this.props.className)
                        ? ' ' + this.props.className.join(' ')
                        : ' ' + this.props.className
                    : ''),
            children = []

        if (this.props.input) {
            var finalInputProps = assign(
                {
                    type: 'text',
                    className: 'form-control',
                    value: this.state.inputValue,
                    id: 'field-' + this.props.name,
                },
                this.props.inputProps,
                {
                    onClick: this.overrideEvent('onClick', this.openCalendar),
                    onFocus: this.overrideEvent('onFocus', this.openCalendar),
                    onChange: this.overrideEvent(
                        'onChange',
                        this.onInputChange
                    ),
                    onKeyDown: this.overrideEvent('onKeyDown', this.onInputKey),
                }
            )

            if (this.props.renderInput) {
                children = [
                    React.createElement(
                        'div',
                        { key: 'i' },
                        this.props.renderInput(
                            finalInputProps,
                            this.openCalendar,
                            this.closeCalendar,
                            this.updateSelectedDate
                        )
                    ),
                ]
            } else {
                children = [
                    React.createElement(
                        'input',
                        assign({ key: 'i' }, finalInputProps)
                    ),
                ]
            }
        } else {
            className += ' rdtStatic'
        }

        if (
            this.props.open ||
            (this.props.open === undefined && this.state.open)
        )
            className += ' rdtOpen'

        return React.createElement(
            ClickableWrapper,
            { className: className, onClickOut: this.handleClickOutside },
            children.concat(
                React.createElement(
                    'div',
                    { key: 'dt', className: 'rdtPicker' },
                    React.createElement(CalendarContainer, {
                        view: this.state.currentView,
                        viewProps: this.getComponentProps(),
                    })
                )
            )
        )
    }
}

Datetime.displayName = 'DateTime'
Datetime.propTypes = {
    allowMultiple: TYPES.bool,
    onFocus: TYPES.func,
    onBlur: TYPES.func,
    onChange: TYPES.func,
    onViewModeChange: TYPES.func,
    onNavigateBack: TYPES.func,
    onNavigateForward: TYPES.func,
    locale: TYPES.string,
    utc: TYPES.bool,
    displayTimeZone: TYPES.string,
    input: TYPES.bool,
    inputProps: TYPES.object,
    timeConstraints: TYPES.object,
    viewMode: TYPES.oneOf([
        viewModes.YEARS,
        viewModes.MONTHS,
        viewModes.DAYS,
        viewModes.TIME,
    ]),
    isValidDate: TYPES.func,
    open: TYPES.bool,
    strictParsing: TYPES.bool,
    closeOnSelect: TYPES.bool,
    closeOnTab: TYPES.bool,
}

var ClickableWrapper = onClickOutside(
    createClass({
        render: function () {
            return React.createElement(
                'div',
                { className: this.props.className },
                this.props.children
            )
        },
        handleClickOutside: function (e) {
            this.props.onClickOut(e)
        },
    })
)

Datetime.defaultProps = {
    className: '',
    defaultValue: '',
    inputProps: {},
    input: true,
    onFocus: function () {},
    onBlur: function () {},
    onChange: function () {},
    onViewModeChange: function () {},
    onNavigateBack: function () {},
    onNavigateForward: function () {},
    timeFormat: true,
    timeConstraints: {},
    dateFormat: true,
    strictParsing: true,
    closeOnSelect: false,
    closeOnTab: true,
    utc: false,
}

// Make moment accessible through the Datetime class
Datetime.moment = moment

export default Datetime
