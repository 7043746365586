//

class EntityDataSource {
    constructor(props) {
        if (!props || !props.input) {
            console.error('EntityDataSource: invalid arguments')
        }

        this.endpoint = undefined

        const {
            form: { fields },
        } = props

        this.input = props.input

        let startId = -new Date().getTime()
        this.data = Array.from(props.input.value).map((item) => {
            startId--
            // SHQ18-2491
            // when duplicating an entity id is set to null
            // thus we need to reassign it to some negative number so we would now that is doesn't exists yet
            if (item.id === null) {
                item.id = startId
                item._created = true
            }
            return item
        })

        this.deleted = {}

        for (const item of this.data) {
            if (item._destroy) {
                this.deleted[item.id] = true
            }
        }

        this.defaultValues = fields.reduce((acc, field) => {
            if (field.default) {
                acc[field.name] = field.default
            }

            return acc
        }, {})
    }

    get isAjax() {
        return false
    }

    async list(args) {
        const emptyColumns = {}

        for (const row of this.data) {
            for (const [key, value] of Object.entries(row)) {
                if (!value || value === '*') {
                    if (emptyColumns[key] === undefined) {
                        emptyColumns[key] = true
                    }
                } else {
                    emptyColumns[key] = false
                }
            }
        }

        return {
            perPage: args.perPage,
            page: args.page,
            data: this.sort(
                this.data.filter((entry) => {
                    return !this.deleted[entry.id]
                }),
                args.sortColumnName,
                args.sortDirection
            ),
            emptyColumns,
        }
    }

    sort(data, sortColumnName, sortDirection) {
        if (sortColumnName) {
            /* $FlowFixMe */
            return data.sort((a, b) =>
                sortDirection === 'desc'
                    ? String(a[sortColumnName]).localeCompare(b[sortColumnName])
                    : String(b[sortColumnName]).localeCompare(a[sortColumnName])
            )
        }
        return data
    }

    async create() {
        // negative ids will be stripped from the form data
        const id = -new Date().getTime()
        return {
            id,
            ...this.defaultValues,
        }
    }

    async get(id) {
        return this.data.find((item) => item.id === id)
    }

    async delete(id) {
        this.deleted[id] = true
        this.updateInput()
    }

    async update(id, values) {
        if (id > 0 || values._created) {
            this.data = this.data.map((entry) => {
                if (entry.id === values.id) {
                    return {
                        ...entry,
                        ...replaceNulls(values),
                    }
                } else {
                    return entry
                }
            })
        } else {
            // new object
            this.data.push({
                id,
                _created: true,
                _overrideIdx: 1000 + Math.abs(id),
                ...replaceNulls(values),
            })
        }

        this.updateInput()
    }

    updateInput() {
        const data = this.data.map((entry) => {
            const result = {
                ...entry,
                id: entry.id > 0 ? entry.id : 0,
                _destroy: this.deleted[entry.id] ? true : false,
            }

            if (entry.id <= 0) {
                delete result.id
            }

            return result
        })

        this.input.onChange(data)
    }

    async hasAssociatedRecords(id) {
        return false
    }

    getPageInfo() {
        return {
            current: 0,
            perPage: 0,
            recordsCount: 0,
            totalRecords: 0,
            total: 0,
        }
    }
}

export default EntityDataSource

/**
 *
 * @param data
 */
function replaceNulls(data) {
    const result = {}
    for (const [k, v] of Object.entries(data)) {
        result[k] = v === null ? '' : v
    }
    return result
}
