import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { Form, FormRenderProps } from 'react-final-form'
import { SHIP_GROUP_RULES } from 'hooks/useShipGroups'
import { useConditionsSources } from 'hooks/useConditionsSources'
import EntitiesSelect from 'components/ui/form/EntitiesSelect'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import StepLayout from '../StepLayout'
import styled from 'styled-components'

const FooterButton = styled.button`
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border: none;
    display: block;
    margin: 0 auto 25px;
`

const SelectForm = ({
    form,
    toggleMode,
}: {
    form: FormRenderProps
    toggleMode: () => void
}) => {
    const { invalid, values } = form
    const { actionTitle } = useConditionRulesField()
    const wizard = useWizardState({ context: ConditionFlowContext })
    const {
        shipGroups: { shipGroups, isLoading },
    } = useConditionsSources()

    const preventShipGroupIds = wizard.context.preventShipGroupIds || []

    const shipGroupsOptions = shipGroups?.filter(
        (group) => !preventShipGroupIds.includes(group.id)
    )

    const handleContinue = () => {
        wizard.skipStep('shippingGroupPreventRule', {
            ...wizard.context,
            ...values,
        })
    }

    const handleNextStep = () => {
        wizard.submitStep({
            ...wizard.context,
            ...values,
        })
    }

    const handleToggleMode = () => {
        wizard.setContext((prev) => ({ ...prev, ...values }))
        toggleMode()
    }

    const Footer = () => (
        <FooterButton
            id="preventShippingGroupsLink"
            onClick={handleNextStep}
            className="primary-link-lg"
        >
            Select Shipping Groups to prevent this rule from applying
        </FooterButton>
    )

    return (
        <StepLayout
            conditionType="shippingGroups"
            disabled={invalid}
            onContinue={handleContinue}
            footer={Footer}
        >
            <>
                <h3 className="blue-title align-left">
                    {`${actionTitle} when...`}
                </h3>

                <div className="initial-align">
                    <EntitiesSelect
                        blank={false}
                        entities={SHIP_GROUP_RULES}
                        name="shipGroupRule"
                    />

                    <EntitiesSelect
                        multiSelect
                        searchable
                        entities={shipGroupsOptions}
                        disabled={isLoading}
                        name="shipGroupIds"
                        title="Select Shipping Groups"
                    />
                </div>

                <p onClick={handleToggleMode} className="mb-0 primary-link">
                    I want to create a new Shipping Group
                </p>
            </>
        </StepLayout>
    )
}

const SelectFormContainer = ({ toggleMode }: { toggleMode: () => void }) => {
    const conditionWizard = useWizardState({ context: ConditionFlowContext })

    return (
        <Form
            onSubmit={() => undefined}
            initialValues={{ shipGroupRule: 'all', ...conditionWizard.context }}
        >
            {(form) => <SelectForm form={form} toggleMode={toggleMode} />}
        </Form>
    )
}

export default SelectFormContainer
