import React from 'react'
import { NewTableRateProvider } from './Provider'
import WizardFlow from './WizardFlow'
import { useSelector } from 'react-redux'

const NewTableRateField = ({ stepForms, values, title, hint, fieldProps }) => {
    const { onClose, onCompleted, values: fieldValues } = fieldProps
    const { option_sources } = useSelector((state: any) => state.app.entity)

    return (
        <NewTableRateProvider
            onClose={onClose}
            onCompleted={onCompleted}
            carrier={values}
            field={{
                stepForms,
                hint,
                title,
                formSources: option_sources,
                values: fieldValues,
            }}
        >
            <WizardFlow />
        </NewTableRateProvider>
    )
}

export default NewTableRateField
