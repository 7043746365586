import React, { useContext, useState } from 'react'
import Modal from 'components/ui/Modal'
import NewFlow from './NewFlow'
import { NewQuickRuleProvider } from './Provider'
import { FormApi } from 'final-form'
import { RuleBuilderAction } from 'components/ui/form/RuleBuilderActionField/types'

interface Props {
    form: FormApi
    values: any
    actions: any
    conditions: any
    open: boolean
    onClose: React.MouseEventHandler
    onCompleted: () => void
    shippingMethodForm: unknown
    ruleAction?: RuleBuilderAction
}

export const NewQuickRuleModal: React.FC<Props> = ({
    form,
    values,
    actions,
    conditions,
    shippingMethodForm,
    open,
    onClose,
    onCompleted,
    ruleAction,
}) => {
    const initialStep = ruleAction
        ? ruleAction === 'HIDE_METHODS'
            ? 'shippingMethods'
            : 'actionForm'
        : null

    return (
        <>
            <NewQuickRuleProvider
                open={open}
                form={form}
                values={values}
                onClose={onClose}
                onCompleted={onCompleted}
                actions={actions}
                conditions={conditions}
                shippingMethodForm={shippingMethodForm}
                ruleAction={ruleAction}
            >
                {open && (
                    <Modal
                        id="conditionModal"
                        onClose={onClose}
                        className="modal-content add-carrier"
                        modalName="add-new-carrier"
                    >
                        <NewFlow initialStep={initialStep} />
                    </Modal>
                )}
            </NewQuickRuleProvider>
        </>
    )
}

export default NewQuickRuleModal
