import { useState } from 'react'
import { axiosClient } from 'net/ajax'

export default function useSubmitForm(url, method = 'post') {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    return {
        submit: async (data) => {
            setLoading(true)

            try {
                const result = await axiosClient[method](url, data)
                if (result && result.status >= 200 && result.status < 300) {
                    if (result.data && result.data.success === false) {
                        if (Array.isArray(result.data.errors)) {
                            setError(result.data.errors.join(', '))
                        } else {
                            setError(
                                Object.entries(result.data.errors)
                                    .map(
                                        ([key, value]) =>
                                            `${key}: ${String(value)}`
                                    )
                                    .join(', ')
                            )
                        }
                        setLoading(false)
                        return false
                    } else {
                        setError(null)
                        setLoading(false)
                        return result.data
                    }
                }

                setError('Error submitting the form.')
            } catch (e) {
                if (
                    e.response &&
                    e.response.data &&
                    (e.response.data.errors || e.response.data.error)
                ) {
                    if (e.response.data.error) {
                        setError(e.response.data.error)
                    } else {
                        setError(
                            Object.entries(e.response.data.errors)
                                .map(
                                    ([key, value]) => `${key}: ${String(value)}`
                                )
                                .join(', ')
                        )
                    }
                } else {
                    setError(e.message)
                }
            }

            setLoading(false)

            return false
        },
        error,
        loading,
    }
}
