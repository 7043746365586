import React from 'react'
import DropdownMenu from 'components/ui/DropdownMenu'
import DropdownItem from 'components/ui/DropdownItem'

import { useImportButton } from 'components/dashboard/import/useImportButton'
import { ImportProgressModal } from 'components/dashboard/import/ImportProgressModal'
import { ImportFormModal } from 'components/dashboard/import/ImportFormModal'

export const TableRateActions = ({ entity, onClose, emptyData }) => {
    const importEntityType = 'table_rates'

    const onRunImport = () => {}

    const {
        loading,
        toggleOpenedModal,
        modalOpened,
        handleCloseModal,
        handleImport,
        importError,
        importTask,
        refetch,
        isLoading,
        handleReportClose,
        handleRemoveTask,
        isFormModal,
        isReportModal,
        additionalProperties,
        setAdditionalProperties,
    } = useImportButton({
        entityType: importEntityType,
        onRun: onRunImport,
        onClose,
    })

    const handleImportAndReplace = () => {
        if (loading) return

        setAdditionalProperties({ append_rates: false, carrier_id: entity.id })
        toggleOpenedModal()
    }

    const handleImportAndAppend = () => {
        if (loading) return

        setAdditionalProperties({ append_rates: true, carrier_id: entity.id })
        toggleOpenedModal()
    }

    const handleExport = () => {
        window.open(`/ratesmgr/carriers/${entity.id}/export_table_rate_csv`)
    }

    return (
        <>
            <div className="flex">
                {emptyData ? (
                    <div
                        onClick={handleImportAndAppend}
                        className="btn btn-o-o btn-round transparent left"
                    >
                        <i className="far fa-file-import mr-2" />
                        Import CSV
                    </div>
                ) : (
                    <DropdownMenu
                        buttonClassName="btn btn-sm btn-basic white"
                        label={
                            <>
                                <i key="up" className="fa fa-upload mr-2" />
                                Import CSV
                            </>
                        }
                    >
                        <DropdownItem
                            disabled={loading}
                            title={[
                                <i key="up" className="fa fa-upload mr-2" />,
                                'Import and Replace ',
                            ]}
                            onSelect={handleImportAndReplace}
                        />

                        <DropdownItem
                            disabled={loading}
                            title={[
                                <i key="up" className="fa fa-upload mr-2" />,
                                'Import and Append ',
                            ]}
                            onSelect={handleImportAndAppend}
                        />
                    </DropdownMenu>
                )}

                {!emptyData && (
                    <button
                        type="button"
                        className="btn btn-sm btn-basic white right"
                        onClick={handleExport}
                    >
                        <i key="dl" className="fa fa-download mr-2" />
                        Export CSV
                    </button>
                )}
            </div>

            {isFormModal && (
                <ImportFormModal
                    title={`Import and ${
                        additionalProperties.append_rates ? 'Append' : 'Replace'
                    } Table Rates`}
                    open={modalOpened}
                    onClose={handleCloseModal}
                    onSubmit={handleImport}
                    error={importError}
                    entityType={importEntityType}
                />
            )}

            {isReportModal && (
                <ImportProgressModal
                    importTask={importTask}
                    refetch={refetch}
                    isLoading={isLoading}
                    open={modalOpened}
                    onClose={handleReportClose}
                    onBack={handleRemoveTask}
                />
            )}
        </>
    )
}
