import React from 'react'
import MenuButton, { MenuLabel, MenuDivider } from 'components/ui/MenuButton'

interface Column {
    property?: string
    header: { label: any }
}

interface ColumnsViewSettingsProps {
    columns: Array<Column>
    viewSettings: any
    onChange: Function
    skipColumns: Array<string>
}

export const ColumnsViewSettingsMenu = ({
    columns,
    viewSettings,
    onChange,
    skipColumns,
}: ColumnsViewSettingsProps) => {
    const { mode } = viewSettings

    const viewColumns = columns.filter(
        (column: Column) =>
            !['id'].includes(column.property) &&
            !skipColumns.includes(column.property)
    )

    const toggleColumn = (column) => {
        onChange(column.property, !viewSettings[column.property])
    }

    return (
        <MenuButton className="mr-2">
            <MenuLabel>
                <input
                    checked={mode === 'allColumns'}
                    onChange={() => onChange('mode', 'allColumns')}
                    type="radio"
                    name="optradio"
                />
                Show all columns
            </MenuLabel>

            <MenuLabel>
                <input
                    checked={mode === 'selectedColumns'}
                    onChange={() => onChange('mode', 'selectedColumns')}
                    type="radio"
                    name="optradio"
                />
                Show selected columns
            </MenuLabel>

            {mode === 'selectedColumns' && (
                <>
                    <MenuDivider />

                    {viewColumns.map((column) => (
                        <MenuLabel key={`column-${column.property}`}>
                            <input
                                onChange={() => toggleColumn(column)}
                                checked={Boolean(viewSettings[column.property])}
                                type="checkbox"
                                name={column.property}
                            />
                            {column.header.label}
                        </MenuLabel>
                    ))}
                </>
            )}
        </MenuButton>
    )
}
