import React, { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormGroup } from 'components/ui/form/renderers'
import Modal from 'components/ui/Modal'
import useCopy from 'hooks/useCopy'
import { useCreateToken, useDeleteToken, useGetToken } from 'hooks/integrations'
import { Flex } from 'styles'
import { FieldRenderProps } from 'react-final-form'

interface Props extends FieldRenderProps<string> {
    title?: string
    revokeDescription: ReactNode
    integrationType: string
    hint?: string
    fullWidth?: boolean
    className?: string
    compact?: boolean
    oneLine?: boolean
}

const FieldApiToken: React.FC<Props> = ({
    input,
    meta,
    title,
    revokeDescription,
    integrationType,
    hint,
    fullWidth,
    className,
    compact,
    oneLine,
}) => {
    const params = useParams()
    const { token } = useGetToken({
        websiteId: params.id,
        integrationType,
    })
    const { create, isFetchingToken } = useCreateToken()
    const { copy, copied, setValueToCopy } = useCopy(token)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const { deleteToken, isDeletingToken } = useDeleteToken()

    useEffect(() => {
        if (token) {
            setValueToCopy(token)
        }
    }, [token])

    const showModal = () => setIsModalVisible(true)
    const hideModal = () => setIsModalVisible(false)

    const handleRevokeClick = () => {
        deleteToken(
            { websiteId: params.id, token },
            {
                onSuccess: () => {
                    hideModal()
                },
            }
        )
    }

    return (
        <>
            {isModalVisible && (
                <Modal
                    modalName="revoke-token"
                    captureNotifications={false}
                    onClose={hideModal}
                    className="modal-content install-app"
                >
                    <div>
                        <h2 style={{ marginBottom: 24 }}>
                            Before Revoking this Access Token
                        </h2>
                        <div className="warn-msg">
                            <i
                                style={{ fontSize: 24, marginBottom: 10 }}
                                className="fa fa-exclamation-triangle ico-caution"
                            />
                            {revokeDescription}
                        </div>
                        <button
                            className="btn btn-primary"
                            onClick={handleRevokeClick}
                            type="button"
                            disabled={isDeletingToken}
                        >
                            Revoke
                        </button>
                    </div>
                </Modal>
            )}
            {token && (
                <FormGroup
                    meta={meta}
                    name={input.name}
                    label={title}
                    hint={hint}
                    compact={compact}
                    fullWidth={fullWidth}
                    oneLine={oneLine}
                    className={className}
                >
                    <div className="form-token">
                        <input
                            className="form-token--input"
                            readOnly={true}
                            value={token}
                        />
                        <Flex>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={copy}
                                style={{ marginRight: '6px' }}
                            >
                                Copy{' '}
                                <i
                                    className={
                                        copied ? 'far fa-check' : 'far fa-paste'
                                    }
                                />
                            </button>
                            <button
                                className="btn"
                                onClick={showModal}
                                type="button"
                            >
                                <i
                                    className="far fa-trash-alt"
                                    role="presentation"
                                    title="Revoke"
                                />
                            </button>
                        </Flex>
                    </div>
                </FormGroup>
            )}
            {!token && (
                <button
                    className="btn btn-primary"
                    type="button"
                    disabled={isFetchingToken}
                    onClick={() =>
                        create({
                            websiteId: params.id,
                            integrationType,
                        })
                    }
                    style={{ marginTop: '10px' }}
                >
                    Generate New Access Token
                </button>
            )}
        </>
    )
}

export const FieldApiTokenShippingInsights: React.FC<Props> = (props) => (
    <FieldApiToken
        {...props}
        integrationType="shipping_insights"
        revokeDescription={
            <p>
                Revoking this access token will stop Shipping Insights from
                displaying order details. This action cannot be undone, but you
                can generate a new token.
            </p>
        }
    />
)

export const FieldApiTokenShipStation: React.FC<Props> = (props) => (
    <FieldApiToken
        {...props}
        integrationType="shipstation"
        revokeDescription={
            <p>
                Revoking this access token will stop shipping details in
                ShipperHQ from being shared with Shipstation. This action cannot
                be undone, but you can generate a new token.
            </p>
        }
    />
)

export const FieldApiTokenLabel: React.FC<Props> = (props) => (
    <FieldApiToken
        {...props}
        integrationType="label"
        revokeDescription={
            <p>
                Revoking this access token will prevent you from displaying
                existing and creating new labels. This action cannot be undone,
                but you can generate a new token.
            </p>
        }
    />
)

export const FieldApiTokenEC: React.FC<Props> = (props) => (
    <FieldApiToken
        {...props}
        integrationType="ec"
        revokeDescription={
            <p>
                Revoking this access token will prevent you from displaying
                rates in checkout. This action cannot be undone, but you can
                generate a new token and update integration code to use it.
            </p>
        }
    />
)
