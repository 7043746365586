import React from 'react'
import { Field } from 'react-final-form'
import isEmpty from 'lodash/isEmpty'
import { fieldInput } from 'components/ui/form/renderers'

export const validation = (value) => {
    const formattedValue = value?.trim()

    if (isEmpty(formattedValue)) {
        return 'Required'
    }

    return undefined
}

const CouponInput = ({ name, ...props }: { name: string }) => {
    return (
        <Field
            title="Coupon Code Contains"
            infoLink="https://docs.shipperhq.com/how-to-apply-a-shipping-rule-using-a-coupon-code-on-bigcommerce"
            name={name}
            allowBlank={false}
            fullWidth
            required
            validate={validation}
            render={fieldInput}
            hint="Enter the phrase(s) contained in your coupon code as it exists in your Ecommerce platform — commonly a prefix/suffix."
            {...props}
        />
    )
}

export default CouponInput
