import React from 'react'
import NoteMessage from 'components/ui/NoteMessage'
import { useSelector } from 'react-redux'
import { isPlatform } from 'utils/platform'
import styled from 'styled-components'

const TaxMessage = styled.div`
    padding: 15px;
    border-top: 1px solid #e9eff4;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

interface BigcommerceTaxMessageProps {
    className?: string
}

const BigcommerceTaxMessage: React.FC<BigcommerceTaxMessageProps> = ({
    className,
}) => {
    const { platform } = useSelector(({ app = {} }: { app: any }) => app)

    if (!isPlatform(platform, ['bigcommerce'])) return null

    return (
        <TaxMessage>
            <NoteMessage className={className}>
                Check your BigCommerce account configuration to see if your prices
                are{' '}
                <a target="_blank" href="https://support.bigcommerce.com/s/article/Tax-Overview?language=en_US">
                    tax inclusive or exclusive
                </a>
                . BigCommerce only passes the price assigned to the product, so we
                are unable to process tax separately, which may affect how shipping
                rules are applied.
            </NoteMessage>
        </TaxMessage>
    )
}

export default BigcommerceTaxMessage
