import { useMutation, useQuery } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

const getZones = () => {
    return axiosClient
        .get('/ratesmgr/criteria/zones')
        .then(({ data }) => data)
}

export const useGetZones = () => {
    const { data: zones = [], ...rest } = useQuery(['zones'], getZones)

    return { zones, ...rest }
}

export const createZone = (variables) =>
    axiosClient.post('/ratesmgr/criteria/zones', {
        render_entity: true,
        ratesmgr_criteria_zone: variables,
    })

export const useCreateZone = () => {
    const props = useMutation(createZone)

    return {
        isSuccess: props.isSuccess,
        isLoading: props.isLoading,
        errors: props.error,
        isError: props.isError,
        createZone: props.mutate,
    }
}
