import React from 'react'
import { Field } from 'react-final-form'
import {
    fieldSelect,
    required,
    prependEmptyOption,
    fieldAssociation,
} from 'components/ui/form/renderers'

const EntitiesSelect = ({
    entities,
    blank = true,
    hidden = false,
    ...props
}) => {
    if (hidden) return <></>

    const entitiesOptions = entities.map((entity) => ({
        value: entity.value || entity.id,
        title: entity.title || entity.name,
    }))

    return (
        <Field
            allowBlank={blank}
            fullWidth
            required
            validate={required}
            render={props.multiSelect ? fieldAssociation : fieldSelect}
            options={
                blank ? prependEmptyOption(entitiesOptions) : entitiesOptions
            }
            {...props}
        />
    )
}

export default EntitiesSelect
