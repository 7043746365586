//
import React, { Component } from 'react'

export default class Tab extends Component {
    static defaultProps = {
        disabled: false,
    }

    render() {
        return this.props.children || null
    }
}
