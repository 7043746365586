import {useForm, useFormState} from 'react-final-form'
import { useEffect } from 'react'

export function useInitialActionValue() {
    const form = useForm()
    const values = useFormState()

    // populate initial value for _action_value
    useEffect(() => {
        const { values } = form.getState()
        const { base_price, base_percentage } = values

        if (parseFloat(base_price) && parseFloat(base_percentage)) {
            form.change('_action_value', 'FLAT_RATE_AND_PERCENTAGE')
        } else if (parseFloat(base_percentage)) {
            form.change('_action_value', 'PERCENTAGE')
        } else {
            form.change('_action_value', 'FLAT_RATE')
        }
    }, [])

    return form
}
