import { useEffect } from 'react'

export const useClickOutsideEffect = (ref: any, callback?: Function) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (ref?.current && !ref?.current.contains(event.target)) {
                callback && callback()
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, callback])
}
