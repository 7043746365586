import { useMutation } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

export const createTableRate = (variables) =>
    axiosClient.post('/ratesmgr/table_rates', {
        ratesmgr_table_rate: variables,
    })

export const updateTableRate = ({ id, ...variables }) =>
    axiosClient.put(`/ratesmgr/table_rates/${id}`, {
        ratesmgr_table_rate: variables,
    })

export const useCreateTableRate = () => {
    const props = useMutation(createTableRate)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        createTableRate: props.mutate,
    }
}

export const useSaveTableRate = ({ id }) => {
    const props = useMutation(id ? updateTableRate : createTableRate)

    const error: any = props.error || {}

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: error?.errors || error,
        isError: props['isError'],
        saveTableRate: props.mutate,
    }
}
