import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import classnames from 'classnames'
import { useClickOutsideEffect } from 'hooks/useEventEffects'

const Container = styled.div`
    position: relative;
    outline: none;
`

const Button = styled.button`
    outline: none !important;
`

const Menu = styled.div`
    position: absolute;
    top: calc(100% + 5px);
    transform: translateX(-50%) scale(0);
    transform-origin: center;
    color: #fff;
    width: 200px;
    left: 50%;
    padding: 15px 0;
    text-align: left;
    font-weight: 300;
    user-select: none;
    z-index: 10;
    background-color: white;
    border: 1px solid #dddddd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 12px;

    &.open {
        opacity: 1;
        visibility: visible;
        transform: translateX(-50%) scale(1);
        opacity: 1;
        user-select: auto;
    }
`

export const MenuLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    color: #384451;
    font-weight: 400;
    font-size: 12px;

    input {
        margin: 0;
        margin-right: 8px;
    }
`

export const MenuDivider = styled.label`
    width: 100%;
    background: #dddddd;
    height: 1px;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
`

interface MenuButtonProps {
    className?: string
}

const MenuButton: React.FC<MenuButtonProps> = ({ children, className }) => {
    const [open, setOpen] = useState(false)
    const containerRef = useRef(null)

    const toggleMenu = (event) => {
        event.preventDefault()
        setOpen((prev) => !prev)
    }

    useClickOutsideEffect(containerRef, () => setOpen(false))

    return (
        <Container ref={containerRef} className={className}>
            <Button
                className="btn btn-sm btn-basic white"
                onClick={toggleMenu}
                type="button"
            >
                <i className="far fa-table mr-5" />
                Customize Table View
                <i className="far fa-angle-down ml-5" />
            </Button>
            <Menu className={classnames({ open: open })}>{children}</Menu>
        </Container>
    )
}

export default MenuButton
