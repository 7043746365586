import { delegate } from 'utils/delegator'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'

const APPLIES_TO_LABELS = {
    ENTIRE_CART: 'Entire Cart',
    CART: 'Each Shipment in the Cart',
    LOCATION: 'Each Origin in the Cart',
    SHIPPING_GROUP: 'Each Shipping Group in the Cart',
    ITEM: 'Each Item within a Shipping Group',
    BOX: 'Each Box per Each Shipping Group',
}

export class QuickRule {
    origin: Record<string, unknown>
    currencyCode: string
    name: string
    shipping_methods: Record<string, string>[]
    carriers_methods_codes: Record<string, string>[]
    apply_price_to: string
    base_percentage: string | null
    base_price: string | null

    constructor(origin: Record<string, unknown>, currencyCode: string = 'USD') {
        this.origin = origin
        this.currencyCode = currencyCode

        delegate(this as Record<string, unknown>, origin)
    }

    get title() {
        return this.name
    }

    get isPercentage() {
        return ![0, '0.00', '0.0', null].includes(this.base_percentage)
    }

    get isFlatPrice() {
        return ![0, '0.00', '0.0', null].includes(this.base_price)
    }

    get withoutCarrierShippingMethods() {
        return (
            isEmpty(this.shipping_methods) &&
            isEmpty(this.carriers_methods_codes)
        )
    }

    get carrierMethodsGroups() {
        return groupBy(this.carriers_methods_codes, 'carrier_name')
    }

    get shippingMethodsGroups() {
        return groupBy(this.shipping_methods, 'carrier_name')
    }

    get appliesToLabel() {
        return APPLIES_TO_LABELS[this.apply_price_to]
    }

    get typeLabel() {
        let typeLabel = []
        if (this.isFlatPrice) typeLabel = [...typeLabel, 'Flat Rate']
        if (this.isPercentage) typeLabel = [...typeLabel, 'Percentage']

        if (isEmpty(typeLabel)) return

        return typeLabel.join(' and ')
    }

    get basePrice() {
        if (!this.isFlatPrice) return

        return parseFloat(this.base_price).toLocaleString('en-US', {
            style: 'currency',
            currency: this.currencyCode,
        })
    }

    get basePercentage() {
        if (!this.isPercentage) return

        return this.base_percentage + '%'
    }

    get typeValueLabel() {
        let typeValue = []
        if (this.basePrice) typeValue = [...typeValue, this.basePrice]
        if (this.basePercentage) typeValue = [...typeValue, this.basePercentage]

        if (isEmpty(typeValue)) return

        return typeValue.join(' & ')
    }
}
