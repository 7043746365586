import { useMutation, useQuery } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

const getShipFilters = () => {
    return axiosClient
        .get(`/ratesmgr/criteria/ship_filters`)
        .then(({ data }) => data)
}

export const useGetShipFilters = () => {
    const { data: shipFilters = [], ...rest } = useQuery(['ship_filters'], () =>
        getShipFilters()
    )

    const findShipFilter = (filterId) =>
        shipFilters.find((filter) => parseInt(filter.id) === parseInt(filterId))

    return {
        shipFilters,
        findShipFilter,
        ...rest,
    }
}

export const APPLIES_TO_OPTIONS = {
    CART: 'Whole Cart',
    SHIPPING_GROUP: 'Everything in Shipping Group',
    LOCATION: 'Everything at Origin',
    ITEM: 'Each Item within Shipping Group',
}

export const CONDITION_TYPES_TITLES = {
    qty: 'Quantity',
    price: 'Price',
    weight: 'Weight',
    volume: 'Volume',
}

const buildFilterName = ({
    appliesTo,
    valueFrom,
    valueTo,
    type,
}: {
    type: string
    appliesTo: string
    valueFrom: number | string
    valueTo: number | string
}) => {
    let name = `${CONDITION_TYPES_TITLES[type]}`

    if (valueFrom && !valueTo) {
        name = `${name} ≥ ${valueFrom}`
    }

    if (!valueFrom && valueTo) {
        name = `${name} ≤ ${valueTo}`
    }

    if (valueFrom && valueTo) {
        name = `${name} = ${valueFrom} to ${valueTo}`
    }

    name = `${name} for ${APPLIES_TO_OPTIONS[appliesTo]} `

    return name
}

export const prepareShipFilterParams = (type, values) => {
    const variables = { ...values }

    const suffix = type === 'price' ? '_max' : ''

    const valueFrom = variables[`${type}_from${suffix}`]
    const valueTo = variables[`${type}_to${suffix}`]

    variables[`filter_range_${type}`] = 'range'

    if (!variables.name) {
        variables.name = buildFilterName({
            type,
            appliesTo: variables.applies_to,
            valueFrom,
            valueTo,
        })
    }

    if (!variables.description) variables.description = variables.name

    return variables
}

export const createShipFilter = ({ check_duplicates, ...variables }) =>
    axiosClient.post('/ratesmgr/criteria/ship_filters', {
        render_entity: true,
        check_duplicates,
        ratesmgr_criteria_ship_filter: { ...variables },
    })

export const useCreateShipFilter = () => {
    const props = useMutation(createShipFilter)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        createShipFilter: props.mutate,
    }
}
