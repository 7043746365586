import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { Form, FormRenderProps } from 'react-final-form'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import StepLayout from '../StepLayout'

import CouponInput from './CouponInput'

const FillStep = ({ form }: { form: FormRenderProps }) => {
    const { invalid, values } = form
    const { actionTitle } = useConditionRulesField()
    const wizard = useWizardState({ context: ConditionFlowContext })

    const handleContinue = () => {
        wizard.submitStep({ ...wizard.context, ...values })
    }

    return (
        <StepLayout
            conditionType="couponCode"
            disabled={invalid}
            onContinue={handleContinue}
        >
            <>
                <h3 className="blue-title align-left">
                    {`${actionTitle} when...`}
                </h3>

                <div className="initial-align">
                    <CouponInput name="couponCode" />
                </div>
            </>
        </StepLayout>
    )
}

const FillStepContainer = () => {
    const conditionWizard = useWizardState({ context: ConditionFlowContext })

    return (
        <Form onSubmit={() => {}} initialValues={conditionWizard.context}>
            {(form) => <FillStep form={form} />}
        </Form>
    )
}

export default FillStepContainer
