import React from 'react'
import {
    useWizardState,
    WizardStateProvider,
} from 'components/dashboard/wizard/WizardStateContext'

import Filter from './Filter'
import ShippingZone from './ShippingZone'
import ShippingGroup from './ShippingGroup'
import CustomerGroup from './CustomerGroup'
import CouponCode from './CouponCode'

export const ConditionFlowContext = React.createContext(null)

const STEPS_COMPONENTS = {
    priceSelect: Filter.PriceSelectStep,
    weightSelect: Filter.WeightSelectStep,
    volumeSelect: Filter.VolumeSelectStep,
    qtySelect: Filter.QtySelectStep,
    shippingZoneSelect: ShippingZone.SelectStep,
    shippingGroupSelect: ShippingGroup.SelectStep,
    shippingGroupPreventRule: ShippingGroup.PreventRuleStep,
    customerGroupSelect: CustomerGroup.SelectStep,
    couponCodeFill: CouponCode.FillStep,
}

const STEPS = {
    price: ['priceSelect'],
    weight: ['weightSelect'],
    qty: ['qtySelect'],
    shippingZones: ['shippingZoneSelect'],
    shippingGroups: ['shippingGroupSelect', 'shippingGroupPreventRule'],
    customerGroups: ['customerGroupSelect'],
    couponCode: ['couponCodeFill'],
    volume: ['volumeSelect'],
}

const SelectConditionComponent = () => {
    const conditionWizard = useWizardState({ context: ConditionFlowContext })
    const currentStep = conditionWizard.currentStep

    const CurrentStepComponent = STEPS_COMPONENTS[currentStep]

    return CurrentStepComponent ? <CurrentStepComponent /> : <div></div>
}

const SelectConditionContainer = ({
    selectedConditions = [],
    onCompleted,
    context = {},
}) => {
    const conditionSteps = selectedConditions.reduce(
        (result, condition) => [...result, ...(STEPS[condition] || [])],
        []
    )

    return (
        <WizardStateProvider
            onCompleted={onCompleted}
            context={ConditionFlowContext}
            defaultContext={context}
            steps={conditionSteps}
        >
            <SelectConditionComponent />
        </WizardStateProvider>
    )
}

export default SelectConditionContainer
