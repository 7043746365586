import React, { useState } from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import { useConditionsSources } from 'hooks/useConditionsSources'
import NewFormStep from 'components/ui/form/ConditionRulesField/ConditionFlow/NewFormStep'
import { useCreateShipGroup } from 'hooks/useShipGroups'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'

import SelectForm from './SelectForm'

const NewForm = ({ toggleMode }: { toggleMode: Function }) => {
    const wizard = useWizardState({ context: ConditionFlowContext })
    const { createShipGroup } = useCreateShipGroup()
    const { shipGroups } = useConditionsSources()
    const { setErrors, setLoading } = useConditionRulesField()

    const handleSubmit = (values) => {
        setLoading(true)

        createShipGroup(values, {
            onSuccess: ({ data }: { data: any }) => {
                shipGroups.refetch()
                wizard.setContext((prev) => ({
                    ...prev,
                    shipGroupIds: [...(prev.shipGroupIds || []), data.id],
                }))
                toggleMode()
                setLoading(false)
            },
            onError: (errors: any) => {
                setErrors(errors)
                setLoading(false)
            },
        })
    }

    return (
        <NewFormStep
            conditionType="shippingGroups"
            formConfig="new_shipping_group_form"
            onBack={toggleMode}
            onSubmit={handleSubmit}
        />
    )
}

const SelectStep = () => {
    const [selectMode, setSelectMode] = useState(true)
    const toggleMode = () => setSelectMode((prev) => !prev)

    return selectMode ? (
        <SelectForm toggleMode={toggleMode} />
    ) : (
        <NewForm toggleMode={toggleMode} />
    )
}

export default SelectStep
