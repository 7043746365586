import React, { useState } from 'react'
import { fieldAssociation as FieldComponent } from 'components/ui/form/renderers'
import RadioInput, { RADIO_OFFSET } from 'components/ui/RadioInput'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

const ALL_OPTION = 'allShippingBoxes'
const SPECIFIC_OPTION = 'specificShippingBoxes'

export const fieldShippingBoxesSelection = (props) => {
    const [boxesMode, setBoxesMode] = useState(
        isEmpty(props.input.value) ? ALL_OPTION : SPECIFIC_OPTION
    )

    const disabled = props.disabled || boxesMode === ALL_OPTION

    const handleChange = (mode) => (_event) => {
        setBoxesMode(mode)
        if (mode === ALL_OPTION) props.input.onChange([])
    }

    return (
        <div className="ent-cont-layer-section">
            <div className="ent-cont-sub-section">
                <div className="ent-cont-subhead">
                    <h3>Shipping Boxes</h3>
                </div>

                <div className="mt-4">
                    <div className="form-row">
                        <div className="form-col-2">
                            <RadioInput
                                labelFor={ALL_OPTION}
                                title={<strong>Use a Custom Box</strong>}
                                checked={boxesMode === ALL_OPTION}
                                onChange={handleChange(ALL_OPTION)}
                                hint={
                                    <>
                                        <span>
                                            Use the item dimensions set above or
                                            the product dimensions set
                                            <br /> within your ecommerce
                                            platform to define a custom box,
                                            called{' '}
                                            <a
                                                target="_blank"
                                                href="https://docs.shipperhq.com/how-pack-according-to-box-rules-works/"
                                            >
                                                SHQ Custom
                                            </a>
                                            .
                                        </span>
                                    </>
                                }
                            />

                            <RadioInput
                                labelFor={SPECIFIC_OPTION}
                                title={<strong>Use Available Boxes</strong>}
                                checked={boxesMode === SPECIFIC_OPTION}
                                onChange={handleChange(SPECIFIC_OPTION)}
                                hint={
                                    <>
                                        <span>
                                            Set your preferred boxes below.
                                            <br />
                                            <strong>Note:</strong> If the boxes
                                            added here don't fit, the rule will
                                            not apply
                                            <br />
                                            and ShipperHQ will use boxes set to
                                            “pack all products” or custom boxes.
                                        </span>
                                    </>
                                }
                            />

                            <div
                                style={{
                                    marginLeft: RADIO_OFFSET,
                                    marginTop: -8,
                                }}
                            >
                                <FieldComponent
                                    {...props}
                                    className={cx({
                                        'disabled-opacity': disabled,
                                    })}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
