import React, { Component, Fragment, useState, useRef } from 'react'
import * as Table from 'reactabular-table'
import provider from 'containers/Grid/provider'
import Modal from 'components/ui/Modal'
import SearchInput from 'components/ui/SearchInput'
import DynamicForm from 'components/ui/form/DynamicForm'
import { Form } from 'react-final-form'
import Paginator from './components/Paginator'
import Stats from 'containers/Grid/components/Stats'
import RecordLimitSelector from 'containers/Grid/components/RecordLimitSelector'
import debounce from 'awesome-debounce-promise'
import { TableRateActions } from './components/TableRateActions'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import checkFieldRequirements from 'components/ui/form/fieldRequirements'
import { toggleFullScreenMode } from 'reducers/modules/dashboard'
import { reloadAppData } from 'reducers/modules/app'
import { ColumnsViewSettingsMenu } from './ColumnsViewSettingsMenu'

import prepareDataToDisplay from 'components/ui/form/prepareData'
import GridContext from './GridContext'
import { Link } from 'react-router-dom'
import { showMessage } from 'reducers/modules/app'
import HeaderHint from 'containers/Grid/components/HeaderHint'
import logger from 'utils/logger'
import classnames from 'classnames'
import { getValidatorByType } from 'components/ui/form/validators'
import {
    ActionListPopover,
    ActionItemPopover,
} from './components/ActionListPopover'

const PERSIST_COLUMNS = ['shipping_method', 'shipping_price']

const ExtraActions = {
    TableRateActions,
}

const actionSuccessMessages = (type) => ({
    create: `New ${type} has been created successfully`,
    clone: `${type} has cloned successfully`,
    update: `${type} has been updated successfully`,
})

const ACTION_MESSAGES = {
    ajax_table_rates: actionSuccessMessages('Shipping Rate'),
    ajax_shipping_methods: actionSuccessMessages('Shipping Method'),
    rows: actionSuccessMessages('Row'),
}

export class Grid extends Component {
    performSearchDebounced

    constructor(props) {
        super(props)

        if (props.actionsClass) {
            const ActionClass = ExtraActions[props.actionsClass]
            if (!ActionClass) {
                console.error(
                    'class for actions not found:',
                    props.actionsClass
                )
            }
        }

        const tableColumns = this.convertColumns()

        const viewSettings = tableColumns.reduce(
            (result, column) => ({ ...result, [column.property]: true }),
            { mode: 'allColumns' }
        )

        this.state = {
            dataSource: provider(props.dataSource, props),
            pageNumber: 1,
            perPage: 25,
            page: null,
            tableColumns: this.convertColumns(),
            viewSettings: viewSettings,
            modalVisible: false,
            columnSearches: {},
            searchActive: false,
        }

        const { validateAssociations } = props
        if (validateAssociations) {
            /* $FlowFixMe */
            this.state.validateAssociations = provider(
                validateAssociations.dataSource,
                props
            )
        }

        this.performSearchDebounced = debounce(this.performSearch, 500)
    }

    async componentDidMount() {
        await this.refreshData()
    }

    refreshData = async () => {
        const {
            dataSource,
            pageNumber,
            perPage,
            sortColumnName,
            sortDirection,
            columnSearches,
            search,
        } = this.state

        const page = await dataSource.list({
            page: pageNumber,
            perPage,
            sortColumnName,
            sortDirection,
            columnSearches,
            search,
        })

        this.setState({
            page: preparePageData(page),
            tableColumns: this.convertColumns(page),
            emptyData: page.recordsTotal === 0,
        })
    }

    setViewSettings = (property, value) => {
        this.setState((state) => ({
            ...state,
            viewSettings: { ...state.viewSettings, [property]: value },
        }))
    }

    changePage = (pageNumber) => {
        this.setState(
            {
                pageNumber,
            },
            this.refreshData
        )
    }

    changeLimit = (perPage) => {
        this.setState(
            {
                perPage,
            },
            this.refreshData
        )
    }

    get isFullScreenMode() {
        return !this.props.sidebarVisible && !this.props.helpDocVisible
    }

    toggleFullScreenMode = (event) => {
        event.preventDefault()

        this.props.toggleFullScreenMode()
    }

    renderTopActions() {
        const { title, hint, values, actionsClass } = this.props
        const { search, searchActive } = this.state
        const ActionClass = ExtraActions[actionsClass]

        return (
            <Fragment>
                <div className="mb-1">
                    {title && (
                        <div className="flex space-between mb-2">
                            <div className="flex">
                                <h3 className="mr-3 mb-0 grid-title">
                                    {title}
                                </h3>
                            </div>
                        </div>
                    )}

                    {hint && (
                        <div className="ent-cont-hint">
                            <p dangerouslySetInnerHTML={{ __html: hint }} />
                        </div>
                    )}
                </div>

                <div className="pagi-tp">
                    <div className="grid-actions">
                        <div className="flex fullWidth mr-4">
                            <div className="flex-start mr-4">
                                <button
                                    className="btn btn-sm btn-basic white mr-1"
                                    onClick={this.toggleFullScreenMode}
                                    type="button"
                                >
                                    <i
                                        className={`mr-1 fa ${
                                            this.isFullScreenMode
                                                ? 'fa-compress'
                                                : 'fa-expand'
                                        }`}
                                    />

                                    {this.isFullScreenMode
                                        ? 'Collapse View'
                                        : 'Full Screen View'}
                                </button>
                            </div>

                            <SearchInput
                                disabled={searchActive}
                                value={search}
                                onChange={this.valueSearch}
                            />
                        </div>

                        <div className="flex-end">
                            <ColumnsViewSettingsMenu
                                columns={this.state.tableColumns}
                                viewSettings={this.state.viewSettings}
                                onChange={this.setViewSettings}
                                skipColumns={PERSIST_COLUMNS}
                            />

                            <GridContext.Provider
                                value={{ reloadGrid: this.refreshData }}
                            >
                                {ActionClass && (
                                    <ActionClass
                                        emptyData={this.state.emptyData}
                                        entity={values}
                                        onClose={this.refreshDataAndSources}
                                    />
                                )}
                            </GridContext.Provider>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

    renderEmptyField() {
        const {
            emptyTitle,
            emptyMessage,
            gridAddButton,
            emptyImportCsv,
            emptyHint,
            actionsClass,
            values,
        } = this.props
        const ActionClass = ExtraActions[actionsClass]

        return (
            <>
                {emptyHint && (
                    <div className="ent-cont-hint">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: emptyHint,
                            }}
                        />
                    </div>
                )}

                <div className="table-empty-message">
                    {emptyTitle && <p class="title">{emptyTitle}</p>}
                    {emptyMessage && <p class="message">{emptyMessage}</p>}

                    <div className="flex-center">
                        {ActionClass && emptyImportCsv && (
                            <div className="flex-center">
                                <ActionClass
                                    entity={values}
                                    emptyData={this.state.emptyData}
                                    onClose={this.refreshDataAndSources}
                                />

                                <span className="ml-2 mr-2">or</span>
                            </div>
                        )}

                        <button
                            onClick={this.create}
                            class="btn btn-primary-o btn-round"
                            type="button"
                        >
                            {gridAddButton || 'Add Record'}
                        </button>
                    </div>
                </div>
            </>
        )
    }

    renderTable() {
        const { emptyMessage, hint2, paginate } = this.props
        let {
            page,
            modalVisible,
            dataSource,
            searchActive,
            search,
            tableColumns,
            viewSettings,
        } = this.state

        if (!page) {
            page = {
                page: 1,
                offset: 0,
                data: [],
                loading: true,
            }
        }

        const isTableEmpty =
            page.data.length === 0 &&
            !searchActive &&
            !page.loading &&
            isEmpty(search)

        const highlightedRowId = this.state.highlightedRowId

        const rows = page.data.map((row) => ({
            ...row,
            highlighted:
                highlightedRowId &&
                (row.DT_RowData?.id === highlightedRowId ||
                    row?.id === highlightedRowId),
        }))

        const columns =
            viewSettings.mode === 'allColumns'
                ? tableColumns
                : tableColumns.filter(
                      (column) =>
                          PERSIST_COLUMNS.includes(column.property) ||
                          viewSettings[column.property]
                  )

        return (
            <>
                {isTableEmpty ? (
                    this.renderEmptyField()
                ) : (
                    <>
                        {this.renderTopActions()}

                        <div className="table-responsive">
                            <Table.Provider
                                className="table table-striped table-hover table-bordered dataTable"
                                columns={columns}
                            >
                                <Table.Header />

                                <Table.Body
                                    key={page.data.length}
                                    rows={rows}
                                    rowKey="id"
                                    onRow={(row) => {
                                        return {
                                            'data-row-id':
                                                row.DT_RowData?.id || row.id,
                                            className: classnames({
                                                'highlight-row':
                                                    row.highlighted,
                                            }),
                                        }
                                    }}
                                />
                            </Table.Provider>
                        </div>

                        {page.data.length === 0 && !isEmpty(search) ? (
                            <div className="table-empty-message">
                                No matching records were found
                            </div>
                        ) : null}
                        <div className="pagi-bt mt-1">
                            <div className="table-bottom-actions">
                                <button
                                    className="btn new-button-section mb-2"
                                    onClick={this.create}
                                >
                                    Add New Row +
                                </button>

                                {paginate && (
                                    <div className="dataTables_paginate paging_simple_numbers pagination-section">
                                        <div className="pagination-left">
                                            <Stats
                                                dataSource={
                                                    this.state.dataSource
                                                }
                                            />
                                        </div>

                                        <div className="pagination-middle">
                                            <Paginator
                                                onNavigate={this.changePage}
                                                dataSource={
                                                    this.state.dataSource
                                                }
                                            />
                                        </div>

                                        <div className="pagination-right">
                                            <RecordLimitSelector
                                                value={this.state.perPage}
                                                onChange={this.changeLimit}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {hint2 && (
                            <div
                                className="ent-cont-hint-right"
                                dangerouslySetInnerHTML={{
                                    __html: hint2,
                                }}
                            />
                        )}
                    </>
                )}

                {modalVisible && this.renderModal()}
            </>
        )
    }

    render() {
        return this.renderTable()
    }

    preprocessData(data) {
        const selectedEntity = prepareDataToDisplay(
            this.props.form,
            data,
            this.props.optionSources,
            false
        )

        return selectedEntity
    }

    create = async (e) => {
        e && e.preventDefault()

        const data = await this.state.dataSource.create()
        const selectedEntity = this.preprocessData(data)
        this.setState({
            modalVisible: true,
            selectedEntity,
        })
    }

    edit = async (e, id) => {
        e && e.preventDefault()

        const data = await this.state.dataSource.get(id)
        const selectedEntity = this.preprocessData(data)

        this.setState({
            modalVisible: true,
            selectedEntity,
        })
    }

    hideModal = (e) => {
        e && e.preventDefault()

        this.setState({
            modalFullScreenVisible: false,
            modalVisible: false,
            cloneAction: false,
        })
    }

    duplicate = async (e, id, { onSuccess }) => {
        e && e.preventDefault()

        if (this.state.validateAssociations) {
            const has_records =
                await this.state.validateAssociations.hasAssociatedRecords(id)

            if (has_records) {
                this.props.showMessage({
                    type: 'error',
                    text: `Can't duplicate this record, because it has associated rates`,
                })
                return
            }
        }

        const data = await this.state.dataSource.get(id)

        if (!data?.id) {
            this.props.showMessage({
                type: 'error',
                text: `Can't duplicate this record, because it can't be find`,
            })

            return
        }

        const selectedEntity = this.preprocessData(data)

        this.setState({
            modalVisible: true,
            cloneAction: true,
            selectedEntity: { ...selectedEntity, id: null },
        })
    }

    delete = async (e, id) => {
        e && e.preventDefault()

        if (this.state.validateAssociations) {
            const has_records =
                await this.state.validateAssociations.hasAssociatedRecords(id)

            if (has_records) {
                this.props.showMessage({
                    type: 'error',
                    text: `Can't delete this record, because it has associated rates`,
                })
                return
            }
        }

        await this.state.dataSource.delete(id)

        const input = this.props.input

        await this.refreshData()

        if (
            this.state.page.data.length === 0 &&
            !this.state.emptyData &&
            this.state.pageNumber > 1
        ) {
            this.changePage(this.state.pageNumber - 1)
        }

        if (this.state.dataSource.isAjax) {
            input.onChange(input.value.filter((entity) => entity.id !== id))
        }

        if (this.props.form.reload_app_data) {
            this.props.reloadAppData(false, true)
        }
    }

    highlightRow = (id) => {
        this.setState({ highlightedRowId: id })

        setTimeout(() => {
            this.setState({ highlightedRowId: null })
        }, 5000)
    }

    renderCellActions = (value) => {
        return (
            <ItemMoreMenu
                id={value}
                onEdit={this.edit}
                onCopy={
                    this.state.dataSource.isDuplicatable ? this.duplicate : null
                }
                onDelete={this.delete}
            />
        )
    }

    onClickCell = (event) => {
        const rowId = event.target.closest('tr').dataset.rowId

        this.edit(event, parseInt(rowId))
    }

    doSort = (e, options) => {
        let { sortColumn, sortDirection } = this.state
        const column = options.columnIndex

        e.preventDefault()

        if (sortColumn === undefined || sortColumn !== column) {
            sortColumn = column
            sortDirection = 'asc'
        } else {
            sortColumn = column
            sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'
        }

        this.setState(
            {
                sortColumn,
                sortColumnName: options.property,
                sortDirection,
            },
            this.refreshData
        )
    }

    sortableHeader = (columnName, options) => {
        const { sortColumn, sortDirection } = this.state

        let icon = null
        if (sortColumn === options.columnIndex) {
            if (sortDirection === 'asc') {
                icon = <i className="fas fa-sort-amount-down" />
            } else {
                icon = <i className="fas fa-sort-amount-up" />
            }
        } else {
            icon = <i className="fas fa-sort" />
        }

        return (
            <Fragment>
                <a href="#" onClick={(e) => this.doSort(e, options)}>
                    {columnName} {icon}
                </a>
            </Fragment>
        )
    }

    commonHeader(columnName) {
        return columnName
    }

    performSearch = async () => {
        await this.refreshData()
    }

    refreshDataAndSources = async () => {
        await this.refreshData()
        this.props.reloadAppData(false, true)
    }

    columnSearch(e, options) {
        const value = e.currentTarget.value

        this.setState((state) => {
            const columnSearches = {
                ...state.columnSearches,
                [options.columnIndex]: value,
            }

            let searchActive = false
            for (const sValue of Object.values(columnSearches)) {
                if (sValue && sValue.length) {
                    searchActive = true
                    break
                }
            }

            return {
                columnSearches,
                searchActive,
            }
        }, this.performSearchDebounced)
    }

    valueSearch = (value) => {
        if (this.state.searchActive) return

        this.setState({ search: value, searchActive: true }, () => {
            this.refreshData().finally(() =>
                this.setState({ searchActive: false })
            )
        })
    }

    searchableHeader = (columnName, options) => {
        const { columnSearches } = this.state
        const value = columnSearches[options.columnIndex]
        return (
            <Fragment>
                {columnName}
                <input
                    type="text"
                    className="form-control input-sm"
                    placeholder="Search"
                    onChange={(e) => this.columnSearch(e, options)}
                    value={value ? value : ''}
                />
            </Fragment>
        )
    }

    headerWithTheHint = (hint) => {
        return (header) => (
            <>
                <HeaderHint hint={hint}>{header}</HeaderHint>
            </>
        )
    }

    filterColumns(page, skipColumns = []) {
        const { columns, viewOptions, values } = this.props

        if (page?.emptyColumns) {
            /* $FlowFixMe */
            for (const [name, isEmpty] of Object.entries(page.emptyColumns)) {
                if (isEmpty) {
                    skipColumns.push(name)
                }
            }
        }

        return columns.filter((column) => {
            if (skipColumns.includes(column.name)) {
                return false
            }

            if (Array.isArray(column.require)) {
                return checkFieldRequirements(
                    column.require,
                    viewOptions,
                    values
                )
            }
            return true
        })
    }

    convertColumns(page) {
        const data = page?.data || []
        const columns = Object.keys(data[0] || [])
        let columnsWithoutData = columns.filter(
            (column) => !PERSIST_COLUMNS[column]
        )

        data.forEach((row) => {
            columnsWithoutData.forEach((property) => {
                const value = row[property]

                if (value !== '' && value !== '*' && value !== null) {
                    columnsWithoutData = columnsWithoutData.filter(
                        (column) => column !== property
                    )
                }
            })
        })

        return [
            ...this.filterColumns(page, columnsWithoutData).map((column) => {
                const props = {}

                if (column.sortable) {
                    props.className = 'sort'
                }

                return {
                    property: column.name,
                    header: {
                        label: column.title,
                        formatters: [
                            column.sortable
                                ? this.sortableHeader
                                : this.commonHeader,
                            column.searchable
                                ? this.searchableHeader
                                : this.commonHeader,
                            column.hint
                                ? this.headerWithTheHint(column.hint)
                                : this.commonHeader,
                        ],
                        props,
                    },
                    cell: {
                        transforms: [() => ({ onClick: this.onClickCell })],
                    },
                }
            }),
            {
                property: 'id',
                header: {
                    label: <i className="far fa-edit label-icon" />,
                },
                cell: {
                    formatters: [this.renderCellActions],
                },
                props: {
                    className: 'dataActions sticky-right',
                },
            },
        ]
    }

    get actionMessage() {
        return ACTION_MESSAGES[this.props.dataSource] || ACTION_MESSAGES.rows
    }

    onAddedRow = (id, messageAction) => {
        const pageInfo = this.state.dataSource.getPageInfo()

        this.setState({ pageNumber: pageInfo.total }, async () => {
            await this.refreshData()
            this.props.showMessage({ text: this.actionMessage[messageAction] })
        })

        this.highlightRow(id)
        this.hideModal()
    }

    onSavedRow = async (id, messageAction) => {
        await this.refreshData()

        this.props.showMessage({
            text: this.actionMessage[messageAction],
        })

        this.highlightRow(id)
        this.hideModal()
    }

    saveEntity = async (values) => {
        this.setState({ errors: {} })

        const isUpdateAction = values.id && values.id > 0
        let response

        try {
            response = await this.state.dataSource.update(values.id, values)
        } catch (e) {
            this.setState({ errors: e.response?.data || {} })

            logger.error('Error saving the entity: %s', e.toString())

            this.props.showMessage({
                type: 'error',
                text: `Error occurred while saving this entity: ${e.toString()}`,
            })

            return
        }

        if (!response) {
            await this.refreshData()
            this.highlightRow(values.id)
            this.hideModal()

            return
        }

        if (isUpdateAction) {
            this.onSavedRow(response.data.id, 'update')
        } else {
            this.onAddedRow(response.data.id, 'create')
        }

        if (this.props.form.reload_app_data) {
            this.props.reloadAppData(false, true)
        }

        if (this.state.dataSource.isAjax) {
            const input = this.props.input
            const newValue = input.value.map((entity) =>
                response.data.id === entity.id
                    ? { ...entity, ...response.data }
                    : entity
            )

            input.onChange(newValue)
        }
    }

    getValidatorByType = (type, values) => {
        switch (type) {
            case 'unique_method_code': {
                const { selectedEntity } = this.state

                // MNB-639
                // The method code can only match if
                // 1. the previous matching method code is being deleted
                // 2. it is the same method being edited
                return (value, values) =>
                    values.carriers_shipping_methods_attributes.some(
                        (attribute) =>
                            attribute.method_code === value &&
                            !attribute._destroy &&
                            (attribute?._overrideIdx
                                ? attribute?._overrideIdx !==
                                  selectedEntity?._overrideIdx
                                : true) &&
                            attribute?.id !== selectedEntity?.id
                    )
                        ? 'Method Code must be unique'
                        : undefined
            }
            default:
                return getValidatorByType(type)
        }
    }

    renderModal() {
        const { selectedEntity, errors } = this.state
        const { values } = this.props

        const handleClose = async () => {
            await this.refreshData()
            this.hideModal()
        }

        const handleCompleted = ({ entityId, update }) => {
            if (update) {
                this.onSavedRow(entityId, 'update')
            } else {
                this.onAddedRow(
                    entityId,
                    this.state.cloneAction ? 'clone' : 'create'
                )
            }
        }

        return (
            <Modal
                title={this.props.form.title}
                onClose={handleClose}
                captureNotifications={false}
            >
                <Form onSubmit={this.saveEntity} initialValues={selectedEntity}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="grid-form">
                                <DynamicForm
                                    name="gridForm"
                                    tab={this.props.form}
                                    oneLine={true}
                                    values={values}
                                    errors={errors}
                                    getValidatorByType={this.getValidatorByType}
                                    fieldProps={{
                                        values: selectedEntity,
                                        onClose: handleClose,
                                        onCompleted: handleCompleted,
                                    }}
                                />
                            </div>

                            {!this.props.form.custom_actions && (
                                <div className="actions">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={handleSubmit}
                                    >
                                        {selectedEntity &&
                                        (selectedEntity.id > 0 ||
                                            selectedEntity._created)
                                            ? 'Update'
                                            : this.props.form.title_button
                                            ? this.props.form.title_button
                                            : selectedEntity?.num_boxes
                                            ? 'Add Box'
                                            : 'Add Rate'}
                                    </button>
                                    <button
                                        type="button"
                                        className="sub-action"
                                        onClick={this.hideModal}
                                    >
                                        Cancel
                                    </button>{' '}
                                </div>
                            )}
                        </form>
                    )}
                </Form>
            </Modal>
        )
    }
}

export default connect(
    ({
        dashboard: { sidebarVisible, helpDocVisible },
        app: {
            user_settings,
            platform,
            enabled_features,
            option_sources,
            entity: { option_sources: entityOptionSources } = {},
        },
    }) => ({
        sidebarVisible,
        helpDocVisible,
        viewOptions: {
            userSettings: user_settings,
            platform,
            enabledFeatures: enabled_features,
        },
        optionSources: { ...option_sources, ...entityOptionSources },
    }),
    { showMessage, toggleFullScreenMode, reloadAppData }
)(Grid)

function preparePageData(page) {
    return {
        ...page,
        data: page.data.map((row) => processRow(row)),
    }
}

/**
 * Convert boolean values into strings (just for displaying)
 * @param row
 * @returns {{}}
 */
function processRow(row) {
    return Object.entries(row).reduce((res, [key, value]) => {
        if (typeof value === typeof true) {
            res[key] = value ? 'true' : 'false'
        } else {
            res[key] = value
        }
        return res
    }, {})
}

function ActionButton({
    onClick,
    actionType,
    component: Component,
    children,
    ...props
}) {
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = async (event) => {
        if (isLoading) return

        setIsLoading(true)
        try {
            await onClick(event)
        } catch (error) {
            logger.error(`error ${actionType} table rate`, error)
        }
    }

    return (
        <Component
            className={classnames({ disabled: isLoading })}
            onClick={handleClick}
            {...props}
        >
            {children}
        </Component>
    )
}

function ItemMoreMenu({ id, onEdit, onCopy, onDelete }) {
    const containerRef = useRef()
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = () => setIsOpen((prev) => !prev)

    const MenuComponent = ({ innerRef }) => (
        <button
            ref={innerRef}
            onClick={toggleOpen}
            data-testid={`menuActions-${id}`}
            type="button"
        >
            <i className="fas fa-ellipsis-v" />
        </button>
    )

    return (
        <span ref={containerRef} className="dataActions-container">
            <ActionListPopover
                containerRef={containerRef}
                onClose={toggleOpen}
                open={isOpen}
                menuComponent={MenuComponent}
            >
                <ActionItemPopover onClick={(e) => onEdit(e, id)}>
                    <i className="fas fa-pencil mr-1" /> Edit
                </ActionItemPopover>

                {onCopy && (
                    <ActionItemPopover
                        onClick={(e) =>
                            onCopy(e, id, { onSuccess: toggleOpen })
                        }
                    >
                        <i className="fas fa-copy mr-1" /> Duplicate
                    </ActionItemPopover>
                )}

                <ActionButton
                    data-testid={`deleteAction-${id}`}
                    type="delete"
                    component={ActionItemPopover}
                    onClick={(e) => onDelete(e, id)}
                >
                    <i className="fa failure fa-trash-alt mr-1" /> Delete
                </ActionButton>
            </ActionListPopover>
        </span>
    )
}
