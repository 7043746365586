//
import * as React from 'react'
import { useContext } from 'react'
import DynamicFormContext from 'components/ui/form/DynamicFormContext'
import useTooltip from 'hooks/useTooltip'

/**
 * HeaderHint Properties
 */

export default function HeaderHint(props) {
    const { children, hint } = props
    const formContext = useContext(DynamicFormContext)
    const hintValue = formContext.conditionalValue(hint)
    const tooltip = useTooltip()

    return (
        <>
            {children}
            <a className="tip" ref={tooltip.ref} data-tip={hintValue}>
                <i className="fas fa-info-circle" {...tooltip.props} />
            </a>
        </>
    )
}
