import React from 'react'
import { ConditionRulesProvider } from 'components/ui/form/ConditionRulesField/Provider'
import { ConditionsSourcesProvider } from 'hooks/useConditionsSources'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import StackFlow from 'components/ui/form/ConditionRulesField/StackFlow'
import { applyAddCondition } from 'components/ui/form/ConditionRulesField'
import { useShippingRuleFormOptions } from 'components/ui/form/RuleBuilderActionField'
import {
    useCreateQuickRule,
    prepareQuickRuleVariables,
} from 'hooks/useQuickRules'
import { useDynamicFormOptions } from 'hooks/selectors'
import { NewRuleFlowContext } from './index'
import { useNewQuickRule } from '../Provider'

const ConditionFlow: React.FC = () => {
    const { createQuickRule } = useCreateQuickRule()
    const { form, values, conditions, onClose } = useNewQuickRule()
    const { optionSources } = useShippingRuleFormOptions()
    const wizard = useWizardState({ context: NewRuleFlowContext })

    const handleComplete = (submitValues, { onSuccess, onError }) => {
        const { conditionField = null } = submitValues

        const formValues = conditionField
            ? applyAddCondition({ form, values, conditionField })
            : form.getState().values

        const quickRuleVariables = prepareQuickRuleVariables(formValues)

        createQuickRule(quickRuleVariables, {
            onSuccess: ({ data }: { data: any }) => {
                wizard.submitStep({ ...wizard.context, quickRule: data })
                onSuccess(formValues)
            },
            onError: (errors) => {
                onError(errors)
            },
        })
    }

    return (
        <ConditionsSourcesProvider>
            <ConditionRulesProvider
                multipleConditions
                onBack={wizard.prevStep}
                onClose={onClose}
                onCompleted={handleComplete}
                onSkip={handleComplete}
                values={values}
                stepForms={conditions}
                formSources={optionSources}
            >
                <StackFlow />
            </ConditionRulesProvider>
        </ConditionsSourcesProvider>
    )
}

export default ConditionFlow
