import styled from 'styled-components'

export const ActionItem = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    color: ${({ selected, theme }) =>
        selected ? theme.colors.dkblue1 : theme.colors.greybluelt4};
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    margin-right: 15px;
    margin-bottom: 20px;
    min-width: 120px;
    width: calc(20% - 16px);

    &:last-child {
        margin-right: 0px;
    }
`

export const ActionContainer = styled.div`
    display: flex;
    column-gap: 5px;
    width: 100%;
    flex-wrap: wrap;

    &.fitWidth {
        justify-content: space-between;

        ${ActionItem} {
            width: auto;
            flex: 1;
        }
    }
`

export const StyledActionButton = styled.button`
    width: 100%;
    height: 90px;
    background: ${({ selected, theme }) =>
        selected ? theme.colors.dkblue1 : theme.colors.greyfa};
    border: 1px solid
        ${({ selected, theme }) =>
            selected ? 'rgba(0, 0, 0, 0.1)' : theme.colors.greyd};
    box-sizing: border-box;
    border-radius: 6px;
    --color_fill: #000;

    i.fa {
        font-size: 32px;
        color: #bbbbbb;

        &.active {
            color: white;
        }
    }
`

export const RuleActionForm = styled.div`
    padding: 29px 16px 29px 16px;
    background: #fafafa;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 3px;
`

export const StyledActionTitle = styled.h3`
    font-weight: ${({ selected }) => (selected ? 700 : 400)};
    font-size: 13px;
    margin-top: 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.dkblue1};
`

export const StyledActionDescription = styled.p`
    font-weight: 400;
    font-size: 11px;
    margin: 0;
    text-align: center;
    color: ${({ theme }) => theme.colors.greybluedk1};
`
