import React from 'react'
import {
    WizardStateProvider,
    useWizardState,
} from 'components/dashboard/wizard/WizardStateContext'
import { useNewTableRateField } from 'components/ui/form/NewTableRateField/Provider'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

export const NewTableRateFlowContext = React.createContext(null)

import ShippingMethodStep from './ShippingMethodStep'
import TableRateStep from './TableRateStep'

export const STEPS_COMPONENTS = {
    shippingMethod: ShippingMethodStep,
    tableRate: TableRateStep,
}

const WizardFlow = () => {
    const wizard = useWizardState({ context: NewTableRateFlowContext })

    const CurrentStepComponent = STEPS_COMPONENTS[wizard.currentStep]

    return CurrentStepComponent ? <CurrentStepComponent /> : <div />
}

const WizardFlowContainer = () => {
    const { onCompleted } = useNewTableRateField()
    const { option_sources } = useSelector((state: any) => state.app.entity)

    let steps = []

    if (isEmpty(option_sources.shipping_methods)) {
        steps = [...steps, 'shippingMethod']
    }

    steps = [...steps, 'tableRate']

    return (
        <WizardStateProvider
            onCompleted={onCompleted}
            context={NewTableRateFlowContext}
            steps={steps}
        >
            <WizardFlow />
        </WizardStateProvider>
    )
}

export default WizardFlowContainer
