import React, { useEffect } from 'react'
import {
    WizardStateProvider,
    useWizardState,
} from 'components/dashboard/wizard/WizardStateContext'
import { useNewQuickRule } from '../Provider'

import ActionSelectorStep from './ActionSelectorStep'
import ConditionFlow from './ConditionFlow'
import ActionFormStep from './ActionFormStep'
import ShippingMethodsStep from './ShippingMethodsStep'
import SuccessStep from './SuccessStep'

export const NewRuleFlowContext = React.createContext(null)

export const STEPS_COMPONENTS = {
    selectAction: ActionSelectorStep,
    actionForm: ActionFormStep,
    shippingMethods: ShippingMethodsStep,
    conditionFlow: ConditionFlow,
    successStep: SuccessStep,
}

type STEP =
    | 'selectAction'
    | 'actionForm'
    | 'shippingMethods'
    | 'conditionFlow'
    | 'successStep'

const INITIAL_STEPS = [
    'selectAction',
    'actionForm',
    'shippingMethods',
    'conditionFlow',
    'successStep',
]

const NewRuleComponent = () => {
    const wizard = useWizardState({ context: NewRuleFlowContext })

    const CurrentStepComponent = STEPS_COMPONENTS[wizard.currentStep]

    return CurrentStepComponent ? <CurrentStepComponent /> : <div />
}

const NewRuleFlow = ({ initialStep }: { initialStep?: STEP }) => {
    const { ruleAction, setRuleAction, onCompleted } = useNewQuickRule()

    useEffect(() => {
        if (!ruleAction) return

        setRuleAction(ruleAction)
    }, [])

    return (
        <WizardStateProvider
            onCompleted={onCompleted}
            context={NewRuleFlowContext}
            steps={INITIAL_STEPS}
            initialStep={initialStep || INITIAL_STEPS[0]}
        >
            <NewRuleComponent />
        </WizardStateProvider>
    )
}

export default NewRuleFlow
