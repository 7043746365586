import React, { useCallback } from 'react'
import { FormGroup } from './renderers'
import { fieldId } from './renderers'

const fieldFile = (options) => {
    const {
        title,
        input,
        meta,
        hint,
        disabled,
        compact,
        fullWidth,
        className,
        oneLine,
        readOnly,
        values,
        previewClass,
        required,
    } = options

    const onChange = useCallback((e) => {
        if (!e || !e.target || !e.target.files || !e.target.files.length) {
            return
        }

        input.onChange(e.target.files[0])
    }, [])

    let preview = null
    const preview_name = input.name + '_preview'

    if (values[preview_name]) {
        preview = <img className={previewClass} src={values[preview_name]} />
    }

    return (
        <FormGroup
            meta={meta}
            name={input.name}
            label={title}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            className={className}
            oneLine={oneLine}
            required={required}
        >
            <input
                id={fieldId(input.name)}
                className="form-control string optional"
                disabled={disabled}
                readOnly={readOnly}
                placeholder={options.placeholder}
                type="file"
                onChange={onChange}
                {...(required ? { 'aria-required': 'true' } : null)}
            />

            {preview}
        </FormGroup>
    )
}

export default fieldFile
