'use strict'

import TimeView from './TimeView'

import React from 'react'
import { DaysView } from './DaysView'
import { MonthsView } from './MonthsView'
import { YearsView } from './YearsView'

const viewComponents = {
    days: DaysView,
    months: MonthsView,
    years: YearsView,
    time: TimeView,
}

function CalendarContainer(props) {
    return React.createElement(viewComponents[props.view], props.viewProps)
}

export default CalendarContainer
