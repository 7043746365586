import React, { useContext, useState, createContext } from 'react'
import { useSelector } from 'react-redux'
import { isMagento } from 'utils/platform'

interface ConditionsProvider {
    conditions: string[]
}

export const CONDITIONS_TITLES = {
    price: 'Price',
    weight: 'Weight',
    qty: 'Quantity',
    shippingZones: 'Shipping Zones',
    shippingGroups: 'Shipping Groups',
    customerGroups: 'Customer Groups',
    couponCode: 'Coupon Code',
    volume: 'Volume',
}

export const CONDITIONS_NAMES = {
    price: 'ship_filter_ids_price',
    weight: 'ship_filter_ids_weight',
    qty: 'ship_filter_ids_qty',
    shippingZones: 'zone_ids',
    shippingGroups: 'shipping_group_ids',
    customerGroups: 'customer_group_ids',
    couponCode: 'coupon_code',
    volume: 'ship_filter_ids_volume',
}

export const CONDITIONS_ICONS = {
    price: 'fa-tag',
    weight: 'fa-weight-hanging',
    qty: 'fa-hashtag',
    shippingZones: 'fa-globe',
    shippingGroups: 'fa-boxes',
    customerGroups: 'fa-users',
    couponCode: 'fa-ticket-alt',
    volume: 'fa-box-open',
}

const ConditionsContext = createContext<ConditionsProvider>(null)

export const useConditions = () => useContext(ConditionsContext)

export const ConditionsProvider: React.FC = ({ children }) => {
    const { userSettings, platform } = useSelector(
        ({ app: { user_settings = {}, platform } }: any) => ({
            userSettings: user_settings ? user_settings : {},
            platform,
        })
    )

    const isCouponCodeAvailable = userSettings.rs_coupon_code
    const isCustomGroupsAvailable = userSettings.rs_cust_group
    const isVolumeAvailable =
        userSettings.rs_volume &&
        userSettings.rs_per_unit_volume &&
        isMagento(platform)

    const conditions = [
        'price',
        'weight',
        'qty',
        'shippingZones',
        'shippingGroups',
    ]

    if (isCustomGroupsAvailable) {
        conditions.push('customerGroups')
    }

    if (isCouponCodeAvailable) {
        conditions.push('couponCode')
    }

    if (isVolumeAvailable) {
        conditions.push('volume')
    }

    return (
        <ConditionsContext.Provider value={{ conditions }}>
            {children}
        </ConditionsContext.Provider>
    )
}
