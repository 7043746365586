import { axiosClient } from 'net/ajax'
import { useMutation } from 'react-query'

/**
 * Disconnects ShipEngine carrier
 * @param id carrier id
 * @returns {Promise<*>}
 */
async function disconnectCarrier({ id }) {
    const response = await axiosClient.post(
        window.Routes.disconnect_ratesmgr_carrier_account_path(id),
        {}
    )
    return response.data
}

export function useDisconnectAccount() {
    const {
        isLoading,
        data,
        error,
        mutateAsync: disconnect,
    } = useMutation((carrierData) => disconnectCarrier(carrierData))

    return {
        disconnect,
        isLoading,
        data,
        error,
    }
}

/**
 * Connects ShipEngine carrier
 * @param id carrier id
 * @param data credentials
 * @returns {Promise<*>}
 */
async function connectCarrier({ id, data }) {
    const response = await axiosClient.post(
        window.Routes.connect_ratesmgr_carrier_account_path(id),
        { ratesmgr_carrier_account: data }
    )
    return response.data
}

export function useConnectAccount() {
    const {
        isLoading,
        data,
        error,
        mutateAsync: connect,
    } = useMutation((carrierData) => connectCarrier(carrierData))

    return {
        connect,
        isLoading,
        data,
        error,
    }
}
