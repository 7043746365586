import { useRef, useEffect } from 'react'

export default function useTimeout(callback, delay) {
    const timeoutRef = useRef()
    const callbackRef = useRef(callback)

    useEffect(() => {
        callbackRef.current = callback
    }, [callback])

    useEffect(() => {
        if (typeof delay === 'number') {
            timeoutRef.current = window.setTimeout(
                () => callbackRef.current(),
                delay
            )

            return () => window.clearTimeout(timeoutRef.current)
        }
    }, [delay])

    return timeoutRef
}
