import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { Form, FormRenderProps } from 'react-final-form'
import { useConditionsSources } from 'hooks/useConditionsSources'
import EntitiesSelect from 'components/ui/form/EntitiesSelect'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import StepLayout from '../StepLayout'

const SelectForm = ({
    form,
    toggleMode,
}: {
    form: FormRenderProps
    toggleMode: () => void
}) => {
    const { invalid, values } = form
    const { actionTitle } = useConditionRulesField()
    const wizard = useWizardState({ context: ConditionFlowContext })

    const {
        customerGroups: { customerGroups, isLoading },
    } = useConditionsSources()

    const handleContinue = () => {
        wizard.submitStep({ ...wizard.context, ...values })
    }

    const handleToggleMode = () => {
        wizard.setContext((prev) => ({ ...prev, ...values }))
        toggleMode()
    }

    return (
        <StepLayout
            conditionType="customerGroups"
            disabled={invalid}
            onContinue={handleContinue}
        >
            <>
                <h3 className="blue-title align-left">
                    {`${actionTitle} when...`}
                </h3>

                <div className="initial-align">
                    <EntitiesSelect
                        multiSelect
                        searchable
                        entities={customerGroups}
                        disabled={isLoading}
                        name="customerGroupIds"
                        title="Select Customer Groups"
                    />
                </div>

                <p onClick={handleToggleMode} className="mb-0 primary-link">
                    I want to create a new Customer Group
                </p>
            </>
        </StepLayout>
    )
}

const SelectFormContainer = ({ toggleMode }: { toggleMode: () => void }) => {
    const conditionWizard = useWizardState({ context: ConditionFlowContext })

    return (
        <Form onSubmit={() => undefined} initialValues={conditionWizard.context}>
            {(form) => <SelectForm form={form} toggleMode={toggleMode} />}
        </Form>
    )
}

export default SelectFormContainer
