import React from 'react'
import { RuleBuilderAction } from './types'
import { ActionItem, StyledActionButton } from '../../ActionSelector/styles'
import { ActionIcon } from './ActionIcon'

export const actionTitles: Record<RuleBuilderAction, string> = {
    HIDE_METHODS: 'Hide Methods',
    OVERRIDE_RATES: 'Override Rates',
    FREE_SHIPPING: 'Offer Free Shipping',
    SURCHARGE_RATES: 'Surcharge Rates',
    DISCOUNT_RATES: 'Discount Rates',
}

const actionList: RuleBuilderAction[] = [
    'HIDE_METHODS',
    'OVERRIDE_RATES',
    'FREE_SHIPPING',
    'SURCHARGE_RATES',
    'DISCOUNT_RATES',
]

export const ActionButton: React.FC<{
    action: RuleBuilderAction
    onClick: (action: RuleBuilderAction) => void
    selected: boolean
}> = ({ action, onClick, selected }) => {
    const title = actionTitles[action] ?? 'Invalid action'

    return (
        <ActionItem selected={selected}>
            <StyledActionButton
                type="button"
                selected={selected}
                onClick={() => onClick(action)}
                data-testid={`action-${action}`}
            >
                <ActionIcon action={action} selected={selected} />
            </StyledActionButton>
            {title}
        </ActionItem>
    )
}

interface ActionSelectorProps {
    onChange: (action: RuleBuilderAction) => void
    selectedAction: RuleBuilderAction
}

/**
 * ActionSelector
 */
export const ActionSelector: React.FC<ActionSelectorProps> = ({
    onChange,
    selectedAction,
}) => {
    return (
        <>
            {actionList.map((action) => (
                <ActionButton
                    key={action}
                    action={action}
                    onClick={onChange}
                    selected={selectedAction === action}
                />
            ))}
        </>
    )
}
