import React from 'react'

export const ActionInput = ({
    id,
    name,
    title,
    disabled = false,
    onChange,
    icon,
    checked = false,
    variant = 'white',
}) => {
    return (
        <label className="conditions-label" htmlFor={`${name}-${id}`}>
            <input
                disabled={disabled}
                className="condition-input"
                id={`${name}-${id}`}
                type="checkbox"
                checked={checked}
                onChange={(event) => onChange(name, event.target.checked)}
                name={name}
            />
            <div className="condition-button">
                <div className={`condition-icon-container ${variant}`}>
                    {icon}
                </div>
                <div className="condition-title">{title}</div>
            </div>
        </label>
    )
}
