import { connect } from 'react-redux'

function PassOptionSources(props) {
    return props.children(props.option_sources)
}

const WithOptionSources = connect(({ app: { option_sources } }) => ({
    option_sources,
}))(PassOptionSources)

export default WithOptionSources
