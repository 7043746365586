import AjaxTableDataSource from './ajaxTables/AjaxTableDataSource'
import TableRatesDataSource from './ajaxTables/TableRatesDataSource'
import ShippingMethodsDataSource from './ajaxTables/ShippingMethodsDataSource'
import EntityDataSource from './entityDataSource'

export default function provider(name, props) {
    switch (name) {
        case 'entity':
            return new EntityDataSource(props)
        case 'ajax_table_rates':
            return new TableRatesDataSource(props)
        case 'ajax_shipping_methods':
            return new ShippingMethodsDataSource(props)
        case 'ajax_table':
            return new AjaxTableDataSource(props)
    }
}
