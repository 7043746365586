import React from 'react'
import { useGetShipFilters } from 'hooks/useShipFilters'
import { useGetCustomerGroups } from 'hooks/useCustomerGroups'
import { useGetShipGroups } from 'hooks/useShipGroups'
import { useGetZones } from 'hooks/useZones'

interface SourcesContextInterface {
    zones?: any
    shipGroups?: any
    shipFilters?: any
    customerGroups?: any
    isLoading: boolean
}

const ConditionsSourcesContext = React.createContext(null)

export const useConditionsSources = () =>
    React.useContext(ConditionsSourcesContext)

export const ConditionsSourcesProvider = ({
    children,
}: {
    children: JSX.Element
}) => {
    const zones = useGetZones()
    const shipGroups = useGetShipGroups()
    const shipFilters = useGetShipFilters()
    const customerGroups = useGetCustomerGroups()

    const isLoading =
        zones.isLoading ||
        shipGroups.isLoading ||
        shipFilters.isLoading ||
        customerGroups.isLoading

    const value: SourcesContextInterface = {
        zones,
        shipGroups,
        shipFilters,
        customerGroups,
        isLoading,
    }

    return (
        <ConditionsSourcesContext.Provider value={value}>
            {children}
        </ConditionsSourcesContext.Provider>
    )
}
