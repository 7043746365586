import React from 'react'
import DynamicForm from 'components/ui/form/DynamicForm'
import { useNewTableRateField } from 'components/ui/form/NewTableRateField/Provider'
import { FormRenderProps } from 'react-final-form'

interface StepFieldsProps {
    formConfig: string
    form: FormRenderProps
    errors: any
    classes?: {}
}

const StepFields = ({ formConfig, form, errors, classes }: StepFieldsProps) => {
    const { values } = form
    const { field } = useNewTableRateField()

    const stepForm = field.stepForms.find((form) => form[formConfig])[
        formConfig
    ]

    return (
        <div className="mb-5">
            <DynamicForm
                classes={classes}
                errors={errors}
                values={values}
                tab={stepForm}
                optionSources={field.formSources}
                onSubmit={form.handleSubmit}
                enabledFeatures={[]}
            />
        </div>
    )
}

export default StepFields
