import React, { useState } from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { CONDITIONS_ICONS } from 'components/ui/form/ConditionRulesField/ConditionsProvider'
import { QuickRule } from 'components/entities/QuickRule'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import { useUpdateQuickRule } from 'hooks/useQuickRules'
import {
    fieldInput as FieldInput,
    fieldTextarea as FieldTextarea,
} from 'components/ui/form/renderers'
import { required } from 'components/dashboard/integrations/containers/CarrierWizard/components/validators'
import { NewRuleFlowContext } from './index'
import { useNewQuickRule } from '../Provider'
import style from './new-flow.module.css'

const SuccessStep: React.FC = () => {
    const wizard = useWizardState({ context: NewRuleFlowContext })
    const { resetForm } = useNewQuickRule()
    const quickRule: any = new QuickRule(wizard.context.quickRule)
    const [ruleName, setRuleName] = useState(null)
    const [internalDescription, setInternalDescription] = useState('')
    const [errors, setErrors] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const { updateQuickRule } = useUpdateQuickRule()
    const [isRuleSummaryOpen, setIsRuleSummaryOpen] = useState(false)

    const {
        ship_filter_ids_price,
        ship_filter_ids_qty,
        ship_filter_ids_weight,
        zone_ids,
        exclude_zone_ids,
        shipping_group_ids,
        any_shipping_group_ids,
        excl_shipping_group_ids,
        customer_group_ids,
        ship_filters,
        all_shipping_groups,
        customer_groups,
        zones,
        coupon_code,
    } = quickRule

    const shipFiltersMap = keyBy(ship_filters, 'id')
    const shipGroupsMap = keyBy(all_shipping_groups, 'id')
    const customerGroupsMap = keyBy(customer_groups, 'id')
    const zonesMap = keyBy(zones, 'id')

    const conditions = [
        {
            hidden: isEmpty(ship_filter_ids_price),
            icon: CONDITIONS_ICONS.price,
            names: ship_filter_ids_price.map((id) => shipFiltersMap[id]?.name),
        },
        {
            hidden: isEmpty(ship_filter_ids_qty),
            icon: CONDITIONS_ICONS.qty,
            names: ship_filter_ids_qty.map((id) => shipFiltersMap[id]?.name),
        },
        {
            hidden: isEmpty(ship_filter_ids_weight),
            icon: CONDITIONS_ICONS.weight,
            names: ship_filter_ids_weight.map((id) => shipFiltersMap[id]?.name),
        },
        {
            hidden: isEmpty(zone_ids),
            icon: CONDITIONS_ICONS.shippingZones,
            title: 'Include',
            names: zone_ids.map((id) => zonesMap[id]?.name),
        },
        {
            hidden: isEmpty(exclude_zone_ids),
            icon: CONDITIONS_ICONS.shippingZones,
            title: 'Exclude',
            names: exclude_zone_ids.map((id) => zonesMap[id]?.name),
        },
        {
            hidden: isEmpty(shipping_group_ids),
            icon: CONDITIONS_ICONS.shippingGroups,
            title: 'All',
            names: shipping_group_ids.map((id) => shipGroupsMap[id]?.name),
        },
        {
            hidden: isEmpty(any_shipping_group_ids),
            icon: CONDITIONS_ICONS.shippingGroups,
            title: 'Any',
            names: any_shipping_group_ids.map((id) => shipGroupsMap[id]?.name),
        },
        {
            hidden: isEmpty(excl_shipping_group_ids),
            icon: CONDITIONS_ICONS.shippingGroups,
            title: 'Prevent',
            names: excl_shipping_group_ids.map((id) => shipGroupsMap[id]?.name),
        },
        {
            hidden: isEmpty(customer_group_ids),
            icon: CONDITIONS_ICONS.customerGroups,
            title: 'Any',
            names: customer_group_ids.map((id) => customerGroupsMap[id]?.name),
        },
        {
            hidden: isEmpty(coupon_code),
            icon: CONDITIONS_ICONS.couponCode,
            title: 'Contains',
            names: [coupon_code],
        },
    ]

    const renderConditions = conditions.filter((condition) => !condition.hidden)

    const handleReset = (event) => {
        event.preventDefault()

        if (!ruleName) {
            setErrors({ name: 'A unique name for your rule is required' })
            return
        }

        const updateProps = {
            name: ruleName,
            id: quickRule.id,
            description: internalDescription,
        }

        setErrors({})
        setLoading(true)

        updateQuickRule(updateProps, {
            onSuccess: async ({ data }) => {
                setLoading(false)
                setRuleName(data.name)
                if (data.description && typeof data.description === 'string')
                    setInternalDescription(data.description)

                resetForm()
                wizard.reset()
            },
            onError: ({ errors }: any) => {
                setLoading(false)
                setErrors(errors)
            },
        })
    }

    const handleSubmitStep = (event) => {
        event.preventDefault()

        if (!ruleName) {
            setErrors({ name: 'A unique name for your rule is required' })
            return
        }

        const updateProps = {
            name: ruleName,
            id: quickRule.id,
            description: internalDescription,
        }

        setErrors({})
        setLoading(true)

        updateQuickRule(updateProps, {
            onSuccess: ({ data }) => {
                setLoading(false)
                setRuleName(data.name)
                if (data.description && typeof data.description === 'string')
                    setInternalDescription(data.description)
                wizard.submitStep()
                window.location.href = '/ratesmgr/quick_rules'
            },
            onError: ({ errors }: any) => {
                setLoading(false)
                setErrors(errors)
            },
        })
    }

    return (
        <div className="condition-form">
            <div className="condition-container">
                <div className="center sub-title">
                    <h3 className="normal">
                        Name your <strong>{quickRule.name} rule</strong>
                    </h3>
                </div>

                <div className="c-message">
                    <img
                        className="background-image"
                        src="/images/carriers/stars_bg.png"
                    />
                </div>

                <div
                    className={`condition-content ${style.upperRuleContainer}`}
                >
                    <div className={style.sectionContainer}>
                        <p className={style.preTitle}>
                            I want to name my rule...
                        </p>

                        <div>
                            <FieldInput
                                title="Shipping Rule name *"
                                error={errors?.name}
                                meta={{}}
                                disabled={loading}
                                validate={required}
                                input={{
                                    name: 'name',
                                    value: ruleName,
                                    onChange: (event) => {
                                        setRuleName(event.target.value)

                                        if (!event.target.value) {
                                            setErrors({ name: 'A unique name for your rule is required' });
                                        } else {
                                            setErrors({});
                                        }
                                    }
                                }}
                            />

                            <p className={style.caption}>
                                Provide a unique name for your Shipping Rule
                            </p>
                        </div>
                    </div>

                    <div className={style.sectionContainer}>
                        <p className={style.preTitle}>
                            The purpose of this rule is...
                        </p>

                        <div>
                            <FieldTextarea
                                title="Internal description"
                                error={errors?.description}
                                meta={{}}
                                disabled={loading}
                                required={false}
                                input={{
                                    name: 'description',
                                    value: internalDescription,
                                    onChange: (event) =>
                                        setInternalDescription(
                                            event.target.value
                                        ),
                                }}
                            />

                            <p className={style.caption}>
                                Add a clear and easily identifiable description
                                of your Shipping Rule to be used as an internal
                                reference
                            </p>
                        </div>
                    </div>
                </div>

                <div className={style.lowerRuleContainer}>
                    <button
                        onClick={() => setIsRuleSummaryOpen(!isRuleSummaryOpen)}
                        className={style.ruleSummaryButton}
                        type="button"
                    >
                        <i
                            className={`fas ${
                                isRuleSummaryOpen
                                    ? 'fa-caret-down'
                                    : 'fa-caret-right'
                            } ${style.summaryIcon}`}
                        ></i>
                        Show Surcharge Rates rule summary
                    </button>

                    {isRuleSummaryOpen && (
                        <div className={style.ruleSummaryContainer}>
                            <div className={style.ruleSection}>
                                <p className={style.ruleSummaryText}>
                                    Rule Applied as:
                                </p>
                                {quickRule.appliesToLabel &&
                                quickRule.typeLabel ? (
                                    <p>
                                        <strong>{`${quickRule.typeLabel}: `}</strong>

                                        {quickRule.typeValueLabel &&
                                            `${quickRule.typeValueLabel}, ${quickRule.appliesToLabel}`}
                                    </p>
                                ) : (
                                    quickRule.appliesToLabel
                                )}
                            </div>

                            <div
                                className={`${style.ruleSection} ${style.ruleSectionBorder}`}
                            >
                                <p className={style.ruleSummaryText}>
                                    For these shipping methods:
                                </p>

                                {quickRule.withoutCarrierShippingMethods && (
                                    <p className="mb-1">
                                        No shipping methods needed
                                    </p>
                                )}

                                <ShippingGroupItem
                                    groups={quickRule.shippingMethodsGroups}
                                />

                                <ShippingGroupItem
                                    groups={quickRule.carrierMethodsGroups}
                                />
                            </div>

                            <div className={style.ruleSection}>
                                <p className={style.ruleSummaryText}>
                                    When these conditions apply:
                                </p>

                                {renderConditions.length === 0 && (
                                    <p className={style.ruleSummaryText}>
                                        No conditions needed
                                    </p>
                                )}

                                {renderConditions.map((condition, index) => (
                                    <div
                                        className="flex align-start"
                                        key={`shipping-condition-${index}`}
                                    >
                                        <i
                                            className={`fa blue ${condition.icon} mr-3`}
                                            style={{ marginTop: 7 }}
                                        />
                                        <p className="mb-1">
                                            <span>
                                                {condition.title && (
                                                    <strong>
                                                        {`${condition.title}: `}
                                                    </strong>
                                                )}
                                                {condition.names.join(', ')}
                                            </span>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <button
                id="modalCloseButton"
                data-testid="modal-close-button"
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={handleSubmitStep}
                disabled={loading}
            />

            <button
                type="button"
                className="btn btn-primary mb-4"
                aria-hidden="true"
                onClick={handleSubmitStep}
                disabled={loading || !ruleName}
            >
                Finish
            </button>

            <button
                onClick={handleReset}
                disabled={loading || !ruleName}
                className="mb-0 blue-link blue-link-button cancel-link"
            >
                Create another rule
            </button>
        </div>
    )
}

const ShippingGroupItem = ({ groups }) => {
    return (
        <>
            {Object.keys(groups).map((groupName, index) => (
                <div
                    className="flex align-start"
                    key={`shipping-method-${index}`}
                >
                    <i
                        className="fa blue fa-check mr-4"
                        style={{ marginTop: 7 }}
                    />

                    <p className="mb-1">
                        <strong>{groupName}: </strong>

                        {groups[groupName]
                            .map((method) => method.name)
                            .join(', ')}
                    </p>
                </div>
            ))}
        </>
    )
}

export default SuccessStep
