//
import React, { Component } from 'react'
import { FormGroup } from 'components/ui/form/renderers'
import DatePicker from 'components/ui/DateTime'
import store from 'reducers/store'
import moment from 'moment'

/**
 */
export default class fieldDate extends Component {
    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
        this.getDateFormat = this.getDateFormat.bind(this)
        this.renderDateMultiSelect = this.renderDateMultiSelect.bind(this)
        this.renderInput = this.renderInput.bind(this)
    }

    onChange(value) {
        const { input } = this.props
        if (value.format) {
            input.onChange(value.format(this.getDateFormat()))
        } else if (Array.isArray(value)) {
            input.onChange(
                value
                    .map((value) => value.format(this.getDateFormat()))
                    .join(', ')
            )
        } else if (typeof value === 'string') {
            // this happens when user edits the value manually
            input.onChange(value === '' ? null : value)
        }
    }

    getDateFormat() {
        const {
            app: { date_format },
        } = store.getState()
        const { dateFormat } = this.props

        if (dateFormat) {
            return dateFormat
                .replace('%d', 'DD')
                .replace('%m', 'MM')
                .replace('%Y', 'YYYY')
        } else if (date_format) {
            return date_format
                .replace('%d', 'DD')
                .replace('%m', 'MM')
                .replace('%Y', 'YYYY')
        } else {
            return 'YYYY-MM-DD'
        }
    }

    static getTimeFormat() {
        const {
            app: { time_format },
        } = store.getState()

        if (time_format) {
            return time_format
                .replace('%l', 'h')
                .replace('%M', 'mm')
                .replace('%p', 'A')
        } else {
            return 'h:mm A'
        }
    }

    render() {
        const {
            input,
            meta,
            title,
            hint,
            fullWidth,
            className,
            compact,
            oneLine,
            multiSelect,
        } = this.props

        const { type, onChange: onChangeFn } = input

        if (type === 'date') {
            return (
                /* $FlowFixMe */
                <FormGroup
                    meta={meta}
                    label={title}
                    hint={hint}
                    compact={compact}
                    fullWidth={fullWidth}
                    oneLine={oneLine}
                    className={className}
                    name={input.name}
                >
                    <DatePicker
                        {...input}
                        {...meta}
                        timeFormat={false}
                        onChange={this.onChange}
                        dateFormat={this.getDateFormat()}
                        renderInput={this.renderInput}
                        allowMultiple={multiSelect}
                    />
                </FormGroup>
            )
        } else if (type === 'datetime') {
            return (
                /* $FlowFixMe */
                <FormGroup
                    meta={meta}
                    label={title}
                    hint={hint}
                    compact={compact}
                    fullWidth={fullWidth}
                    oneLine={oneLine}
                    className={className}
                    name={input.name}
                >
                    <DatePicker
                        {...input}
                        {...meta}
                        timeFormat={fieldDate.getTimeFormat()}
                        onChange={(e) =>
                            onChangeFn(
                                e.format
                                    ? e
                                          .format(
                                              this.getDateFormat() +
                                                  ' ' +
                                                  fieldDate.getTimeFormat()
                                          )
                                          .trim()
                                    : null
                            )
                        }
                        dateFormat={this.getDateFormat()}
                        renderInput={this.renderInput}
                    />
                </FormGroup>
            )
        } else {
            return (
                /* $FlowFixMe */
                <FormGroup
                    meta={meta}
                    label={title}
                    hint={hint}
                    compact={false}
                    fullWidth={fullWidth}
                    oneLine={oneLine}
                    className={className}
                    name={input.name}
                >
                    <DatePicker
                        {...input}
                        {...meta}
                        timeFormat={fieldDate.getTimeFormat()}
                        onChange={(e) => {
                            const timeFormat = fieldDate.getTimeFormat()
                            let value = e.format
                                ? e.format(timeFormat).trim()
                                : null
                            if (
                                value === null &&
                                typeof e === 'string' &&
                                e.length === 5
                            ) {
                                // try to parse using hh:mm format
                                value = moment(e, 'hh:mm')
                                    .format(timeFormat)
                                    .trim()
                            }
                            onChangeFn(value)
                            return { inputValue: value }
                        }}
                        dateFormat={false}
                        renderInput={this.renderInput}
                    />
                </FormGroup>
            )
        }
    }

    getDatesList(value) {
        let dates = value.length ? value.split(',') : []

        if (this.props.sort) {
            const format = this.getDateFormat()

            dates.sort((a, b) => {
                const aMoment = moment(a, format)
                const bMoment = moment(b, format)
                return aMoment > bMoment ? 1 : aMoment < bMoment ? -1 : 0
            })
        }

        return dates
    }

    renderDates(value, updateSelectedDate) {
        const dates = this.getDatesList(value)

        if (!dates.length) {
            return <span className="empty-note">Add Dates</span>
        }

        return dates.map((dateValue) => {
            return (
                <li
                    key={dateValue}
                    className="srch-choice"
                    onClick={(e) => e.stopPropagation()}
                >
                    {moment(dateValue, this.getDateFormat()).format(
                        'D MMM YYYY'
                    )}{' '}
                    <i
                        className="far fa-times"
                        onClick={(e) => {
                            e.stopPropagation()
                            updateSelectedDate(dateValue, false)
                        }}
                    />
                </li>
            )
        })
    }

    renderDateMultiSelect(props, openCalendar, updateSelectedDate) {
        return (
            <div className="show-tick optional multiselect-ignore searchable-select">
                <div className="chosen-container chosen-container-multi chosen-with-drop chosen-container-active">
                    <ul
                        className="chosen-choices form-control select"
                        onClick={openCalendar}
                    >
                        <div className="icon-container">
                            <i className="fa fa-calendar-alt" />
                        </div>
                        {this.renderDates(props.value, updateSelectedDate)}
                    </ul>
                </div>
            </div>
        )
    }

    renderInput(props, openCalendar, close, updateSelectedDate) {
        const {
            input: { type },
            multiSelect,
        } = this.props

        if (type === 'date' && multiSelect) {
            return this.renderDateMultiSelect(
                props,
                openCalendar,
                updateSelectedDate
            )
        }

        return (
            <div className="input-group">
                <input
                    name={this.props.input.name}
                    {...props}
                    autoComplete="off"
                />
                <span className="input-group-addon" onClick={openCalendar}>
                    <span
                        className={
                            'fa fa-' +
                            (type === 'date' || type === 'datetime'
                                ? 'calendar-alt'
                                : 'clock')
                        }
                    />
                </span>
            </div>
        )
    }
}
