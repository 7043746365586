import isEmpty from 'lodash/isEmpty'

const caller = (obj, method) => {
    if (isEmpty(method)) return obj
    if (method === '.') return obj

    for (let i = 0, path = method.split('.'), len = path.length; i < len; i++) {
        if (obj[path[i]] === null || obj[path[i]] === undefined) return
        obj = obj[path[i]]
    }

    return obj
}

export default caller
