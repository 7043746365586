//
import * as React from 'react'
import { Component } from 'react'
import DropdownContext from 'components/ui/DropdownContext'

export default class DropdownMenu extends Component {
    state = {
        opened: false,
    }

    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState((state) => ({
            opened: !state.opened,
        }))
    }

    render() {
        let { classes = ['btn-group'], label, buttonClassName } = this.props

        if (this.state.opened) {
            classes = [...classes, 'open']
        }

        return (
            <div className={classes.join(' ')}>
                <button
                    type="button"
                    className={
                        buttonClassName || 'btn btn-bl-o dropdown-toggle'
                    }
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={this.toggle}
                >
                    {label}
                    <i className="far fa-angle-down ml-1" />
                </button>
                <ul className="dropdown-menu">
                    <DropdownContext.Provider
                        value={{
                            close: () => this.setState({ opened: false }),
                        }}
                    >
                        {this.props.children}
                    </DropdownContext.Provider>
                </ul>
            </div>
        )
    }
}
