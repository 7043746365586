import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import DynamicForm from 'components/ui/form/DynamicForm'
import { NewRuleFlowContext } from './index'
import { useNewQuickRule } from '../Provider'
import isEmpty from 'lodash/isEmpty'
import { actionTitles } from 'components/ui/form/RuleBuilderActionField/ActionSelector'
import { useShippingRuleFormOptions } from 'components/ui/form/RuleBuilderActionField'

const ShippingMethodStep: React.FC = () => {
    const { values, selectedAction, shippingMethodForm } = useNewQuickRule()
    const { features, optionSources } = useShippingRuleFormOptions()
    const wizard = useWizardState({ context: NewRuleFlowContext })
    const title = actionTitles[values.builder_action]

    const shippingMethodIds = [
        ...(values.shipping_method_ids || []),
        ...(values.carriers_methods_code_ids || []),
    ]

    const handleSubmitStep = () => {
        wizard.submitStep()
    }

    const prevStep = () => {
        if (selectedAction === 'HIDE_METHODS') {
            wizard.setStep('selectAction')
        } else {
            wizard.prevStep()
        }
    }

    return (
        <div className="condition-form">
            <h2 className="title">
                <strong>{title}</strong>
                <br />
                for these shipping methods...
            </h2>

            <div className="mb-5">
                <DynamicForm
                    classes={{
                        root: 'initial-align',
                        formCol: 'form-col-1',
                        formRow: 'form-row mt-0',
                    }}
                    values={values}
                    tab={{ fields: [shippingMethodForm] }}
                    optionSources={optionSources}
                    onSubmit={() => undefined}
                    enabledFeatures={features}
                />
            </div>

            <button
                data-testid="modal-close-button"
                type="button"
                className="back"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={prevStep}
            />

            <button
                disabled={isEmpty(shippingMethodIds)}
                type="button"
                className="btn btn-primary"
                aria-hidden="true"
                onClick={handleSubmitStep}
            >
                Continue
            </button>
        </div>
    )
}

export default ShippingMethodStep
