//
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useReducer, useState } from 'react'
import { logoStyle } from 'components/dashboard/integrations/containers/Index/components/utils'
import {
    useCarrierLogo,
    useCarrierTypes,
    useInstalledCarrierTypes,
} from 'hooks/carriers'


/**
 * @param name
 * @param title
 * @param onToggle
 * @param checked
 * @returns {JSX.Element}
 * @constructor
 */
function CarrierTypeOption({
    name,
    title,
    onToggle,
    checked,
}) {
    const { logo, logos } = useCarrierLogo(name)

    return (
        <div className={`c-carrier c-carrier-${name}`}>
            <button
                type="button"
                className="btn"
                onClick={() => onToggle(name)}
            >
                {logo && (
                    <div className="icon" {...logoStyle(logos, name)}>
                        <img alt={`${title} logo`} src={logo} />
                    </div>
                )}
                <div className="title">{title}</div>
                {checked && (
                    <i
                        className="fas fa-check-circle"
                        data-testid={`carrier-${name}-check`}
                    />
                )}
            </button>
        </div>
    )
}

// Implements toggling of enabled carriers
const reducer = (carriers, name) => {
    const result = new Set(carriers)

    if (result.has(name)) {
        result.delete(name)
    } else {
        result.add(name)
    }

    return result
}

/**
 * This selector is used for selecting carrier types for the Woo Free Lite plan
 * @param input
 * @returns {JSX.Element}
 * @constructor
 */
export default function CarrierTypeSelector({ input }) {
    const carrierTypes = useCarrierTypes()
    const [enabledCarriers, toggleCarrier] = useReducer(reducer, {}, () => {
        // preselect all carriers
        const result = new Set()
        for (const carrier of carrierTypes) {
            result.add(carrier.name)
        }
        return result
    })

    const onToggle = (name) => {
        toggleCarrier(name)
    }

    useEffect(() => {
        if (input?.onChange) {
            input.onChange(Array.from(enabledCarriers))
        }
    }, [enabledCarriers])

    return (
        <div className="c-type-live">
            <div className="c-carriers tw-carrier-types">
                {carrierTypes.map((carrier) => (
                    <CarrierTypeOption
                        key={carrier.name}
                        {...carrier}
                        onToggle={onToggle}
                        checked={enabledCarriers.has(carrier.name)}
                    />
                ))}
            </div>
        </div>
    )
}

/**
 * This component renders a list of created carriers in the last step of the Woo Free Lite plan TW flow
 * @returns {JSX.Element}
 * @constructor
 */
export function InstalledCarrierTypes() {
    const availableCarrierTypes = useCarrierTypes()
    const [enabledCarriers, setEnabledCarriers] = useState(new Set())
    const { loading, carrierTypes } = useInstalledCarrierTypes()

    useEffect(() => {
        if (!carrierTypes) {
            return
        }

        const newValue = new Set()

        for (const ctype of carrierTypes) {
            newValue.add(ctype)
        }
        setEnabledCarriers(newValue)
    }, [carrierTypes])

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <div className="c-type-live">
            <div className="c-carriers tw-carrier-types selected">
                {availableCarrierTypes.map((carrier) => (
                    <CarrierTypeOption
                        key={carrier.name}
                        {...carrier}
                        onToggle={() => {}}
                        checked={enabledCarriers.has(carrier.name)}
                    />
                ))}
            </div>
        </div>
    )
}
