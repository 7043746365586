import { RuleBuilderAction } from '../RuleBuilderActionField/types'
import { useState } from 'react'
import { FormApi } from 'final-form'

const ruleActionFromValues = (values) => {
    const { action_method, action_type, base_price, base_percentage } = values

    switch (`${action_method}.${action_type}`) {
        case 'SHOW.SET':
            return parseFloat(base_price) === 0.0 &&
                parseFloat(base_percentage) === 0.0
                ? 'FREE_SHIPPING'
                : 'OVERRIDE_RATES'
        case 'SHOW.SURCHARGE':
            return 'SURCHARGE_RATES'
        case 'HIDE.NONE':
            return 'HIDE_METHODS'
        default:
            return null
    }
}

export function useSetRuleActionType({ form }: { form: FormApi }) {
    const [selectedAction, setSelectedAction] = useState<RuleBuilderAction>(
        () => {
            const { values } = form.getState()
            return values['builder_action'] || ruleActionFromValues(values)
        }
    )

    const setRuleAction = (actionType: RuleBuilderAction) => {
        form.change('builder_action', actionType)

        switch (actionType) {
            case 'OVERRIDE_RATES':
                form.change('action_method', 'SHOW')
                form.change('action_type', 'SET')
                form.change('_action_value', 'FLAT_RATE')
                break
            case 'SURCHARGE_RATES':
                form.change('action_method', 'SHOW')
                form.change('action_type', 'SURCHARGE')
                form.change('_action_value', 'FLAT_RATE')
                break
            case 'HIDE_METHODS':
                form.change('action_method', 'HIDE')
                form.change('action_type', 'NONE')
                form.change('_action_value', 'FLAT_RATE')
                break
            case 'DISCOUNT_RATES':
                form.change('action_method', 'SHOW')
                form.change('action_type', 'SURCHARGE')
                form.change('_action_value', 'FLAT_RATE')
                break
            case 'FREE_SHIPPING':
                form.change('action_method', 'SHOW')
                form.change('action_type', 'SET')
                form.change('base_price', 0)
                form.change('base_percentage', 0)
                form.change('_action_value', 'FLAT_RATE')
                break
            default:
                form.change('action_method', '')
                form.change('action_type', null)
                break
        }

        setSelectedAction(actionType)
    }

    return {
        setRuleAction,
        selectedAction,
    }
}
