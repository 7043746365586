import React, { useState, createContext, useContext, useMemo } from 'react'
import DynamicForm from 'components/ui/form/DynamicForm'
import { Form, useForm, useFormState } from 'react-final-form'
import WithOptionSources from 'components/dashboard/WithOptionSources'
import logger from 'utils/logger'
import { LinkButton } from 'components/dashboard/styles'
import MultiStepFormContext from 'components/ui/form/MultiStepFormContext'
import resolveFields from 'components/ui/form/fieldResolver'
import {
    useDisconnectAccount,
    useConnectAccount,
} from 'components/ui/form/api/carrierAccounts'
import NetworkError from 'components/shared/NetworkError'

function useUpdateCarrierCredentials() {
    const [isUpdatingCredentials, setIsUpdatingCredentials] = useState(false)
    return {
        isUpdatingCredentials,
        setIsUpdatingCredentials,
    }
}

const UpdateCredentialsContext = createContext({
    isUpdatingCredentials: false,
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-empty-function
    setIsUpdatingCredentials: (state) => {},
})
UpdateCredentialsContext.displayName = 'UpdateCredentialsContext'

function useCarrierAccountValidated() {
    const { values = {} } = useFormState()
    return !!values?.ship_engine_carrier_id
}

function CarrierAccountValidated() {
    const form = useForm()
    const {
        values: { id, label_carrier_username: carrierUsername = '' } = {},
    } = useFormState()
    const { disconnect, isLoading, error } = useDisconnectAccount()
    const { setIsUpdatingCredentials } = useContext(UpdateCredentialsContext)

    const onDisconnect = async () => {
        try {
            await disconnect({ id })
            form.change('ship_engine_carrier_id', null)
            form.change('label_carrier_username', null)
            form.change('label_carrier_password', null)
        } catch (e) {
            logger.error(e)
        }
    }

    return (
        <>
            {error && <NetworkError error={error} />}
            <div className="ent-lbl-credentials validated">
                <span>
                    Stamps.com account <strong>{carrierUsername}</strong> is
                    successfully validated.{' '}
                    <LinkButton
                        type="button"
                        onClick={() => setIsUpdatingCredentials(true)}
                    >
                        View or update credentials.
                    </LinkButton>
                </span>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={onDisconnect}
                    disabled={isLoading}
                >
                    Disconnect
                </button>
            </div>
        </>
    )
}

function ValidateCarrierAccount(props) {
    const { fields, values } = props
    const { connect, isLoading, error } = useConnectAccount()
    const { setIsUpdatingCredentials } = useContext(UpdateCredentialsContext)
    const [fieldNames] = useState(() =>
        Object.values(resolveFields({ fields })).map((field) => field.name)
    )

    const initialValues = useMemo(
        () =>
            fieldNames.reduce((result, b) => {
                const value = values[b]
                if (value) {
                    // eslint-disable-next-line no-param-reassign
                    result[b] = value
                }
                return result
            }, {}),
        [fieldNames, values]
    )

    const form = useForm()

    const onSubmit = async (data) => {
        const handleFailure = () => {
            form.change('ship_engine_carrier_id', null)
        }

        try {
            const response = await connect({ id: values.id, data })
            if (response.ship_engine_carrier_id) {
                // success?
                form.change(
                    'ship_engine_carrier_id',
                    response.ship_engine_carrier_id
                )
                form.change(
                    'label_carrier_username',
                    data.label_carrier_username
                )
                form.change(
                    'label_carrier_password',
                    data.label_carrier_password
                )
                setIsUpdatingCredentials(false)

                return
            }
        } catch (e) {
            logger.error(e)
        }

        handleFailure()
    }

    return (
        <MultiStepFormContext.Provider
            value={{
                values: {},
                resetForm: () => undefined,
                disableAutocomplete: true,
            }}
        >
            <Form onSubmit={onSubmit} initialValues={initialValues}>
                {(form) => (
                    <WithOptionSources>
                        {(optionSources) => (
                            <>
                                <DynamicForm
                                    name="form1"
                                    values={values}
                                    tab={{
                                        fields,
                                    }}
                                    optionSources={optionSources}
                                    onSubmit={form.handleSubmit}
                                    enabledFeatures={[]}
                                    platform="magento 2"
                                />
                                {error && <NetworkError error={error} />}
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={form.handleSubmit}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Validating..' : 'Validate'}
                                    {isLoading && (
                                        <i className="fa fa-spinner fa-pulse" />
                                    )}
                                </button>
                            </>
                        )}
                    </WithOptionSources>
                )}
            </Form>
        </MultiStepFormContext.Provider>
    )
}

export default function fieldLabelCarrierValidator(props) {
    const isValidated = useCarrierAccountValidated(props.values)
    const credentialsUpdater = useUpdateCarrierCredentials()

    let body
    if (isValidated && !credentialsUpdater.isUpdatingCredentials) {
        body = <CarrierAccountValidated {...props} />
    } else {
        body = <ValidateCarrierAccount {...props} />
    }

    return (
        <UpdateCredentialsContext.Provider value={credentialsUpdater}>
            {body}
        </UpdateCredentialsContext.Provider>
    )
}
