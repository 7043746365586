import React, { useState } from 'react'
import useTimeout from 'hooks/useTimeout'
import LoaderIcon from './LoaderIcon'

const DELAY = 300

export default function Loader() {
    const [visible, setVisible] = useState(false)
    const showLoader = () => setVisible(true)

    useTimeout(showLoader, DELAY)

    if (!visible) {
        return null
    }

    return <LoaderIcon />
}
