//
import React, { Component } from 'react'
import segmentize from '../segmentize'

/**
 * Paginator Properties
 */

/**
 * Paginator State
 */

/**
 */
export default class Paginator extends Component {
    constructor(props) {
        super(props)

        this.navigate = this.navigate.bind(this)
    }

    navigate(e, page, disabled) {
        e.preventDefault()

        if (disabled) {
            return false
        }

        if (!isNaN(page)) {
            this.props.onNavigate(page)
        }

        return false
    }

    renderPage(idx, text, active, disabled, extraCls = '') {
        const classes = ['paginate_button']

        if (active) {
            classes.push('active')
        }

        if (disabled) {
            classes.push('disabled')
        }

        if (extraCls) {
            classes.push(extraCls)
        }

        return (
            <li key={idx + text} className={classes.join(' ')}>
                <a
                    href="#"
                    aria-controls="table_rates_data_table"
                    onClick={(e) => this.navigate(e, idx, disabled)}
                >
                    {text}
                </a>
            </li>
        )
    }

    renderPages() {
        const pageInfo = this.props.dataSource.getPageInfo()
        const pages = pageInfo.total
        const page = pageInfo.current
        const segments = segmentize({
            page,
            pages: pages + 1,
            sidePages: 2,
        })

        const result = []

        result.push(
            this.renderPage(
                latestPage(segments.previousPages),
                <span>Previous</span>,
                false,
                page === 1
            )
        )

        result.push(
            ...[
                ...segments.previousPages,
                ...segments.centerPage,
                ...segments.nextPages,
            ].map((pageIdx) =>
                this.renderPage(
                    pageIdx,
                    String(pageIdx),
                    pageIdx === page,
                    pages === 1
                )
            )
        )

        result.push(
            this.renderPage(
                segments.nextPages[0],
                <span>Next</span>,
                false,
                page === pages
            )
        )

        return result
    }

    render() {
        return <ul className="pagination">{this.renderPages()}</ul>
    }
}

function latestPage(pages) {
    if (pages && pages.length > 0) {
        return pages[pages.length - 1]
    }
    return 0
}
