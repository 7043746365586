import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { Form, FormRenderProps } from 'react-final-form'
import { useGetShipGroups } from 'hooks/useShipGroups'

import EntitiesSelect from 'components/ui/form/EntitiesSelect'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import StepLayout from '../StepLayout'

const PreventRuleForm = ({ form }: { form: FormRenderProps }) => {
    const { shipGroups: allShipGroups, isLoading } = useGetShipGroups()
    const { invalid, values } = form
    const wizard = useWizardState({ context: ConditionFlowContext })
    const shipGroupIds = wizard.context.shipGroupIds || []
    const preventShipGroups = allShipGroups.filter(
        (group) => !shipGroupIds.includes(group.id)
    )

    const handleContinue = () => {
        wizard.submitStep({ ...wizard.context, ...values })
    }

    return (
        <StepLayout
            conditionType="shippingGroups"
            disabled={invalid}
            onContinue={handleContinue}
        >
            <>
                <h3 className="blue-title align-left">
                    Prevent this rule from applying
                </h3>

                <div className="initial-align">
                    <EntitiesSelect
                        multiSelect
                        searchable
                        entities={preventShipGroups}
                        disabled={isLoading}
                        name="preventShipGroupIds"
                        title="Shipping Groups that prevent rule from applying"
                        hint="Any selected Shipping Groups will prevent this rule from applying, not just the Shipping Groups selected above."
                    />
                </div>
            </>
        </StepLayout>
    )
}

const PreventRuleStep = () => {
    const conditionWizard = useWizardState({ context: ConditionFlowContext })

    return (
        <Form onSubmit={() => {}} initialValues={conditionWizard.context}>
            {(form) => <PreventRuleForm form={form} />}
        </Form>
    )
}

export default PreventRuleStep
