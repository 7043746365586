//
import React, { Component } from 'react'

/**
 * RecordLimitSelector Properties
 */

/**
 * RecordLimitSelector State
 */

/**
 */
export default class RecordLimitSelector extends Component {
    constructor(props) {
        super(props)

        this.onChange = this.onChange.bind(this)
    }

    onChange(e) {
        this.props.onChange(parseInt(e.currentTarget.value))
    }

    render() {
        const limits = [10, 15, 25, 50, 100, 1000]
        return (
            <div className="dataTables_length pagination-stats">
                <span className="mr-1">Show</span>
                <div className="select-sm">
                    <select
                        value={this.props.value}
                        onChange={this.onChange}
                        className="form-control input-sm"
                    >
                        {limits.map((limit) => (
                            <option key={limit} value={limit}>
                                {limit} rows
                            </option>
                        ))}
                    </select>
                </div>
                <span className="ml-1">entries</span>
            </div>
        )
    }
}
