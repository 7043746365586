//

export default function (options) {
    const pages = options.pages
    const page = Math.min(Math.max(options.page, 1), pages)
    const previousPages = options.sidePages
        ? range(Math.max(page - options.sidePages, 1), page)
        : []
    const nextPages = options.sidePages
        ? range(page + 1, Math.min(page + options.sidePages + 1, pages))
        : []
    const beginPages = options.beginPages
        ? range(1, Math.min(options.beginPages, pages) + 1)
        : []
    const endPages = options.endPages
        ? range(Math.max(pages - options.endPages + 1, 0), pages + 1)
        : []

    return {
        beginPages: difference(
            beginPages,
            range(
                page,
                Math.max(pages, options.beginPages ? options.beginPages : 0) + 1
            )
        ),
        previousPages: difference(previousPages, beginPages),
        centerPage: [page],
        nextPages: difference(nextPages, endPages),
        endPages: difference(endPages, range(0, page + 1)),
    }
}

function range(a, b) {
    const len = b ? b : a
    const ret = []
    let i = b ? a : 0

    for (; i < len; i++) {
        ret.push(i)
    }

    return ret
}

function difference(a, b) {
    return a.filter(function (v) {
        return b.indexOf(v) < 0
    })
}
