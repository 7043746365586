import React, { useState } from 'react'
import styled from 'styled-components'
import { useClickOutsideEffect } from 'hooks/useEventEffects'
import { usePopper } from 'react-popper'

interface PopoverProps {
    isOpen: boolean
}

interface ActionListPopoverProps {
    containerRef?: any
    menuComponent: Function
    open: boolean
    onClose: Function
}

const Popover = styled.div`
    min-width: 144px;
    transition: width 0.3s, opacity 0.3s;
    opacity: ${(props: PopoverProps) => (props.isOpen ? '1' : '0')};
    visibility: ${(props: PopoverProps) =>
        props.isOpen ? 'visible' : 'hidden'};
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    z-index: 1;
    border-radius: 3px;
`

const Triangle = styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    visibility: hidden;
    right: -8px;

    &::before {
        content: '';
        position: absolute;
        visibility: visible;
        border-style: solid;
        border-width: 6px 0 6px 6px;
        border-color: transparent transparent transparent white;
    }
`

export const ActionItemPopover = styled.div`
    padding: 10px 12px;
    transition: background-color 0.1s;
    background-color: white;
    gap: 8px;
    border-bottom: 1px solid #ddd;

    &:hover {
        background-color: #eee;
    }

    &.disabled {
        cursor: not-allowed;
        pointer-events: all;
    }
`

export const ActionListPopover: React.FC<ActionListPopoverProps> = ({
    children,
    containerRef,
    open = false,
    onClose,
    menuComponent: MenuComponent,
}) => {
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const [arrowElement, setArrowElement] = useState(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'left',
        strategy: 'fixed',
        modifiers: [
            {
                name: 'arrow',
                options: { element: arrowElement, padding: 5 },
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 22],
                },
            },
        ],
    })

    useClickOutsideEffect(containerRef, open ? onClose : null)

    return (
        <>
            <MenuComponent innerRef={setReferenceElement} />

            <Popover
                isOpen={open}
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
            >
                {children}

                <Triangle ref={setArrowElement} style={styles.arrow} />
            </Popover>
        </>
    )
}
