import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
    display: flex;
    position: relative;

    background: var(--color-redlt1);
    border: 1px solid var(--color-redlt2);
    padding: 1em;
    border-radius: 3px;
    margin-bottom: 0.7em;

    &:before {
        font-family: 'Font Awesome 5 Pro', serif;
        font-weight: 900;
        font-size: 16px;
        content: '\\f071';
        margin-right: 5px;
        color: var(--color-shqred);
    }
`

type Props = {
    error?: AxiosException
}

/**
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
export default function NetworkError(props: Props) {
    const { error } = props
    let message = 'Server Error Occurred'

    const errors = error?.response?.data?.errors
    if (Array.isArray(errors)) {
        if (errors?.length) {
            message = errors.join(', ')
        }
    } else if (Array.isArray(errors?.base)) {
        message = errors?.base.join(', ')
    }

    return (
        <ErrorContainer>
            <span>Error: {message}</span>
        </ErrorContainer>
    )
}
