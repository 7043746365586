import React, { useState } from 'react'
import { Form } from 'react-final-form'
import StepFields from './StepFields'
import { useCreateShipMethod } from 'hooks/useShipMethods'
import { useNewTableRateField } from 'components/ui/form/NewTableRateField/Provider'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import useReloadAppData from 'hooks/useReloadAppData'
import { NewTableRateFlowContext } from './index'
import StepLayout from 'components/ui/form/components/StepLayout'

const FORM_CONFIG = 'shipping_method'

const ShippingMethodStep = () => {
    const reloadAppData = useReloadAppData()
    const [loading, setLoading] = useState(false)
    const { carrier, onClose, field } = useNewTableRateField()
    const { errors, createShipMethod } = useCreateShipMethod()
    const wizard = useWizardState({ context: NewTableRateFlowContext })

    const stepForm = field.stepForms.find((form) => form[FORM_CONFIG])[
        FORM_CONFIG
    ]

    const handleSubmit = ({ form, onSuccess }) => {
        const values = form.getState().values
        const variables = { ...values, carrier_id: carrier.id }

        setLoading(true)

        return createShipMethod(variables, {
            onSuccess: (data) => {
                reloadAppData(false, true)
                setLoading(false)
                onSuccess(data)
            },
            onError: () => {
                setLoading(false)
            },
        })
    }

    const handleContinue = ({ form }) => {
        handleSubmit({
            form,
            onSuccess: ({ data }) => {
                wizard.submitStep({
                    ...wizard.context,
                    shippingMethodId: data.id,
                })
            },
        })
    }

    const saveAndClose = ({ form }) => {
        handleSubmit({ form, onSuccess: onClose })
    }

    return (
        <StepLayout
            title="Create Table Rate"
            hint={field.hint}
            subTitle={`Step ${wizard.pagination.currentStep}: ${stepForm.title}`}
        >
            <Form onSubmit={() => {}} initialValues={{}}>
                {(form) => (
                    <>
                        <StepFields
                            form={form}
                            errors={errors}
                            formConfig={FORM_CONFIG}
                        />

                        <div className="form-col-1 align-center">
                            <button
                                disabled={loading}
                                type="button"
                                className="btn btn-primary mb-5"
                                aria-hidden="true"
                                onClick={() => handleContinue(form)}
                            >
                                Continue
                            </button>

                            <div>
                                <button
                                    disabled={loading}
                                    type="button"
                                    className="btn btn-text-primary"
                                    aria-hidden="true"
                                    onClick={() => saveAndClose(form)}
                                >
                                    Save and Close
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Form>
        </StepLayout>
    )
}

export default ShippingMethodStep
