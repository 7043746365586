import React, { useContext, createContext } from 'react'

interface FieldProps {
    title: string
    hint: string
    stepForms: any[]
    formSources: OptionSources
    values: any
}

interface NewTableRateProps {
    onClose: () => void
    onCompleted: () => void
    field: FieldProps
    carrier: any
}

interface NewTableRateProvider {
    onClose: () => void
    onCompleted: () => void
    carrier: any
    field: FieldProps
}

const NewTableRateContext = createContext<NewTableRateProvider>(null)

export const useNewTableRateField = () => useContext(NewTableRateContext)

export const NewTableRateProvider: React.FC<NewTableRateProps> = ({
    carrier,
    onClose,
    onCompleted,
    field,
    children,
}) => {
    return (
        <NewTableRateContext.Provider
            value={{
                carrier,
                onClose,
                onCompleted,
                field,
            }}
        >
            {children}
        </NewTableRateContext.Provider>
    )
}
