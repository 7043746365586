import React from 'react'
import { useDispatch } from 'react-redux'
import { reloadAppData } from 'reducers/modules/app'

export default function useReloadAppData() {
    const dispatch = useDispatch()

    const dispatchReloadAppData = (
        useCache = true,
        hideNetworkAction = false
    ) => {
        dispatch(reloadAppData(useCache, hideNetworkAction))
    }

    return dispatchReloadAppData
}
