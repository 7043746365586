import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { Form } from 'react-final-form'
import ErrorMessage from 'components/ui/form/ErrorMessage'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import DynamicForm from 'components/ui/form/DynamicForm'
import { ConditionType } from 'components/ui/form/ConditionRulesField/types'
import { FormRenderProps } from 'react-final-form'

import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import StepLayout from './StepLayout'

interface NewFormStepProps {
    title?: string
    children?: JSX.Element
    onBack?: any
    onSubmit: Function
    formConfig: string
    conditionType: ConditionType
}

interface NewFormProps extends NewFormStepProps {
    form: FormRenderProps
}

const NewForm = ({
    title,
    form,
    children,
    onBack,
    onSubmit,
    formConfig,
    conditionType,
}: NewFormProps) => {
    const { stepForms, formSources, isLoading } = useConditionRulesField()
    const { invalid, values } = form

    const stepFormConfig = stepForms.find(
        (formItem) => formItem.name === formConfig
    )

    return (
        <StepLayout
            conditionType={conditionType}
            disabled={isLoading || invalid}
            onBack={onBack}
            onContinue={() => onSubmit(values)}
        >
            <div className="entity-content">
                <div className="form-row mt-0 flex-start">
                    <div className="form-col-1">
                        <h3 className="blue-title align-left">
                            {title || stepFormConfig.step_title}
                        </h3>
                    </div>
                </div>

                <DynamicForm
                    classes={{
                        root: 'initial-align',
                        formCol: 'form-col-1',
                        formRow: 'form-row mt-0 flex-start',
                    }}
                    values={values}
                    tab={{ fields: [stepFormConfig] }}
                    optionSources={formSources}
                    onSubmit={form.handleSubmit}
                    enabledFeatures={[]}
                />

                {children}
            </div>
        </StepLayout>
    )
}

const NewFormStep = (props: NewFormStepProps) => {
    const conditionWizard = useWizardState({ context: ConditionFlowContext })

    return (
        <Form onSubmit={() => {}} initialValues={conditionWizard.context}>
            {(form) => <NewForm form={form} {...props} />}
        </Form>
    )
}

export default NewFormStep
