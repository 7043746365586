//
import React, { Component, Fragment } from 'react'
import { fieldId, FormGroup } from 'components/ui/form/renderers'
import Modal from 'components/ui/Modal'
import { axiosClient } from 'net/ajax'

/**
 * fieldDate Properties
 */

/**
 * fieldDate State
 */

/**
 */
export default class fieldAuthCode extends Component {
    state = {
        modalVisible: false,
        resetConfirmed: false,
        newCode: '',
    }

    constructor(props) {
        super(props)

        this.generateNewCode = this.generateNewCode.bind(this)
    }

    async generateNewCode() {
        const data = new FormData()
        data.append('ratesmgr_website[id]', this.props.values.id)

        const response = await axiosClient.request({
            url: '/ratesmgr/websites/generate_password',
            method: 'post',
            data,
        })

        const newCode =
            response && Array.isArray(response.data) ? response.data[0] : ''
        this.setState({
            resetConfirmed: true,
            newCode,
        })
    }

    render() {
        const {
            input,
            meta,
            title,
            hint,
            fullWidth,
            className,
            type,
            compact,
            oneLine,
            multiSelect,
            values: { id } = {},
        } = this.props

        const { modalVisible, resetConfirmed, newCode } = this.state

        return (
            <Fragment>
                {/* $FlowFixMe */}
                <FormGroup
                    name={input.name}
                    meta={meta}
                    label={title}
                    compact={compact}
                    fullWidth={fullWidth}
                    oneLine={oneLine}
                    className={className}
                >
                    {resetConfirmed ? (
                        <div className="alert alert-success">
                            <small>
                                Authentication code has been reset successfully
                            </small>
                        </div>
                    ) : (
                        <button
                            id={fieldId(input.name)}
                            className="btn btn-block btn-primary"
                            type="button"
                            onClick={() =>
                                this.setState({ modalVisible: true })
                            }
                            disabled={id === null}
                        >
                            Generate New Authentication Code
                        </button>
                    )}
                    {id === null ? (
                        <p className="help-block">
                            Note: Please save the website to generate
                            Authentication Code.
                        </p>
                    ) : (
                        <p className="help-block">
                            Note: this will reset your current Authentication
                            Code and will break existing connections until you
                            update.
                        </p>
                    )}
                </FormGroup>

                {modalVisible ? (
                    <Modal
                        title="Create Website Authentication Code"
                        onClose={() => this.setState({ modalVisible: false })}
                    >
                        {resetConfirmed ? (
                            <Fragment>
                                <p>
                                    Your new Authentication Code was generated
                                    and is shown below. Please save this Code as
                                    you will not be able to display it again.
                                </p>
                                <div
                                    className="alert alert-success"
                                    align="center"
                                >
                                    <strong>{newCode}</strong>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <p>
                                    Warning: This will reset your website's
                                    existing Authentication Code.
                                </p>
                                <button
                                    type="button"
                                    onClick={this.generateNewCode}
                                    className="btn btn-primary"
                                >
                                    Generate New Authentication Code
                                </button>
                            </Fragment>
                        )}
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}
