import styled from 'styled-components'

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Button = styled.div`
    cursor: pointer;
    padding: 11px 24px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 1px;
    background-color: ${({ theme }) => theme.colors.greyfa};
    border-radius: 26px;
    color: ${({ theme }) => theme.colors.grey3};
    display: flex;
    align-items: center;

    &:hover {
        background-color: ${({ theme }) => theme.colors.white};
    }
`

const Title = styled.div`
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey3};
    white-space: nowrap;
    margin: 0;
`

const Icon = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
    margin-right: 12px;

    i {
        font-size: 18px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.dkblue1};
    }
`

const LabelItem = styled.label`
    display: flex;
    margin-right: 14px;
    margin-bottom: 14px;

    &:nth-child(2n) {
        margin-right: 0px;
    }

    input {
        display: none;
    }

    input:checked ~ ${Button} {
        border: ${({ theme }) => `2px solid ${theme.colors.dkblue1}`};
        margin: 0;
        background-color: ${({ theme }) => theme.colors.white};
    }

    input[disabled] ~ ${Button} {
        cursor: auto;

        ${Title} {
            color: #bbbbbb;
        }

        i {
            color: #bbbbbb;
        }
    }
`

export const ShippingGoals = {
    List,
    Button,
    Title,
    Icon,
    LabelItem,
}
