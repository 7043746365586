import { useMutation } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'
import { actionTitles } from 'components/ui/form/RuleBuilderActionField/ActionSelector'

export const createQuickRule = ({
    responseType = 'render_entity',
    ...variables
}) =>
    axiosClient.post('/ratesmgr/quick_rules', {
        [responseType]: true,
        ratesmgr_quick_rule: { ...variables },
    })

export const updateQuickRule = ({
    id,
    responseType = 'render_entity',
    ...variables
}) =>
    axiosClient.put(`/ratesmgr/quick_rules/${id}`, {
        [responseType]: true,
        ratesmgr_quick_rule: { ...variables },
    })

export const prepareQuickRuleVariables = (variables) => {
    let name = actionTitles[variables.builder_action]

    const coupon_code = variables.coupon_code?.trim()

    return { ...variables, name, coupon_code: coupon_code }
}

export const useCreateQuickRule = () => {
    const props = useMutation(createQuickRule)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        createQuickRule: props.mutate,
    }
}

export const useUpdateQuickRule = () => {
    const props = useMutation(updateQuickRule)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        updateQuickRule: props.mutate,
        ...props,
    }
}
