import React, { useEffect } from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { CONDITIONS_ICONS } from 'components/ui/form/ConditionRulesField/ConditionsProvider'
import { ActionIcon } from 'components/ui/form/RuleBuilderActionField/ActionIcon'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import { ConditionType } from 'components/ui/form/ConditionRulesField/types'
import ErrorMessage from 'components/ui/form/ErrorMessage'

import { ConditionFlowContext } from './index'
import { StackFlowContext } from '../StackFlow'

interface StepLayoutProps {
    children: JSX.Element
    onContinue: () => void
    disabled?: boolean
    conditionType: ConditionType
    onBack?: React.MouseEventHandler<HTMLButtonElement>
    footer?: () => JSX.Element
}

const StepLayout = ({
    children,
    onContinue,
    disabled,
    onBack,
    conditionType,
    footer: Footer,
}: StepLayoutProps) => {
    const stackWizard = React.useContext(StackFlowContext)
        ? useWizardState({ context: StackFlowContext })
        : null

    const wizard = useWizardState({ context: ConditionFlowContext })

    const { isLoading, errorMessage, setErrors, values } =
        useConditionRulesField()

    const actionType = values.builder_action
    const prevStep = onBack || wizard.prevStep || stackWizard?.prevStep

    const handleContinue = () => {
        onContinue && onContinue()
    }

    useEffect(() => {
        setErrors({})
    }, [wizard.currentStep])

    const paginationSteps = wizard.steps.filter(
        (step) => step !== 'shippingGroupPreventRule'
    )

    const totalCount = paginationSteps.length

    const currentStepIndex =
        wizard.currentStep === 'shippingGroupPreventRule'
            ? paginationSteps.indexOf('shippingGroupSelect')
            : paginationSteps.indexOf(wizard.currentStep)

    return (
        <div>
            {conditionType && (
                <div className="c-message-icons-pair">
                    <div className="c-message-icon c-message-icon--main">
                        {actionType && <ActionIcon action={actionType} />}
                    </div>

                    <div className="c-message-icon c-message-icon--sub">
                        <i
                            className={`fa ${CONDITIONS_ICONS[conditionType]}`}
                        />
                    </div>
                </div>
            )}
            <div className="condition-modal-content c-message-icons-pair-content mb-6">
                {children}

                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </div>

            {Footer && <Footer />}
            {prevStep && (
                <button
                    data-testid="modal-close-button"
                    type="button"
                    className="back"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={prevStep}
                />
            )}

            <button
                disabled={disabled || isLoading}
                type="button"
                className="btn btn-primary mb-4"
                aria-hidden="true"
                onClick={handleContinue}
            >
                Continue
            </button>

            <p className="pagination-hint mb-0">
                {`Condition ${currentStepIndex + 1} of ${totalCount}`}
            </p>
        </div>
    )
}

export default StepLayout
