//
import React, { Fragment, useCallback, useState } from 'react'
import { FormGroup } from 'components/ui/form/renderers'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'
import Loader from 'components/Loader'
import { axiosClient } from 'net/ajax'
import { prepareFormData } from 'utils/formData'
import { showNotification } from 'reducers/modules/app'
import { connect } from 'react-redux'

/**
 * fieldDate Properties
 */

/**
 */
function fieldCsvUploader(props) {
    const {
        input,
        meta,
        title,
        hint,
        fullWidth,
        className,
        compact,
        oneLine,
        sampleFile,
        sampleText,
        postButton,
        postUrl,
        showNotification,
    } = props
    const [fileName, setFileName] = useState(null)
    const [error, setError] = useState(null)
    const [isUploading, setUploading] = useState(false)
    const [fileObject, setFileObject] = useState(null)

    const metaCopy = { ...meta }
    if (!metaCopy.error && error) {
        metaCopy.error = error
        metaCopy.touched = true
    }

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const firstFile = acceptedFiles[0]
            const error = validateCsvFile(firstFile)
            if (!error) {
                if (!postButton || !postButton.length) {
                    input.onChange(firstFile)
                } else {
                    setFileObject(firstFile)
                }
                setFileName(firstFile.name)
            } else {
                reset()
            }
            setError(error)
        }
    }, [])

    /**
     * Deselects any selected files
     * @type {function((Event|null)=): boolean}
     */
    const reset = useCallback((e = null) => {
        e && e.preventDefault()
        input.onChange(null)
        input.onChange(undefined)
        setFileName(null)
        setFileObject(null)
        return false
    }, [])

    /**
     * Post file to the specified url
     */
    const doCustomUpload = useCallback(
        async (e) => {
            e.preventDefault()

            setUploading(true)

            try {
                const data = prepareFormData(
                    {
                        catalog: fileObject,
                    },
                    null,
                    'ratesmgr_hurricane'
                )

                const result = await axiosClient.post(postUrl, data)
                if (result) {
                    const { status, headers } = result
                    if (status === 200) {
                        if (headers && headers['x-message']) {
                            showNotification(
                                headers['x-message'],
                                headers['x-message-type']
                            )
                        }
                    }
                }

                setUploading(false)
                reset()
            } catch (e) {
                console.error(e)

                showNotification(
                    'Error occurred while uploading the catalog. Please try again later or contact support.'
                )

                setUploading(false)
            }

            return false
        },
        [fileObject]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    return (
        <Fragment>
            {/* $FlowFixMe */}
            <FormGroup
                meta={metaCopy}
                label={title}
                hint={hint}
                compact={compact}
                fullWidth={fullWidth}
                oneLine={oneLine}
                className={cx(className, 'csv-file-selector')}
            >
                <a {...getRootProps()} className="btn btn-bl-o csv-upload">
                    <i className="far fa-file-upload"></i> Upload CSV
                    <input {...getInputProps()} />
                </a>
                {fileName ? (
                    <Fragment>
                        <span className="selected-file">
                            Selected file: {fileName}
                            <a className="reset" href="#" onClick={reset}>
                                <i className="far fa-redo"></i> Reset
                            </a>
                        </span>

                        {postButton ? (
                            <div className="post-button">
                                {isUploading ? <Loader /> : null}
                                <button
                                    onClick={doCustomUpload}
                                    className="btn btn-primary"
                                    disabled={isUploading}
                                >
                                    {postButton}
                                </button>
                            </div>
                        ) : null}
                    </Fragment>
                ) : null}
            </FormGroup>

            {sampleFile ? (
                <p className="help-block csv-format">
                    <a href={sampleFile}>
                        {sampleText ? sampleText : 'Download CSV format'}
                    </a>
                </p>
            ) : null}
        </Fragment>
    )
}

function validateCsvFile(file) {
    if (file.type.indexOf('csv') === -1) {
        return 'Only CSV files are supported. Please select a CSV file'
    }

    return false
}

export default connect(null, {
    showNotification,
})(fieldCsvUploader)
