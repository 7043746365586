//

function addValue(formData, baseName, key, value) {
    if (Array.isArray(value)) {
        if (value.length === 0) {
            formData.append(baseName + '[' + key + '][]', '')
        } else {
            for (const idx in value) {
                if (!value.hasOwnProperty(idx)) {
                    continue
                }

                const itemValue = value[idx]
                if (Array.isArray(itemValue)) {
                    addValue(formData, baseName, key, itemValue)
                } else if (typeof itemValue === 'object') {
                    let targetIdx = idx
                    if (itemValue._overrideIdx) {
                        targetIdx = itemValue._overrideIdx
                        delete itemValue._overrideIdx
                    }

                    for (const [objKey, objValue] of Object.entries(
                        itemValue
                    )) {
                        if (objKey === 'id' && Number(objValue) <= 0) {
                            continue
                        }

                        formData.append(
                            baseName +
                                '[' +
                                key +
                                '][' +
                                targetIdx +
                                '][' +
                                objKey +
                                ']',
                            objValue === null ? '' : String(objValue)
                        )
                    }
                } else {
                    if (key.indexOf('__') !== -1) {
                        key = key.replace(/__/g, '][')
                        const formKey = baseName + '[' + key + ']'
                        if (formData.has(formKey)) {
                            formData.delete(formKey)
                        }
                        formData.append(formKey, String(itemValue))
                    } else {
                        if (typeof itemValue === 'boolean') {
                            formData.append(
                                baseName + '[' + key + ']',
                                String(itemValue)
                            )
                        } else {
                            formData.append(
                                baseName + '[' + key + '][]',
                                String(itemValue)
                            )
                        }
                    }
                }
            }
        }
    } else {
        let strValue =
            value === undefined || value === null ? '' : String(value).trim()

        let formKey

        if (strValue === '[true]') {
            strValue = '1'
        }

        if (key.indexOf('__') !== -1) {
            key = key.replace(/__/g, '][')
            formKey = baseName + '[' + key + ']'
            if (formData.has(formKey)) {
                formData.delete(formKey)
            }
        } else {
            formKey = baseName + '[' + key + ']'
        }

        if (strValue === '[object File]') {
            formData.append(formKey, value)
        } else {
            formData.append(formKey, strValue)
        }
    }
}

/**
 * This function is used to prepare a FormData object for submitting to the backend
 * @param data
 * @param namespaceName
 * @param baseName
 * @param method
 * @returns {FormData}
 */
export function prepareFormData(
    data,
    namespaceName = null,
    baseNameOriginal = 'ratesmgr_user_setting',
    method = 'patch'
) {
    const formData = new FormData()

    if (method) {
        formData.append('_method', method)
    }

    const baseName = namespaceName
        ? `${baseNameOriginal}[${namespaceName}]`
        : baseNameOriginal

    const keys = new Set(Object.keys(data))
    for (const [key, value] of Object.entries(data)) {
        switch (true) {
            case key === 'id':
                break
            // should this field/entry be skipped? - we won't send it to backend
            case key.indexOf('_skip_') === 0 ||
                key.indexOf('_dynamic_') === 0 ||
                keys.has(`_skip_${key}`):
                break
            default:
                addValue(formData, baseName, key, value)
        }
    }

    return formData
}
