import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import chunk from 'lodash/chunk'
import { NewRuleFlowContext } from './index'
import { RuleBuilderAction } from 'components/ui/form/RuleBuilderActionField/types'
import { ActionIcon } from 'components/ui/form/RuleBuilderActionField/ActionIcon'
import { ActionInput } from 'components/ui/form/components/ActionInput'
import { actionTitles } from 'components/ui/form/RuleBuilderActionField/ActionSelector'
import { useNewQuickRule } from '../Provider'

const SelectConditionStep: React.FC = () => {
    const { selectedAction, setRuleAction } = useNewQuickRule()
    const wizard = useWizardState({ context: NewRuleFlowContext })

    const onChangeAction = (actionType: RuleBuilderAction) => {
        setRuleAction(actionType)

        if (actionType === 'HIDE_METHODS') {
            wizard.skipStep('actionForm')
        } else {
            wizard.submitStep()
        }
    }

    return (
        <div className="condition-form">
            <h2 className="title">
                <strong>I want to...</strong>
            </h2>

            <p className="description mb-5">
                Select the action you would like to perform
            </p>

            <div className="mb-10">
                {chunk(Object.keys(actionTitles), 2).map(
                    (actionTypes: string[], index: number) => (
                        <div key={`action-section-${index}`} className="row">
                            {actionTypes.map(
                                (actionType: RuleBuilderAction) => (
                                    <div
                                        key={`action-input-${actionType}`}
                                        className="col-xs-6 mb-5"
                                    >
                                        <ActionInput
                                            variant="blue"
                                            id={actionType}
                                            title={actionTitles[actionType]}
                                            checked={
                                                selectedAction === actionType
                                            }
                                            name="actionType"
                                            onChange={() =>
                                                onChangeAction(actionType)
                                            }
                                            icon={
                                                <ActionIcon
                                                    action={actionType}
                                                    selected
                                                />
                                            }
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default SelectConditionStep
