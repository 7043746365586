import React, { useContext, createContext, useEffect } from 'react'
import { useSetRuleActionType } from 'components/ui/form/hooks/useSetRuleActionType'
import { FormApi } from 'final-form'
import { RuleBuilderAction } from 'components/ui/form/RuleBuilderActionField/types'

const NewQuickRuleContext = createContext(null)

export const useNewQuickRule = () => useContext(NewQuickRuleContext)

interface NewQuickRuleProviderProps {
    open: boolean
    onClose: React.MouseEventHandler
    onCompleted: () => void
    onSkip?: () => void
    form: FormApi
    values: any
    actions: unknown[]
    conditions: unknown[]
    shippingMethodForm: any
    ruleAction?: RuleBuilderAction
    children?: JSX.Element
}

export const NewQuickRuleProvider = ({
    open,
    form,
    values,
    onClose,
    onCompleted,
    actions,
    conditions,
    shippingMethodForm,
    ruleAction,
    children,
}: NewQuickRuleProviderProps) => {
    const { selectedAction, setRuleAction } = useSetRuleActionType({ form })

    const resetForm = () => {
        form.reset()
        setRuleAction(ruleAction)
    }

    useEffect(() => {
        resetForm()
    }, [open])

    return (
        <NewQuickRuleContext.Provider
            value={{
                form,
                values,
                onClose,
                onCompleted,
                actions,
                conditions,
                shippingMethodForm,
                selectedAction,
                setRuleAction,
                resetForm,
                ruleAction,
            }}
        >
            {children}
        </NewQuickRuleContext.Provider>
    )
}
