import React, { useEffect } from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { Form, FormRenderProps } from 'react-final-form'
import { useConditionsSources } from 'hooks/useConditionsSources'
import EntitiesSelect from 'components/ui/form/EntitiesSelect'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import { toTitleCase } from 'utils/string'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import { ConditionType } from 'components/ui/form/ConditionRulesField/types'

import StepLayout from '../StepLayout'

interface SelectFormProps {
    form: FormRenderProps
    conditionType: ConditionType
    toggleMode: () => void
}

const SelectForm = ({ form, conditionType, toggleMode }: SelectFormProps) => {
    const wizard = useWizardState({ context: ConditionFlowContext })
    const { actionTitle } = useConditionRulesField()
    const { invalid, values } = form
    const conditionLabel = toTitleCase(conditionType)
    const fieldName = `shipFilterIds${conditionLabel}`
    const shipFilterIds = values[fieldName]

    const {
        shipFilters: { shipFilters, isLoading },
    } = useConditionsSources()

    const conditionShipFilters = shipFilters?.filter(
        (item) => item[`filter_range_${conditionType}`] === 'range'
    )

    const handleContinue = () => wizard.submitStep()

    useEffect(() => {
        wizard.setContext({
            ...wizard.context,
            ...values,
        })
    }, [shipFilterIds])

    const handleToggleMode = () => {
        wizard.setContext((prev) => ({ ...prev, ...values }))
        toggleMode()
    }

    return (
        <StepLayout
            conditionType={conditionType}
            disabled={invalid}
            onBack={toggleMode}
            onContinue={handleContinue}
        >
            <>
                <h3 className="blue-title align-left">
                    {`${actionTitle} when...`}
                </h3>

                <div className="initial-align">
                    <EntitiesSelect
                        multiSelect
                        searchable
                        entities={conditionShipFilters}
                        disabled={isLoading}
                        name={fieldName}
                        title={`Choose ${conditionLabel} Filter`}
                    />
                </div>

                <p onClick={handleToggleMode} className="mb-0 primary-link">
                    {`I want to create a new ${conditionLabel} Filter`}
                </p>
            </>
        </StepLayout>
    )
}

const SelectFormContainer = (props) => {
    const conditionWizard = useWizardState({ context: ConditionFlowContext })

    return (
        <Form onSubmit={() => {}} initialValues={conditionWizard.context}>
            {(form) => <SelectForm form={form} {...props} />}
        </Form>
    )
}

export default SelectFormContainer
