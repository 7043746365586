import resolveFields from 'components/ui/form/fieldResolver'

export default function prepareDataToDisplay(
    tab,
    data,
    optionSources,
    changed
) {
    let result = data

    if (!result) {
        result = {}
    }

    if (changed || !tab.fields) {
        return result
    }

    const fieldList = Object.values(resolveFields(tab))
    for (const field of fieldList) {
        if (
            field.type === 'number' &&
            field.defaultValue &&
            !result[field.name]
        ) {
            result[field.name] = field.defaultValue
        } else if (
            field.type === 'association' &&
            field.forceSelect &&
            !result[field.name]
        ) {
            const optionSource = optionSources[field.option_source]
            if (Array.isArray(optionSource) && optionSource.length > 0) {
                result[field.name] = optionSource[0].value
            }
        }
    }

    return result
}
