import { useState, useEffect } from 'react'
import copyToClipboard from 'copy-to-clipboard'

export default function useCopy(initialValueToCopy = '') {
    const [copied, setCopied] = useState(false)
    const [valueToCopy, setValueToCopy] = useState(initialValueToCopy)

    function copy() {
        copyToClipboard(valueToCopy)
        setCopied(true)
    }

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 2500)
        }
    }, [copied])

    return {
        copied,
        copy,
        setValueToCopy,
    }
}
