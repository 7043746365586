import { useMutation, useQuery } from 'react-query'
import { axiosQuery as axiosClient } from 'net/ajax'

const getShipGroups = () => {
    return axiosClient
        .get(`/ratesmgr/criteria/shipping_groups`)
        .then(({ data }) => data)
}

export const SHIP_GROUP_RULES = [
    { id: 'all', name: 'All selected Shipping Groups are in the cart' },
    { id: 'any', name: 'Any selected Shipping Groups are in the cart' },
]

export const useGetShipGroups = () => {
    const { data: shipGroups = [], ...rest } = useQuery(
        ['shipping_groups'],
        getShipGroups
    )

    return { shipGroups, ...rest }
}

export const createShipGroup = (variables) =>
    axiosClient.post('/ratesmgr/criteria/shipping_groups', {
        render_entity: true,
        ratesmgr_criteria_shipping_group: variables,
    })

export const useCreateShipGroup = () => {
    const props = useMutation(createShipGroup)

    return {
        isSuccess: props['isSuccess'],
        isLoading: props['isLoading'],
        errors: props.error,
        isError: props['isError'],
        createShipGroup: props.mutate,
    }
}
