import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { ConditionFlowContext } from 'components/ui/form/ConditionRulesField/ConditionFlow'
import { useConditionsSources } from 'hooks/useConditionsSources'
import { ConditionType } from 'components/ui/form/ConditionRulesField/types'
import {
    useCreateShipFilter,
    prepareShipFilterParams,
} from 'hooks/useShipFilters'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import NewFormStep from 'components/ui/form/ConditionRulesField/ConditionFlow/NewFormStep'
import SelectForm from './SelectForm'
import { toTitleCase } from 'utils/string'

const FORMS = {
    qty: 'new_qty_filter_form',
    price: 'new_price_filter_form',
    weight: 'new_weight_filter_form',
    volume: 'new_volume_filter_form',
}

interface NewFormProps {
    toggleMode: any
    conditionType: ConditionType
}

const NewForm = ({ toggleMode, conditionType }: NewFormProps) => {
    const wizard = useWizardState({ context: ConditionFlowContext })
    const { createShipFilter } = useCreateShipFilter()
    const { shipFilters } = useConditionsSources()
    const { actionTitle, setErrors, setLoading } = useConditionRulesField()
    const fieldName = `shipFilterIds${toTitleCase(conditionType)}`

    const handleSubmit = (values) => {
        setLoading(true)

        const variables = prepareShipFilterParams(conditionType, values)

        createShipFilter(
            { ...variables, check_duplicates: true },
            {
                onSuccess: ({ data }: { data: any }) => {
                    shipFilters.refetch()

                    wizard.setContext((prev) => ({
                        ...prev,
                        [fieldName]: [...(prev[fieldName] || []), data.id],
                    }))

                    toggleMode()
                    setLoading(false)
                },
                onError: ({ errors }: any) => {
                    setErrors(errors)
                    setLoading(false)
                },
            }
        )
    }

    return (
        <NewFormStep
            conditionType={conditionType}
            title={`${actionTitle} when...`}
            formConfig={FORMS[conditionType]}
            onSubmit={handleSubmit}
        >
            <p onClick={toggleMode} className="mb-0 primary-link">
                I want to apply an existing Filter
            </p>
        </NewFormStep>
    )
}

const SelectStep = (conditionType: ConditionType) => () => {
    const fieldName = `shipFilterIds${toTitleCase(conditionType)}`
    const wizard = useWizardState({ context: ConditionFlowContext })
    const [selectMode, setSelectMode] = useState(
        !isEmpty(wizard.context[fieldName])
    )

    const toggleMode = () => setSelectMode((prev) => !prev)

    return selectMode ? (
        <SelectForm conditionType={conditionType} toggleMode={toggleMode} />
    ) : (
        <NewForm conditionType={conditionType} toggleMode={toggleMode} />
    )
}

export default SelectStep
