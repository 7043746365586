import React from 'react'
import { Form } from 'react-final-form'
import { useSaveTableRate } from 'hooks/useTableRates'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { useNewTableRateField } from 'components/ui/form/NewTableRateField/Provider'
import StepLayout from 'components/ui/form/components/StepLayout'
import { useSelector } from 'react-redux'

import { NewTableRateFlowContext } from './index'
import StepFields from './StepFields'

const FORM_CONFIG = 'table_rate'

const TableRateStep = () => {
    const { carrier, onClose, field } = useNewTableRateField()
    const data = useSelector(({ app: data = {} }: any) => data)

    const tableRateId =
        field.values.id === null || field.values.id < 0 ? null : field.values.id

    const isUpdate = !!tableRateId

    const { errors, saveTableRate } = useSaveTableRate({ id: tableRateId })
    const wizard = useWizardState({ context: NewTableRateFlowContext })

    const stepForm = field.stepForms.find((form) => form[FORM_CONFIG])[
        FORM_CONFIG
    ]

    const handleSubmit = (values) => {
        const variables = { ...values, carrier_id: carrier.id }

        return saveTableRate(variables, {
            onSuccess: ({ data }: { data: any }) => {
                wizard.submitStep({ entityId: data.id, update: isUpdate })
            },
        })
    }

    const formInitialValues = {
        shipping_method_id: wizard.context.shippingMethodId,
        ...data.entity.data.table_rates_values,
        ...field.values,
    }

    const stepSubTitle = `Step ${wizard.pagination.currentStep}: ${stepForm.title}`

    return (
        <StepLayout
            title={isUpdate ? 'Update Table Rate' : 'Create Table Rate'}
            hint={isUpdate ? null : field.hint}
            subTitle={
                isUpdate || wizard.pagination.totalCount <= 1
                    ? null
                    : stepSubTitle
            }
        >
            <Form onSubmit={handleSubmit} initialValues={formInitialValues}>
                {(form) => (
                    <>
                        <StepFields
                            form={form}
                            errors={errors}
                            formConfig={FORM_CONFIG}
                            classes={{
                                root: 'ent-cont-section empty marginX-0',
                            }}
                        />

                        <div className="align-center">
                            <button
                                disabled={form.submitting}
                                type="submit"
                                className="btn btn-primary mb-5"
                                aria-hidden="true"
                                onClick={form.handleSubmit}
                            >
                                {isUpdate ? 'Update' : 'Continue'}
                            </button>

                            <div>
                                <button
                                    disabled={form.submitting}
                                    type="button"
                                    className="btn btn-text-primary"
                                    aria-hidden="true"
                                    onClick={onClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Form>
        </StepLayout>
    )
}

export default TableRateStep
