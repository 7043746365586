import React from 'react'
import { CONDITIONS_ICONS } from 'components/ui/form/ConditionRulesField/ConditionsProvider'

interface ConditionCardProps {
    className: string
    hidden: boolean
    onRemove: React.MouseEventHandler<HTMLParagraphElement>
    onEdit: React.MouseEventHandler<HTMLParagraphElement>
    title: string
    icon?: string
    children: JSX.Element
}

const ConditionCard = ({
    className,
    hidden = false,
    onRemove,
    onEdit,
    title,
    icon,
    children,
}: ConditionCardProps) => {
    if (hidden) return <></>

    return (
        <div className={`condition-card ${className}`}>
            <div className="flex space-between mb-5">
                <div className="flex">
                    {icon && (
                        <div className="circle-icon white mr-5">
                            <i className={`fa ${CONDITIONS_ICONS[icon]}`} />
                        </div>
                    )}

                    <p className="condition-card--title mb-0">{title}</p>
                </div>

                <div className="flex btn-pairs">
                    <p
                        className="btn btn-default condition-card--btn mb-0 btn-left"
                        onClick={onEdit}
                    >
                        <i className="fa fa-pen mr-5" />
                        Edit
                    </p>

                    <p
                        className="btn btn-default condition-card--btn remove mb-0 mr-0 btn-right"
                        onClick={onRemove}
                    >
                        <i className="fa fa-trash mr-5" />
                        Remove
                    </p>
                </div>
            </div>

            {children}
        </div>
    )
}

export default ConditionCard
