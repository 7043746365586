import React, { useState, useContext, useEffect } from 'react'
import Modal from 'components/ui/Modal'
import StackFlow from './StackFlow'
import DynamicFormContext from 'components/ui/form/DynamicFormContext'
import EntitiesSelect from 'components/ui/form/EntitiesSelect'
import ConditionCard from './ConditionCard'
import BigcommerceTaxMessage from 'components/dashboard/bigcommerce/BigcommerceTaxMessage'
import { useSelector } from 'react-redux'
import {
    ConditionsSourcesProvider,
    useConditionsSources,
} from 'hooks/useConditionsSources'
import { ConditionRulesProvider } from './Provider'
import ConditionFlow from './ConditionFlow'
import CouponInput from './ConditionFlow/CouponCode/CouponInput'
import uniq from 'lodash/uniq'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import { toTitleCase } from 'utils/string'

type StepFormConfig = any

const ConditionRulesField = ({ stepForms }: { stepForms: StepFormConfig }) => {
    const { form, values }: any = useContext(DynamicFormContext)
    const { option_sources } = useSelector((state: any) => state.app.entity)
    const [addDialog, setAddDialog] = useState(false)
    const [editFields, setEditFields] = useState([])

    const toggleAddDialog = () => setAddDialog((prev) => !prev)
    const closeEditDialog = () => setEditFields(null)

    const handleEditFields = (conditions) => () => setEditFields(conditions)

    const {
        shipFilters: { shipFilters },
        zones: { zones },
        shipGroups: { shipGroups },
        customerGroups: { customerGroups },
    } = useConditionsSources()

    const {
        ship_filter_ids_price,
        ship_filter_ids_qty,
        ship_filter_ids_weight,
        ship_filter_ids_volume,
        zone_ids,
        exclude_zone_ids,
        shipping_group_ids,
        any_shipping_group_ids,
        excl_shipping_group_ids,
        customer_group_ids,
        coupon_code,
    } = values

    const shipFiltersPrice = shipFilters.filter(
        (item) => item.filter_range_price === 'range'
    )
    const shipFiltersWeight = shipFilters.filter(
        (item) => item.filter_range_weight === 'range'
    )

    const shipFiltersVolume = shipFilters.filter(
        (item) => item.filter_range_volume === 'range'
    )

    const shipFiltersQty = shipFilters.filter(
        (item) => item.filter_range_qty === 'range'
    )

    const handleSkip = (_values, { onSuccess }) => {
        onSuccess()
        toggleAddDialog()
    }

    const handleCompletedAddDialog = (submitValues, { onSuccess }) => {
        const { conditionField = null } = submitValues

        const formValues = conditionField
            ? applyAddCondition({ form, values, conditionField })
            : form.getState().values

        onSuccess(formValues)

        toggleAddDialog()
    }

    const handleCompletedEditDialog = ({ conditionField }) => {
        const values = {}

        applyCleanCondition({ form, fields: editFields })
        applyAddCondition({ form, values, conditionField })

        closeEditDialog()
    }

    const handleCleanValues = (names) => () => {
        names.forEach((name) => {
            form.change(name, [])
        })
    }

    const handleCleanValue = (name) => () => {
        form.change(name, null)
    }

    const filterIds = [
        ship_filter_ids_price,
        ship_filter_ids_qty,
        ship_filter_ids_weight,
        ship_filter_ids_volume,
    ]

    useEffect(() => {
        form.change('ship_filter_ids', uniq(compact(flatten(filterIds))))
    }, [...filterIds])

    return (
        <>
            <ConditionCard
                icon="price"
                hidden={isEmpty(ship_filter_ids_price)}
                onRemove={handleCleanValues(['ship_filter_ids_price'])}
                onEdit={handleEditFields(['ship_filter_ids_price'])}
                className="mb-5"
                title="Price filter:"
            >
                <>
                    <EntitiesSelect
                        name="ship_filter_ids_price"
                        multiSelect
                        searchable
                        entities={shipFiltersPrice}
                        title="Select Price Filter"
                    />

                    <BigcommerceTaxMessage className="mb-2" />
                </>
            </ConditionCard>

            <ConditionCard
                icon="weight"
                hidden={isEmpty(ship_filter_ids_weight)}
                onRemove={handleCleanValues(['ship_filter_ids_weight'])}
                onEdit={handleEditFields(['ship_filter_ids_weight'])}
                className="mb-5"
                title="Weight filter:"
            >
                <EntitiesSelect
                    name="ship_filter_ids_weight"
                    multiSelect
                    searchable
                    entities={shipFiltersWeight}
                    title="Select Weight Filter"
                />
            </ConditionCard>

            <ConditionCard
                icon="volume"
                hidden={isEmpty(ship_filter_ids_volume)}
                onRemove={handleCleanValues(['ship_filter_ids_volume'])}
                onEdit={handleEditFields(['ship_filter_ids_volume'])}
                className="mb-5"
                title="Volume filter:"
            >
                <EntitiesSelect
                    name="ship_filter_ids_volume"
                    multiSelect
                    searchable
                    entities={shipFiltersVolume}
                    title="Select Volume Filter"
                />
            </ConditionCard>

            <ConditionCard
                icon="qty"
                hidden={isEmpty(ship_filter_ids_qty)}
                onRemove={handleCleanValues(['ship_filter_ids_qty'])}
                onEdit={handleEditFields(['ship_filter_ids_qty'])}
                className="mb-5"
                title="Quantity filter:"
            >
                <EntitiesSelect
                    name="ship_filter_ids_qty"
                    multiSelect
                    searchable
                    entities={shipFiltersQty}
                    title="Select Quantity Filter"
                />
            </ConditionCard>

            <ConditionCard
                icon="shippingZones"
                hidden={isEmpty(zone_ids)}
                onRemove={handleCleanValues(['zone_ids'])}
                onEdit={handleEditFields(['zone_ids'])}
                className="mb-5"
                title="Shipping Zones:"
            >
                <EntitiesSelect
                    name="zone_ids"
                    multiSelect
                    searchable
                    entities={zones}
                    title="Shipping Zones Include"
                />
            </ConditionCard>

            <ConditionCard
                icon="shippingZones"
                hidden={isEmpty(exclude_zone_ids)}
                onRemove={handleCleanValues(['exclude_zone_ids'])}
                onEdit={handleEditFields(['exclude_zone_ids'])}
                className="mb-5"
                title="Shipping Zones:"
            >
                <EntitiesSelect
                    name="exclude_zone_ids"
                    multiSelect
                    searchable
                    entities={zones}
                    title="Shipping Zones Do Not Include"
                />
            </ConditionCard>

            <ConditionCard
                icon="shippingGroups"
                hidden={isEmpty(shipping_group_ids)}
                onRemove={handleCleanValues(['shipping_group_ids'])}
                onEdit={handleEditFields(['shipping_group_ids'])}
                className="mb-5"
                title="Shipping Groups:"
            >
                <EntitiesSelect
                    hidden={isEmpty(shipping_group_ids)}
                    name="shipping_group_ids"
                    multiSelect
                    searchable
                    entities={shipGroups}
                    title="All selected Shipping Groups are in the cart"
                />
            </ConditionCard>

            <ConditionCard
                icon="shippingGroups"
                hidden={
                    isEmpty(any_shipping_group_ids) &&
                    isEmpty(excl_shipping_group_ids)
                }
                onRemove={handleCleanValues([
                    'any_shipping_group_ids',
                    'excl_shipping_group_ids',
                ])}
                onEdit={handleEditFields(['any_shipping_group_ids'])}
                className="mb-5"
                title="Shipping Groups:"
            >
                <>
                    <EntitiesSelect
                        hidden={isEmpty(any_shipping_group_ids)}
                        name="any_shipping_group_ids"
                        multiSelect
                        searchable
                        entities={shipGroups}
                        title="Any selected Shipping Groups are in the cart"
                    />

                    <EntitiesSelect
                        hidden={isEmpty(excl_shipping_group_ids)}
                        name="excl_shipping_group_ids"
                        multiSelect
                        searchable
                        entities={shipGroups}
                        title="Shipping Groups that prevent rule from applying"
                    />
                </>
            </ConditionCard>

            <ConditionCard
                icon="customerGroups"
                hidden={isEmpty(customer_group_ids)}
                onRemove={handleCleanValues(['customer_group_ids'])}
                onEdit={handleEditFields(['customer_group_ids'])}
                className="mb-5"
                title="Customer Groups:"
            >
                <EntitiesSelect
                    name="customer_group_ids"
                    multiSelect
                    searchable
                    entities={customerGroups}
                    title="Any selected Customer Groups are in the cart"
                />
            </ConditionCard>

            <ConditionCard
                icon="couponCode"
                hidden={isEmpty(coupon_code)}
                onRemove={handleCleanValue('coupon_code')}
                onEdit={handleEditFields(['coupon_code'])}
                className="mb-20"
                title="Coupon Code:"
            >
                <div style={{ maxWidth: 300 }}>
                    <CouponInput name="coupon_code" />
                </div>
            </ConditionCard>

            <div
                id="openConditionModal"
                className="blue-text-button mb-0"
                onClick={toggleAddDialog}
            >
                <div className="circle-icon blue mr-2">
                    <i className="fa fa-plus" />
                </div>
                <p className="text">Add another condition</p>
            </div>

            {!isEmpty(editFields) && (
                <ConditionRulesProvider
                    onClose={closeEditDialog}
                    onSkip={handleSkip}
                    onCompleted={handleCompletedEditDialog}
                    stepForms={stepForms}
                    values={values}
                    formSources={option_sources}
                >
                    <Modal
                        id="conditionModal"
                        onClose={closeEditDialog}
                        className="modal-content add-carrier"
                        modalName="add-new-carrier"
                    >
                        <ConditionFlow
                            context={editValuesMapper(values, editFields)}
                            selectedConditions={editFieldsMapper(editFields)}
                            onCompleted={(conditionField) =>
                                handleCompletedEditDialog({ conditionField })
                            }
                        />
                    </Modal>
                </ConditionRulesProvider>
            )}

            {addDialog && (
                <ConditionRulesProvider
                    multipleConditions
                    onClose={toggleAddDialog}
                    onSkip={handleSkip}
                    onCompleted={handleCompletedAddDialog}
                    stepForms={stepForms}
                    values={values}
                    formSources={option_sources}
                >
                    <Modal
                        id="conditionModal"
                        onClose={toggleAddDialog}
                        className="modal-content add-carrier"
                        modalName="add-new-carrier"
                    >
                        <StackFlow />
                    </Modal>
                </ConditionRulesProvider>
            )}
        </>
    )
}

const ConditionRulesFieldContainer = ({
    stepForms,
}: {
    stepForms: StepFormConfig
}) => (
    <ConditionsSourcesProvider>
        <ConditionRulesField stepForms={stepForms} />
    </ConditionsSourcesProvider>
)

const editFieldsMapper = (editFields) => {
    const fieldStepsMapper = {
        ship_filter_ids_price: 'price',
        ship_filter_ids_qty: 'qty',
        ship_filter_ids_weight: 'weight',
        ship_filter_ids_volume: 'volume',
        zone_ids: 'shippingZones',
        exclude_zone_ids: 'shippingZones',
        shipping_group_ids: 'shippingGroups',
        any_shipping_group_ids: 'shippingGroups',
        excl_shipping_group_ids: 'shippingGroups',
        customer_group_ids: 'customerGroups',
        coupon_code: 'couponCode',
    }

    return editFields.map((field) => fieldStepsMapper[field])
}

const editValuesMapper = (values, editFields) => {
    const {
        ship_filter_ids_price,
        ship_filter_ids_qty,
        ship_filter_ids_weight,
        ship_filter_ids_volume,
        zone_ids,
        exclude_zone_ids,
        shipping_group_ids,
        any_shipping_group_ids,
        excl_shipping_group_ids,
        customer_group_ids,
        coupon_code,
    } = values

    const exludeZone = editFields.includes('exclude_zone_ids')
    const anyShippingGroup = editFields.includes('any_shipping_group_ids')

    return {
        zoneType: exludeZone ? 'exclude' : 'include',
        zoneIds: exludeZone ? exclude_zone_ids : zone_ids,

        shipFilterIdsPrice: ship_filter_ids_price,
        shipFilterIdsQty: ship_filter_ids_qty,
        shipFilterIdsWeight: ship_filter_ids_weight,
        shipFilterIdsVolume: ship_filter_ids_volume,

        customerGroupIds: customer_group_ids,

        shipGroupIds: anyShippingGroup
            ? any_shipping_group_ids
            : shipping_group_ids,

        shipGroupRule: anyShippingGroup ? 'any' : 'all',
        preventShipGroupIds: excl_shipping_group_ids,
        couponCode: coupon_code,
    }
}

export const applyAddCondition = ({ form, values, conditionField }) => {
    const types = ['price', 'weight', 'qty', 'volume']

    types.forEach((type) => {
        const fieldName = `shipFilterIds${toTitleCase(type)}`
        const formName = `ship_filter_ids_${type}`

        if (conditionField[fieldName]) {
            form.change(`ship_filter_ids_${type}`, [
                ...(values[formName] || []),
                ...conditionField[fieldName],
            ])
        }
    })

    form.change('ship_filter_ids', [
        ...(values.ship_filter_ids || []),
        ...(conditionField.shipFilterIdsPrice || []),
        ...(conditionField.shipFilterIdsWeight || []),
        ...(conditionField.shipFilterIdsQty || []),
        ...(conditionField.shipFilterIdsVolume || []),
    ])

    if (conditionField.zoneIds) {
        const zoneField =
            conditionField.zoneType === 'exclude'
                ? 'exclude_zone_ids'
                : 'zone_ids'

        form.change(zoneField, [
            ...(values[zoneField] || []),
            ...conditionField.zoneIds,
        ])
    }

    if (conditionField.customerGroupIds) {
        form.change('customer_group_ids', [
            ...(values.customer_group_ids || []),
            ...conditionField.customerGroupIds,
        ])
    }

    if (conditionField.couponCode) {
        form.change('coupon_code', conditionField.couponCode)
    }

    if (conditionField.shipGroupIds) {
        const name =
            conditionField.shipGroupRule === 'all'
                ? 'shipping_group_ids'
                : 'any_shipping_group_ids'

        form.change(name, [
            ...(values[name] || []),
            ...conditionField.shipGroupIds,
        ])
    }

    if (conditionField.preventShipGroupIds) {
        form.change('excl_shipping_group_ids', [
            ...(values.excl_shipping_group_ids || []),
            ...conditionField.preventShipGroupIds,
        ])
    }

    return form.getState().values
}

export const applyCleanCondition = ({ form, fields }) => {
    fields.forEach((fieldName) => {
        form.change(fieldName, fieldName === 'coupon_code' ? null : [])
    })
}

export default ConditionRulesFieldContainer
