/**
 * Copies props from record into origin except for props included in exceptKeys.
 *
 * @param origin
 * @param record
 * @param exceptKeys
 */
export const delegate = (
    origin: Record<string, unknown>,
    record: Record<string, unknown>,
    exceptKeys: string[] = []
) => {
    if (!origin || !record) return

    Object.keys(record).forEach((method) => {
        if (method !== 'origin' && !exceptKeys.includes(method)) {
            origin[method] = record[method]
        }
    })
}
