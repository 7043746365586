import React from 'react'
import styled from 'styled-components'

interface InfoLinkProps {
    href: string
    title: string
}

const DecoratedLink = styled.a`
    i {
        color: ${({ theme }) => theme.colors.infoLink};
    }
`

/**
 * InfoLink
 */
export const InfoLink: React.FC<InfoLinkProps> = React.memo(
    ({ href, title }) => {
        return (
            <DecoratedLink href={href} title={title} target="_blank">
                <i className="fal fa-info-circle" />
            </DecoratedLink>
        )
    }
)
