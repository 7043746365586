export const required = (value?: string) => (value ? undefined : 'Required')
export const dateFormat = (value?: string) => {
    if (value && value.length && value.split('/').length !== 3) {
        return 'Invalid date format. Please click on the calendar icon to select the date.'
    }
}

export const composeValidators =
    (...validators) =>
    (value) =>
        validators.reduce(
            (error, validator) => error || validator(value),
            undefined
        )
