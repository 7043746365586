//
import { createContext } from 'react'

/**
 * This context is used for exposing DynamicForm functionality for the form components
 *
 * @type {React$Context<DynamicFormContextType>}
 */
const DynamicFormContext = createContext({
    /**
     * Choose the value from the list depending on the conditions
     */
    conditionalValue: (hint) => String(hint),
})

export default DynamicFormContext
