//
import React, { Component } from 'react'

/**
 * Stats Properties
 */

/**
 * Stats State
 */

/**
 */
export default class Stats extends Component {
    render() {
        const { dataSource } = this.props
        const {
            current,
            perPage,
            recordsCount,
            totalRecords,
            filteredRecords,
        } = dataSource.getPageInfo()

        const start = (current - 1) * perPage
        const filtered = filteredRecords !== totalRecords
        if (filtered) {
            return (
                <div className="dataTables_info">
                    Showing {start + 1} to {start + recordsCount} of{' '}
                    {filteredRecords} (filtered from {totalRecords} total
                    records)
                </div>
            )
        }

        return (
            <div className="dataTables_info">
                Showing {start + 1} to {start + recordsCount} of {totalRecords}
            </div>
        )
    }
}
