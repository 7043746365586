import React from 'react'

const ErrorMessage = ({ children }) => {
    return (
        <div className="form-group has-error">
            <span className="help-block">
                <i className="fa fa-exclamation-circle" /> {children}
            </span>
        </div>
    )
}

export default ErrorMessage
