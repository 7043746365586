import React from 'react'
import { DynamicForm } from '../DynamicForm'
import { useInitialActionValue } from './useInitialActionValue'
import { useShippingRuleFormOptions } from 'components/ui/form/RuleBuilderActionField'

interface ActionFormRendererProps {
    width?: string
    values: any
    fields: any[]
}

/**
 * ActionFormRenderer renders action form from the supplied yml definition
 */
export const ActionFormRenderer: React.FC<ActionFormRendererProps> = ({
    fields,
    values,
    width = '50%',
}) => {
    const { features, optionSources } = useShippingRuleFormOptions()

    useInitialActionValue()

    if (!fields?.length) {
        return null
    }

    return (
        <div style={{ width }}>
            <DynamicForm
                classes={{
                    root: 'initial-align',
                    formRow: 'form-row',
                }}
                values={values}
                tab={{ fields: fields }}
                optionSources={optionSources}
                onSubmit={() => undefined}
                enabledFeatures={features}
            />
        </div>
    )
}
