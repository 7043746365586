//

import { axiosClient } from 'net/ajax'
import { prepareFormData } from 'utils/formData'
import { showMessage } from 'reducers/modules/app'
import isEmpty from 'lodash/isEmpty'
import store from 'reducers/store'

class AjaxTableDataSource {
    constructor(config) {
        this.config = config
        this.data = undefined
        this.pageInfo = {
            current: 1,
            perPage: 10,
            total: 0,
            recordsCount: 0,
            totalRecords: 0,
            emptyColumns: {},
        }
    }

    get endpointConfig() {
        return {}
    }

    get paramsKey() {
        return 'invalid'
    }

    get isDuplicatable() {
        return true
    }

    get isAjax() {
        return true
    }

    async list(args) {
        const result = await axiosClient.request({
            url: this.endpointConfig.list + `?${this.getParameters(args)}`,
            method: 'GET',
        })

        this.data = result.data.data.map((entry) => ({
            ...entry,
            id: entry.DT_RowData.id,
        }))

        this.pageInfo = {
            emptyColumns: result.data.emptyСolumns,
            current: args.page,
            perPage: args.perPage,
            total: Math.ceil(result.data.recordsFiltered / args.perPage),
            recordsCount: result.data.data.length,
            totalRecords: result.data.recordsTotal,
            filteredRecords: result.data.recordsFiltered,
        }

        return {
            perPage: args.perPage,
            page: args.page,
            data: this.data,
            recordsTotal: result.data.recordsTotal,
            emptyColumns: this.pageInfo.emptyColumns,
        }
    }

    getParameters(args) {
        const params = new URLSearchParams()
        let idx = 0
        let sortColumnIndex

        for (const column of this.config.columns) {
            if (args.sortColumnName === column.name) {
                sortColumnIndex = idx
            }

            params.append(`columns[${idx}][data]`, column.name)
            params.append(`columns[${idx}][name]`, column.title)
            params.append(
                `columns[${idx}][search][searchable]`,
                column.searchable ? 'true' : 'false'
            )
            params.append(`columns[${idx}][search][orderable]`, 'false')
            params.append(
                `columns[${idx}][search][value]`,
                args.columnSearches[idx] ? args.columnSearches[idx] : ''
            )
            params.append(`columns[${idx}][search][regex]`, 'false')
            idx++
        }

        if (sortColumnIndex !== undefined) {
            params.append('order[0][column]', String(sortColumnIndex))
        }

        if (args.sortDirection) {
            params.append('order[0][dir]', args.sortDirection)
        }

        const start = String((args.page - 1) * args.perPage)
        params.append('start', start < 0 ? 0 : start)
        params.append('length', String(args.perPage))
        params.append('search[value]', isEmpty(args.search) ? '' : args.search)
        params.append('search[regex]', 'false')

        return params.toString()
    }

    async get(id) {
        const result = await axiosClient.request({
            url: this.endpointConfig.get(id),
            method: 'GET',
        })

        const entry = result.data
        if (entry) {
            for (const column of this.config.columns) {
                if (column.map) {
                    const value = entry[column.name],
                        mapped = column.map[value]
                    if (mapped !== undefined) {
                        entry[column.name] = mapped
                    }
                }
            }
        }

        return entry
    }

    async delete(id) {
        try {
            await axiosClient.request({
                url: this.endpointConfig.delete(id),
                method: 'DELETE',
            })
        } catch (e) {
            const { response: { data: { errors } = {} } = {} } = e
            const keys = Object.keys(errors)

            let errorMessage = 'Internal server error'
            if (keys && keys.length) {
                errorMessage = keys
                    .map((key) => `"${key}": ${errors[key]}`)
                    .join('; ')
            }

            store.dispatch(
                showMessage({
                    type: 'error',
                    text: `Error while deleting this record: ${errorMessage}`,
                })
            )
        }
    }

    async update(id, data) {
        const { values, dataSourceIdField } = this.config

        const targetData = { ...data }
        if (dataSourceIdField) {
            targetData[dataSourceIdField] = values.id
        }

        let result
        if (data.id > 0) {
            result = await axiosClient.request({
                url: this.endpointConfig.update(data.id),
                method: 'PUT',
                data: prepareFormData(targetData, null, this.paramsKey, 'PUT'),
            })
        } else {
            result = await axiosClient.request({
                url: this.endpointConfig.create,
                method: 'POST',
                data: prepareFormData(targetData, null, this.paramsKey, 'POST'),
            })
        }

        this.validateResult(result)

        return result
    }

    async duplicate(id) {
        const getResult = await axiosClient.request({
            url: this.endpointConfig.get(id),
            method: 'GET',
        })

        this.validateResult(getResult)

        const { id: itemId, ...createData } = getResult.data

        const createResult = await axiosClient.request({
            url: this.endpointConfig.create,
            method: 'POST',
            data: { [this.paramsKey]: createData },
        })

        this.validateResult(createResult)

        return createResult.data
    }

    async create() {
        // negative ids will be stripped from the form data
        const id = -new Date().getTime()
        return {
            id,
        }
    }

    async hasAssociatedRecords(id) {
        const { values } = this.config
        // no need to check for non-persisted records for now..
        if (id < 0) {
            return false
        }

        const params = new URLSearchParams()
        params.append(`shipping_method`, id)

        const result = await axiosClient.request({
            url: this.endpointConfig.hasAssociatedRecords(
                values.id,
                params.toString()
            ),
            method: 'GET',
        })

        if (result && result.status === 200 && result.data) {
            return !!result.data.result
        }

        return true
    }

    getPageInfo() {
        return this.pageInfo
    }

    validateResult(result) {
        if (result?.data?.errors) {
            const errors = []
            for (const error of Object.values(result?.data?.errors)) {
                errors.push(error)
            }

            throw new Error(errors.join('. '))
        }
    }
}

export default AjaxTableDataSource
