//
import { compareField } from 'utils/fields'

export const validateNonempty = (value) => {
    if (Array.isArray(value)) {
        const filtered = value.filter((entry) => String(entry).length > 0)
        return filtered.length > 0 ? undefined : 'Required'
    }

    // MNB-1115 If just whitespace trigger required error
    if (String(value).trim().length === 0) {
        return 'Required'
    }

    if (typeof value === 'number') {
        return undefined
    }

    return value ? undefined : 'Required'
}

export const validateNumber = (value) =>
    isNaN(value) ? 'Must be a number' : undefined

export const validateHasLength = (value, allValues, options) =>
    value.length !== options.length
        ? `Must be exactly ${options.length} characters long (you've entered ${value.length})`
        : undefined

export const validateMaxLength = (value, allValues, options) => {
    if (value === null || value === undefined) {
        return undefined
    }

    return value.length >= options.length + 1
        ? `Must be ${options.length} characters or less in length (you've entered ${value.length})`
        : undefined
}

export const validateNumberInRange = (value, allValues, options) => {
    const iValue = parseFloat(String(value))

    if (Number.isNaN(iValue)) {
        return undefined
    }

    return iValue > options.to || iValue < options.from
        ? `Must be between ${options.from} and ${options.to}.`
        : undefined
}

export const validatePositiveInteger = (value) =>
    isNaN(value) ||
    value < 1 ||
    String(value).indexOf('.') !== -1 ||
    String(value).indexOf(',') !== -1
        ? 'Must be a positive integer value'
        : undefined

export const validateEntityName = (value) => {
    return value.match(/(?!(^1$|^0+$)|^all$)^[^#,*]+$/i)
        ? undefined
        : "Must not include '#', '*' or comma. Can not be any of the words: 'All, 0 or 1'"
}

export const validateZipCodeList = (value) => {
    const zipCodeError = 'Please use comma to separate postcodes'

    if (!value) return zipCodeError

    const charList = [...value.trim()]

    const spaceCount = charList.filter((c) => c === ' ').length
    const commaCount = charList.filter((c) => c === ',').length
    // allow one space in each zip code
    return spaceCount > 1 && commaCount < spaceCount / 2
        ? zipCodeError
        : undefined
}

export const validatePhone = (value) => {
    // Remove whitespace
    const phone = value?.trim();

    // Check if phone is empty
    if (!phone) {
        return "Phone number is required";
    }

    // Validate for numeric characters only, 6 to 15 digits
    const phoneRegex = /^\d{6,15}$/;

    return phoneRegex.test(phone)
        ? undefined
        : "Invalid phone number. Use numbers only, between 6 to 15 digits.";
};

export const validateWebsite = (value) => {
    const websiteRegexp =
        // eslint-disable-next-line no-useless-escape
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/

    return value?.match(websiteRegexp) ? undefined : 'Invalid URL'
}

export const validatePositiveRangeOrNumber = (value) => {
    const parts = value.split ? value.split('-') : []
    if (parts.length === 2) {
        // allow 0 as a start of the range
        const start =
            parts[0] === '0' ? undefined : validatePositiveInteger(parts[0])
        if (start) {
            return start
        }

        const end = validatePositiveInteger(parts[1])
        if (end) {
            return end
        }

        if (parts[0] === parts[1]) {
            return 'Start of the range cannot be equal to the end of the range'
        }
        if (parseFloat(parts[0]) > parseFloat(parts[1])) {
            return 'Start of the range should be greater than the end of the range'
        }
    } else {
        return validateNumber(value)
    }
}

export const getValidator = (type, values, checkRequirements, getByType) => {
    if (type.require) {
        const validator = getValidatorReal(type)

        return (value, allValues) => {
            if (!validator) {
                return undefined
            }

            const updatedValues = {
                ...values,
                ...allValues,
            }

            if (checkRequirements && checkRequirements(updatedValues)) {
                return validator(value, updatedValues)
            } else {
                return undefined
            }
        }
    } else {
        return getValidatorReal(type, values, getByType)
    }
}

const getValidatorReal = (type, values, getByType) => {
    if (typeof type !== 'string') {
        if (Array.isArray(type)) {
            const result = type.map((type) =>
                getValidatorReal(type, values, getByType)
            )
            if (result?.length) {
                return (value, allValues) => {
                    for (const validator of result) {
                        if (validator) {
                            const validationResult = validator(value, {
                                ...values,
                                ...allValues,
                            })
                            if (validationResult) {
                                return validationResult
                            }
                        }
                    }
                }
            }

            return undefined
        }

        // is it ValidateWhenType
        if (type.fails && type.when) {
            return (value, allValues) => {
                let sourceValue = type.when.arg
                    ? allValues[type.when.arg]
                    : null

                if (
                    type.fails &&
                    type.when &&
                    type.when.target &&
                    !compareField(sourceValue, value, type.when.op)
                ) {
                    return type.fails
                }
                return undefined
            }
        } else if (type.when && type.with) {
            const validatorFunc = getValidator(
                type.with,
                undefined,
                undefined,
                getByType
            )
            return (value, allValues) => {
                if (
                    validatorFunc &&
                    compareField(
                        allValues[type.when.target],
                        null,
                        type.when.op
                    )
                ) {
                    return validatorFunc(value, allValues)
                }
                return undefined
            }
        } else if (type.with && type.options) {
            const validatorFunc = getValidator(
                type.with,
                undefined,
                undefined,
                getByType
            )

            return (value, allValues) => {
                return validatorFunc(value, allValues, type.options)
            }
        }

        return (value, allValues) => {
            if (
                type.target &&
                compareField(value, allValues[type.target], type.op)
            ) {
                return type.message
                    ? type.message
                    : 'Please enter a valid value here'
            }
            return undefined
        }
    }

    if (getByType) {
        return getByType(type)
    }

    return getValidatorByType(type)
}

export function getValidatorByType(type) {
    switch (type) {
        case 'nonempty':
            return validateNonempty

        case 'number':
            return validateNumber

        case 'positive_integer':
            return validatePositiveInteger

        case 'number_in_range':
            return validateNumberInRange

        case 'entity_name':
            return validateEntityName

        case 'has_length':
            return validateHasLength

        case 'max_length':
            return validateMaxLength

        case 'positive_range_or_number':
            return validatePositiveRangeOrNumber

        case 'zip_code_list':
            return validateZipCodeList

        case 'phone':
            return validatePhone

        case 'website':
            return validateWebsite

        default:
            return undefined
    }
}
