import React, { useContext, useState } from 'react'
import DynamicFormContext from 'components/ui/form/DynamicFormContext'
import { FormApi } from 'final-form'
import caller from 'utils/caller'
import { useSelector } from 'react-redux'

import NewQuickRuleModal from './Modal'

interface Props {
    form: FormApi
    values: any
    actions: any
    conditions: any
    shippingMethodForm: unknown
}

export const useQuickRuleFormSelector = ({ key = 'entity' } = {}) => {
    const { form } = useSelector(
        ({ app }: { app: ReduxAppData }) => caller(app, key) || {}
    )

    if (!form) return {}

    const formField = form.form.new_button
    const actions = formField.actions
    const conditions = formField.conditions
    const shippingMethodForm = formField.shipping_method_form

    return { actions, conditions, shippingMethodForm }
}

export const NewQuickRuleButton: React.FC<Props> = ({
    form,
    values,
    actions,
    conditions,
    shippingMethodForm,
}) => {
    const [openDialog, setOpenDialog] = useState(false)
    const toggleDialog = () => setOpenDialog((prev) => !prev)

    const handleCompleted = () => {
        window.location.reload()
    }

    return (
        <>
            <p
                id="openNewQuickRuleModal"
                className="btn btn-primary-o btn-round btn-lg mb-0"
                onClick={toggleDialog}
            >
                + New
            </p>

            <NewQuickRuleModal
                open={openDialog}
                form={form}
                values={values}
                onClose={toggleDialog}
                onCompleted={handleCompleted}
                actions={actions}
                conditions={conditions}
                shippingMethodForm={shippingMethodForm}
            />
        </>
    )
}

const NewQuickRuleField = ({ actions, conditions, shippingMethodForm }) => {
    const { form, values }: any = useContext(DynamicFormContext)

    return (
        <NewQuickRuleButton
            form={form}
            values={values}
            actions={actions}
            conditions={conditions}
            shippingMethodForm={shippingMethodForm}
        />
    )
}

export default NewQuickRuleField
