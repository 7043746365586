import React from 'react'
import styled from 'styled-components'

const NoteContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: start;
    background: ${({ theme }) => theme.colors['ltblue1-3']};
    border: 1px solid ${({ theme }) => theme.colors['ltblue1-5']};
    padding: 8px;
    border-radius: ${({ theme }) => theme.radiusxl};

    & .note-icon {
        color: ${({ theme }) => theme.colors.dkblue1};
        margin-top: 4px;
    }
`

const NoteBody = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.greybluedk1};

    a {
        color: ${({ theme }) => theme.colors.dkblue1};
        font-weight: 700;
    }
`

interface NoteMessageProps {
    className?: string
}

const NoteMessage: React.FC<NoteMessageProps> = ({ children, className }) => {
    return (
        <NoteContainer className={className}>
            <i className="note-icon mr-2 fas fa-info-circle" />
            <NoteBody>{children}</NoteBody>
        </NoteContainer>
    )
}

export default NoteMessage
