import React from 'react'
import { RuleBuilderAction } from './types'
import cx from 'classnames'

const iconMap: Record<RuleBuilderAction, string> = {
    HIDE_METHODS: 'fa-minus-circle',
    SURCHARGE_RATES: 'fa-arrow-circle-up',
    FREE_SHIPPING: 'fa-truck',
    OVERRIDE_RATES: 'fa-random',
    DISCOUNT_RATES: 'fa-arrow-circle-down',
}

export const ActionIcon: React.FC<{
    action: RuleBuilderAction
    selected?: boolean
}> = React.memo(({ action, selected }) => {
    const className = cx('fa', iconMap[action], {
        active: selected,
    })

    return <i className={className} />
})
