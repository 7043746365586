//
import React, { Component, Fragment } from 'react'
import { fieldId, FormGroup } from 'components/ui/form/renderers'
import { axiosClient } from 'net/ajax'
import copy from 'copy-to-clipboard'

/**
 * fieldDate Properties
 */

/**
 * fieldDate State
 */

/**
 */
export default class fieldApiKey extends Component {
    state = {
        modalVisible: false,
        resetConfirmed: false,
        newCode: '',
        wasCopied: false,
    }

    constructor(props) {
        super(props)

        this.generateNewCode = this.generateNewCode.bind(this)
    }

    async generateNewCode() {
        const data = new FormData()
        data.append('ratesmgr_website[id]', this.props.values.id)

        const response = await axiosClient.request({
            url: '/ratesmgr/websites/generate_password',
            method: 'post',
            data,
        })

        const newCode =
            response && Array.isArray(response.data) ? response.data[0] : ''
        this.setState({
            resetConfirmed: true,
            newCode,
        })
    }

    render() {
        const {
            input,
            meta,
            title,
            hint,
            fullWidth,
            className,
            compact,
            oneLine,
            values: { id } = {},
        } = this.props

        const { wasCopied } = this.state

        return (
            <Fragment>
                {/* $FlowFixMe */}
                <FormGroup
                    meta={meta}
                    name={input.name}
                    label={title}
                    hint={hint}
                    compact={compact}
                    fullWidth={fullWidth}
                    oneLine={oneLine}
                    className={className}
                >
                    <div className="input-group">
                        <input
                            id={fieldId(input.name)}
                            {...input}
                            className="form-control string optional"
                            readOnly={true}
                        />
                        <div
                            className="input-group-addon copy"
                            onClick={(e) => {
                                e.preventDefault()
                                copy(input.value)
                                this.setState({ wasCopied: true })

                                setTimeout(() => {
                                    this.setState({ wasCopied: false })
                                }, 2500)

                                return false
                            }}
                        >
                            {wasCopied ? 'Copied! ' : 'Copy '}
                            <i
                                className={
                                    wasCopied ? 'far fa-check' : 'far fa-paste'
                                }
                            />
                        </div>
                    </div>
                </FormGroup>
            </Fragment>
        )
    }
}
