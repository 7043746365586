import AjaxTableDataSource from './AjaxTableDataSource'

class TableRatesDataSource extends AjaxTableDataSource {
    get endpointConfig() {
        const { values, dataSourceEntity } = this.config

        return {
            list: `/ratesmgr/${dataSourceEntity}/${values.id}/get_table_rates`,
            get: (id) => `/ratesmgr/table_rates/${id}`,
            delete: (id) => `/ratesmgr/table_rates/${id}`,
            update: (id) => `/ratesmgr/table_rates/${id}`,
            create: '/ratesmgr/table_rates.json',
            hasAssociatedRecords: (id, params) =>
                `/ratesmgr/carriers/${id}/has_table_rates?` + params.toString(),
        }
    }

    get paramsKey() {
        return 'ratesmgr_table_rate'
    }
}

export default TableRatesDataSource
