import React from 'react'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import icons from './icons'
import { ShippingGoals } from './components'

const GOALS_BLUE_SVG_ICONS = {
    ship_restricted_products: icons.shield,
}

const GOALS_WHITE_SVG_ICONS = {
    ship_restricted_products: icons.whiteShield,
}

const GOALS_ICONS = {
    missing_carrier: 'fas fa-truck',
    missing_website: 'fas fa-mouse-pointer',
    missing_origin: 'fas fa-warehouse',
    offer_free_shipping: 'fas fa-certificate',
    modify_shipping_rates: 'fas fa-random',
    show_delivery_dates: 'fas fa-calendar',
    rate_on_dimensional_weight: 'fas fa-box-open',
    ship_from_multiple_locations: 'fas fa-warehouse',
    in_store_pickup: 'fas fa-store',
    ship_perishable_products: 'fas fa-seedling',
    use_ltl_freight_carriers: 'fas fa-truck-container',
    create_custom_rates: 'fas fa-money-bill-wave',
    use_table_rates: 'fas fa-table',
}

type Goal =
    | 'missing_carrier'
    | 'missing_website'
    | 'missing_origin'
    | 'offer_free_shipping'
    | 'modify_shipping_rates'
    | 'show_delivery_dates'
    | 'rate_on_dimensional_weight'
    | 'ship_from_multiple_locations'
    | 'in_store_pickup'
    | 'ship_perishable_products'
    | 'use_ltl_freight_carriers'
    | 'create_custom_rates'
    | 'use_table_rates'

export const shippingGoalIcon = (goal: Goal, svgBlue = true) => {
    const svgIcon = svgBlue
        ? GOALS_BLUE_SVG_ICONS[goal]
        : GOALS_WHITE_SVG_ICONS[goal]

    return svgIcon ? <img src={svgIcon} /> : <i className={GOALS_ICONS[goal]} />
}

const fieldShippingGoals = (props) => {
    const { input, values, shippingGoalsOptions } = props

    const selectedGoals = isEmpty(values[input.name]) ? [] : values[input.name]

    const onChangeField = (goalId, checked) => {
        const newValues = checked
            ? uniq([...selectedGoals, goalId])
            : selectedGoals.filter((shippingGoal) => shippingGoal !== goalId)

        input.onChange(newValues)
    }

    const isSelected = (goalId) => selectedGoals.includes(goalId)

    return (
        <ShippingGoals.List>
            {shippingGoalsOptions.map((shippingGoal, index: number) => (
                <GoalInput
                    key={`shippingGoal-input-${shippingGoal.id}`}
                    id={shippingGoal.id}
                    title={shippingGoal.display_name}
                    name={shippingGoal.name}
                    onChange={onChangeField}
                    checked={isSelected(shippingGoal.id)}
                    icon={shippingGoalIcon(shippingGoal.name)}
                />
            ))}
        </ShippingGoals.List>
    )
}

const GoalInput = ({
    id,
    name,
    title,
    disabled = false,
    onChange,
    icon,
    checked = false,
    variant = 'white',
}) => {
    return (
        <ShippingGoals.LabelItem htmlFor={name}>
            <input
                disabled={disabled}
                id={name}
                type="checkbox"
                checked={checked}
                onChange={(event) => onChange(id, event.target.checked)}
                name={name}
            />
            <ShippingGoals.Button>
                <ShippingGoals.Icon className={variant}>
                    {icon}
                </ShippingGoals.Icon>

                <ShippingGoals.Title>{title}</ShippingGoals.Title>
            </ShippingGoals.Button>
        </ShippingGoals.LabelItem>
    )
}

export default fieldShippingGoals
