import React, { useContext, useState, createContext } from 'react'
import { actionTitles } from 'components/ui/form/RuleBuilderActionField/ActionSelector'

interface FormErrors {
    [key: string]: string
}

interface HandleProps {
    onSuccess?: (props: any) => void
    onError?: (errors: FormErrors) => void
}

interface ConditionRulesProps {
    onBack?: () => void
    onClose: () => void
    onCompleted: (values?: any, resultHandle?: HandleProps) => void
    onSkip: (values?: any, resultHandle?: HandleProps) => void
    stepForms: any[]
    formSources: OptionSources
    values: any
    multipleConditions?: boolean
    children: JSX.Element
}

interface ConditionRulesProvider {
    onBack?: () => void
    onClose: () => void
    onCompleted: (values?: any) => void
    onSkip: (values?: any) => void
    stepForms: any[]
    formSources: OptionSources
    values: any
    multipleConditions?: boolean
    actionTitle?: string
    isLoading?: boolean
    setLoading: (value: boolean) => void
    errors: FormErrors
    setErrors: (errors: FormErrors) => void
    errorMessage?: string | unknown
}

const ConditionRulesContext = createContext<ConditionRulesProvider>(null)

export const useConditionRulesField = () => useContext(ConditionRulesContext)

export const ConditionRulesProvider: React.FC<ConditionRulesProps> = ({
    onBack = null,
    onClose,
    onCompleted,
    onSkip = null,
    stepForms,
    values,
    formSources,
    multipleConditions = false,
    children,
}: ConditionRulesProps) => {
    const [errors, setErrors] = useState({})
    const [isLoading, setLoading] = useState(false)

    const actionTitle = actionTitles[values.builder_action] || 'Condition Rule'

    let errorMessage = Object.values(errors || {})[0]
    errorMessage = Array.isArray(errorMessage) ? errorMessage[0] : errorMessage

    const handleCompleted = (values = {}) => {
        setLoading(true)

        onCompleted(values, {
            onSuccess: () => {
                setLoading(false)
            },
            onError: (errors: FormErrors) => {
                setLoading(false)
                setErrors(errors)
                return
            },
        })
    }

    const handleSkip = (values = {}) => {
        setLoading(true)

        onSkip(values, {
            onSuccess: () => {
                setLoading(false)
            },
            onError: (errors: FormErrors) => {
                setLoading(false)
                setErrors(errors)
                return
            },
        })
    }

    return (
        <ConditionRulesContext.Provider
            value={{
                onBack,
                onClose,
                onCompleted: handleCompleted,
                onSkip: handleSkip,
                stepForms,
                actionTitle,
                formSources,
                values,
                errors,
                setErrors,
                isLoading,
                setLoading,
                multipleConditions,
                errorMessage,
            }}
        >
            {children}
        </ConditionRulesContext.Provider>
    )
}
