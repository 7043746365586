import React from 'react'
import { ActionSelector } from './ActionSelector'
import { RuleBuilderAction } from './types'
import { FieldRenderProps } from 'react-final-form'
import { ActionFormRenderer } from './ActionFormRenderer'
import { useSetRuleActionType } from '../hooks/useSetRuleActionType'
import { useForm } from 'react-final-form'
import {
    ActionContainer,
    RuleActionForm,
} from 'components/ui/ActionSelector/styles'

interface RuleBuilderActionFieldProps extends FieldRenderProps<string, any> {
    actions: Record<RuleBuilderAction, any>
    values: any
}

/**
 * RuleBuilderActionField
 */
export const RuleBuilderActionField: React.FC<RuleBuilderActionFieldProps> = ({
    actions,
    values,
}) => {
    const form = useForm()
    const { selectedAction, setRuleAction } = useSetRuleActionType({ form })

    const onChangeAction = (actionType: RuleBuilderAction) => {
        setRuleAction(actionType)
    }

    return (
        <>
            <ActionContainer>
                <ActionSelector
                    selectedAction={selectedAction}
                    onChange={onChangeAction}
                />
            </ActionContainer>

            {selectedAction !== 'HIDE_METHODS' && (
                <RuleActionForm>
                    <ActionFormRenderer
                        values={values}
                        fields={actions?.[selectedAction] || []}
                    />
                </RuleActionForm>
            )}
        </>
    )
}
