import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import ErrorMessage from 'components/ui/form/ErrorMessage'
import { StackFlowContext } from './index'
import chunk from 'lodash/chunk'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import { useConditionRulesField } from 'components/ui/form/ConditionRulesField/Provider'
import { ActionInput } from 'components/ui/form/components/ActionInput'
import {
    useConditions,
    CONDITIONS_TITLES,
    CONDITIONS_NAMES,
    CONDITIONS_ICONS,
} from 'components/ui/form/ConditionRulesField/ConditionsProvider'

const SelectConditionStep = () => {
    const wizard = useWizardState({ context: StackFlowContext })
    const selectedConditions = wizard.context.selectedConditions || []

    const {
        isLoading,
        errorMessage,
        onSkip,
        onBack,
        actionTitle,
        values,
        multipleConditions,
    } = useConditionRulesField()

    const { conditions } = useConditions()

    const onChangeCondition = (name, checked) => {
        const newConditions = checked
            ? multipleConditions
                ? uniq([...selectedConditions, name])
                : [name]
            : selectedConditions.filter((condition) => condition !== name)

        wizard.setContext({
            ...wizard.context,
            selectedConditions: newConditions,
        })
    }

    const handleSubmitStep = () => {
        wizard.submitStep()
    }

    const handleSkip = () => {
        if (isLoading) return

        onSkip()
    }

    const rulesValues = {
        ...(values || {}),
        shipping_group_ids: compact(
            flatten([
                values?.shipping_group_ids,
                values?.any_shipping_group_ids,
                values?.excl_shipping_group_ids,
            ])
        ),
    }

    const isAvailable = (condition) => {
        if (multipleConditions) return true

        return isEmpty(rulesValues[CONDITIONS_NAMES[condition]])
    }

    return (
        <div className="condition-form">
            <h2 className="title">
                <strong>{`${actionTitle}`}</strong>
                <br />
                when these conditions apply
            </h2>

            <p className="description mb-5">
                Select all conditions that must be met
            </p>

            {onBack && (
                <button
                    data-testid="modal-close-button"
                    type="button"
                    className="back"
                    data-dismiss="modal"
                    aria-hidden="true"
                    onClick={onBack}
                />
            )}

            <div className="mb-10">
                {chunk(conditions, 2).map(
                    (chunkConditions: string[], index: number) => (
                        <div key={`action-section-${index}`} className="row">
                            {chunkConditions.map((condition: string) => (
                                <div
                                    key={`action-input-${condition}`}
                                    className="col-xs-6 mb-5"
                                >
                                    <ActionInput
                                        id="condition"
                                        disabled={!isAvailable(condition)}
                                        title={CONDITIONS_TITLES[condition]}
                                        name={condition}
                                        onChange={onChangeCondition}
                                        checked={selectedConditions.includes(
                                            condition
                                        )}
                                        icon={
                                            <i
                                                className={`fa ${CONDITIONS_ICONS[condition]}`}
                                            />
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )
                )}
            </div>

            <div className="mb-5">
                <p onClick={handleSkip} className="blue-link cancel-link">
                    I don't have any conditions to apply
                </p>
            </div>

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <button
                disabled={isEmpty(selectedConditions) || isLoading}
                type="button"
                className="btn btn-primary"
                aria-hidden="true"
                onClick={handleSubmitStep}
            >
                Continue
            </button>
        </div>
    )
}

export default SelectConditionStep
