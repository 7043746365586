import { useSelector } from 'react-redux'
import caller from 'utils/caller'

interface OptionType {
    entityKey?: string
}

export function useOptionSources(props?: OptionType) {
    const { entityKey } = props || {}

    return useSelector<
        ReduxRootState,
        ReduxRootState['app']['entity']['option_sources']
    >(
        ({ app }) =>
            caller(app, entityKey)?.option_sources ||
            app?.entity?.option_sources ||
            app?.option_sources
    )
}

export function useEnabledFeatures() {
    return useSelector<
        ReduxRootState,
        ReduxRootState['app']['enabled_features']
    >(({ app }) => app?.enabled_features)
}

export function useDynamicFormOptions(props?: OptionType) {
    const features = useEnabledFeatures()
    const optionSources = useOptionSources(props)

    return { features, optionSources }
}
