import React, { useEffect, useMemo } from 'react'
import { FormGroup, fieldId } from './renderers'
import { useField, useForm } from 'react-final-form'

export const fieldRuleAction = (options) => {
    const {
        title,
        input,
        meta,
        hint,
        disabled,
        compact,
        fullWidth,
        className,
        oneLine,
        readOnly,
        required,
        emptyMessage,
    } = options

    const form = useForm()
    const actionMethod = useField('action_method')
    const actionType = useField('action_type')
    const currentValue = useMemo(() => {
        if (actionMethod.input.value === 'HIDE') {
            return 'HIDE'
        }
        if (actionType.input.value === 'SET') {
            return 'OVERRIDE'
        }
        if (actionType.input.value === 'SURCHARGE') {
            return 'SURCHARGE'
        }

        return ''
    }, [actionMethod.input.value, actionType.input.value])

    useEffect(() => {
        form.change(input.name, currentValue)
    }, [currentValue])

    const onChange = (e) => {
        switch (e.target.value) {
            case 'OVERRIDE':
                form.change('action_method', 'SHOW')
                form.change('action_type', 'SET')
                break
            case 'SURCHARGE':
                form.change('action_method', 'SHOW')
                form.change('action_type', 'SURCHARGE')
                break
            case 'HIDE':
                form.change('action_method', 'HIDE')
                form.change('action_type', 'NONE')
                break
            case '':
                form.change('action_method', '')
                form.change('action_type', null)
                break
        }
    }

    return (
        <FormGroup
            meta={meta}
            name={input.name}
            label={title}
            hint={hint}
            compact={compact}
            fullWidth={fullWidth}
            className={className}
            oneLine={oneLine}
            required={required}
        >
            <select
                id={fieldId(input.name)}
                className="form-control string optional"
                disabled={disabled}
                readOnly={readOnly}
                placeholder={options.placeholder}
                onChange={onChange}
                value={currentValue}
                {...(required ? { 'aria-required': 'true' } : null)}
            >
                <option>{emptyMessage || ''}</option>
                <option value="OVERRIDE">Override Rates</option>
                <option value="SURCHARGE">Surcharge or discount rates</option>
                <option value="HIDE">Hide a shipping method</option>
            </select>
        </FormGroup>
    )
}
