import React from 'react'
import { useWizardState } from 'components/dashboard/wizard/WizardStateContext'
import { ActionFormRenderer } from 'components/ui/form/RuleBuilderActionField/ActionFormRenderer'
import { ActionIcon } from 'components/ui/form/RuleBuilderActionField/ActionIcon'
import { actionTitles } from 'components/ui/form/RuleBuilderActionField/ActionSelector'
import { FormStepTitle } from 'components/ui/form/components/StepLayout'
import { InfoLink } from 'components/ui/InfoLink'

import { NewRuleFlowContext } from './index'
import { useNewQuickRule } from '../Provider'

const ActionFormStep: React.FC = () => {
    const { values, selectedAction, actions } = useNewQuickRule()
    const wizard = useWizardState({ context: NewRuleFlowContext })
    const actionType = values.builder_action
    const actionTitle = actionTitles[actionType]

    const handleSubmitStep = () => {
        wizard.submitStep()
    }

    return (
        <div className="condition-form">
            <div className="c-message">
                <div className="c-named">
                    <div className="carrier-selected c-type-live c-type-icon c-type-icon--blue">
                        <ActionIcon action={actionType} />
                    </div>
                </div>
            </div>

            <div className="reqs-list-modal-content center">
                <div className="max-w350">
                    <div className="mb-6 center">
                        <FormStepTitle>
                            How would you like to {actionTitle}?
                            <InfoLink
                                href="https://docs.shipperhq.com/carrier-rules-configuration/#Action"
                                title="Learn more about setting up shipping rules"
                            />
                        </FormStepTitle>
                    </div>

                    <div className="entity-content">
                        <ActionFormRenderer
                            width="100%"
                            values={values}
                            fields={actions[selectedAction] || []}
                        />
                    </div>
                </div>
            </div>

            <button
                data-testid="modal-close-button"
                type="button"
                className="back"
                data-dismiss="modal"
                aria-hidden="true"
                onClick={wizard.prevStep}
            />

            <button
                type="button"
                className="btn btn-primary"
                aria-hidden="true"
                onClick={handleSubmitStep}
            >
                Continue
            </button>
        </div>
    )
}

export default ActionFormStep
